// TODO: remove --new once all legals are up to date
.shopping-cart-legal--new {
  margin: 5px auto;
  padding-bottom: 75px;
  border-bottom: 1px solid #e6e6e6;
  height: 65px;
  overflow: hidden;
  position: relative;
  transition: height 0.3s;
  cursor: pointer;

  .shopping-cart-legal__content {
    margin-right: 50px;
    padding: 10px 0;
  }

  .shopping-cart-legal__collapse {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .shopping-cart-legal__collapse svg {
    width: 20px;
    height: 20px;
  }

  .shopping-cart-legal__collapse .icon-minus {
    display: none;
  }

  &.is-expanded .shopping-cart-legal__collapse .icon-minus {
    display: block;
  }

  &.is-expanded .shopping-cart-legal__collapse .icon-plus {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 45px;
    width: 100%;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 75%
    );
    bottom: 0;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.is-expanded:after {
    opacity: 0;
  }
}
