.section-menu {
  background: white;
  border-bottom: 1px solid black;
  @media print {
    display: none;
  }
  .container {
    display: flex;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  &.section-menu--scrollable {
    .section-menu__button {
      display: block;
      @media screen and (max-width: 767px) {
        display: none !important;
      }
    }

    .section-menu__scroller-container .section-menu__scroller {
      padding: 5px;
      margin: 0 5px;
    }
  }
  &.section-menu--fixed {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 50;
  }
  &__scroller {
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /*hide scroll start*/
    overflow-y: hidden;
    padding-bottom: 30px;
    margin-bottom: -30px;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    /*hide scroll end*/
  }
  &__scroller-container {
    position: relative;
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    min-height: 46px;
    width: 100%;
    height: 46px;
    overflow: hidden;
    .section-menu--scrollable &:after {
      content: "";
      height: 100%;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      width: 25px;
      //background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 99%);
    }
  }
  &__item {
    background: white;
    border: 0;
    color: black;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
    outline: none;
    padding: 8px 15px;
    position: relative;
    text-transform: uppercase;
    transition: background 300ms ease;

    &--external {
      line-height: 2;
      text-decoration: none;
    }

    &:hover {
      background-color: $secondary-color;
      color: white;
    }
    body.isTouchDevice & {
      &:hover {
        background: white;
        color: black;
      }
    }
    &:after {
      background-color: $secondary-color;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 3px;
      transition: width 300ms ease;
      .scrollingUp & {
        left: 0;
        right: auto;
      }
    }
    &.active {
      &:after {
        width: 100%;
        left: 0;
        right: auto;
        .scrollingUp & {
          left: auto;
          right: 0;
        }
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 11px;
      padding: 6px 12px;
    }
  }
  &__button {
    border: 0;
    background: white;
    display: none;
    flex: 0 0 28px;
    fill: #b2b2b2;
    width: 28px;
    height: 40px;
    svg {
      height: 10px;
      width: 15px;
    }
    &:hover {
      fill: black;
    }
    &--prev {
      border-right: 1px solid #e8e8e8;
      svg {
        transform: rotate(90deg);
      }
    }
    &--next {
      border-left: 1px solid #e8e8e8;
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &--spring-hub {
    .container {
      background-color: $spring-hub-section-menu-color;
      align-items: center;
    }
    .section-menu__button {
      display: none;
      background-color: $spring-hub-section-menu-color;
      border: $spring-hub-section-menu-text-color solid 1px;
      fill: $spring-hub-section-menu-text-color;
      &:hover {
        background-color: $spring-hub-section-menu-text-color;
        fill: $spring-hub-color-darkgray;
      }
    }
    .section-menu-mobile-button {
      &__text {
        color: $spring-hub-section-menu-text-color;
      }
      &__burger {
        span {
          background-color: $spring-hub-section-menu-text-color;
        }
      }
    }
    .section-menu__item {
      &:after {
        //content: none;
      }
    }
    &.section-menu--fixed {
      position: relative;
      width: 100%;
      z-index: 50;
    }
    border: none;
    margin: 30px 0;
    .section-menu__scroller-container {
      height: auto;
      //overflow: visible;
      margin: 0;
      .section-menu__scroller {
        display: flex;
        align-content: center;
        justify-content: space-around;
        padding: 5px 30px;
        margin: 0 10px;
        :last-child {
          margin-right: 30px;
        }
      }

      background-color: $spring-hub-section-menu-color;

      &:after {
        //content: none;
      }
    }

    &.section-menu--scrollable .section-menu__scroller {
      justify-content: unset !important;
    }

    .section-menu__item {
      background-color: $spring-hub-section-menu-color;
      color: $spring-hub-section-menu-text-color;
      flex: 1 0 auto;
      text-align: center;
      padding: 15px;
      margin: 0 10px;
      text-decoration: none;
      display: flex;
      align-items: center;
      height: auto;
      justify-content: center;

      @media screen and (min-width: 992px) {
        max-width: 18%;
      }
      @media screen and (max-width: 991px) {
        border-bottom: 1px solid white;
        &:last-of-type {
          border-bottom: none;
        }
      }
      &:hover,
      &:focus,
      &.active {
        background-color: #ffffff;
        color: $spring-hub-section-menu-text-highlight-color;
        border: none;
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 20px 0 20px;
          border-color: $spring-hub-section-menu-color transparent transparent transparent;
          bottom: -20px;
          transform: translateX(-50%);
          left: 50% !important;
          right: auto !important;
          background: transparent;
        }
      }
    }
    .section-menu__button {
      //display: none;
    }
  }
}

.section-menu--mobile-burger {
  .container {
    @media screen and (max-width: 1199px) {
      flex-flow: column;
    }
  }
  .section-menu__scroller-container {
    @media screen and (min-width: 1200px) {
      display: block !important;
    }
    @media screen and (max-width: 1199px) {
      display: none;
      min-height: 0;
      .section-menu__scroller {
        :last-child {
          margin: 0 10px;
        }
      }
    }
  }
  .section-menu__scroller {
    @media screen and (max-width: 1199px) {
      flex-flow: column;
    }
  }
}

.section-menu-mobile-button {
  background-color: $spring-hub-section-menu-color;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 15px;
  width: 100%;
  @media screen and (min-width: 1200px) {
    display: none;
  }
  &__text {
    color: black;
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
    text-transform: uppercase;
  }
  &__burger {
    display: inline-block;
    height: 22px;
    font-size: 0;
    line-height: 0;
    margin-right: 8px;
    vertical-align: middle;
    width: 25px;
    span {
      background-color: #000;
      display: block;
      height: 4px;
      margin-bottom: 5px;
      width: 100%;
      transition: color 250ms, background-color 250ms;
      &:last-child {
        margin: 0;
      }
    }
  }
}
