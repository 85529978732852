.espot-DepartmentBottomContent {
  display: block; // temp
}

.dept-top-brands {
  $block: &;

  &__title {
    color: #000;
    text-transform: none;
    font-size: 24px;
    font-weight: 400;
  }

  &__item {
    margin: 0 5px;
    border: 1px solid #d7d5d5;
    display: block;
    transition: border-color ease-in-out 0.2s;

    &:hover {
      border-color: $light-blue;
    }

    &:hover #{$block}__logo {
      opacity: 0.6 !important;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto;
    opacity: 1 !important;
    transition: opacity ease-in-out 0.2s;
  }

  & .slick-slider__container {
    margin-left: 20px;
    margin-right: 20px;

    @media screen and (max-width: $screen-xs-max) {
      margin-left: 0;
      margin-right: 0;
    }

    .slick-list {
      min-height: 94px;
    }
  }

  & .slick-slider__nav {
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -12px;
  }
}

.top-brands {
  $this: &;
  &__item {
    width: 205px ;
    display: inline-block !important;
    margin: 0 5px;
    &:hover #{$this}__logo {
      opacity: 0.6 !important;
    }
  }

  &__logo {
    opacity: 1 !important;
  }
}
