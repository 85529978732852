.rebateTag-wrapper {
	position: absolute;
}

.rebateTag {
	background-color: $red;
	box-sizing: border-box;
	color: white;
	display: flex;
	flex-flow: column;
	font-family: $font-normal;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	padding: 15px 20px 15px 15px;
	position: relative;
	text-transform: uppercase;
	@media screen and (max-width: 767px) {
		padding: 10px 15px 10px 10px;
	}
	&--XONY {
		.rebateTag__text--before {
			align-self: center;
			text-align: center;
		}
		.rebateTag__number {
			margin-top: 5px;
		}
		.rebateTag__number-text {
			font-size: 50px;
			display: flex;
			align-items: center;
			&__slash {
				position: relative;
				top: -3px;
			}
		}
		.rebateTag__text--boxed {
			align-self: center;
			background: white;
			color: $red;
			font-size: 20px;
			line-height: 1;
			margin-left: 10px;
			padding: 6px 10px;
			text-transform: uppercase;
		}
	}
	&__number {
		display: flex;
		position: relative;
	}
	&__number-sign {
		align-self: flex-start;
		flex: 0 0 auto;
		font-size: 24px;
		line-height: 1;
	}
	&__number-symbol {
		position: absolute;
		top: 0;
		right: -10px;
	}
	&__number-text {
		@include font(medium);
		white-space: nowrap;
		line-height: 1;
		flex: 0 0 auto;
		font-size: 58px;
		line-height: 0.8;
		&--XFORY {
			align-items: center;
			display: flex;
			line-height: 1;
			span {
				display: inline-block;
				line-height: 1;
				margin: auto 5px;
			}
		}
	}
	&__number-text--decimal {
		font-size: 25px;
		margin-top: 2px;
	}
	&__number-regularPrice {
		align-self: flex-end;
		font-family: $font-normal;
		font-size: 14px;
		margin: auto 5px 0;
		text-decoration: line-through;
	}
	&__after-number {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}
	&__text {
		font-family: $font-normal;
		font-size: 11px;
		line-height: 1.2;
		font-weight: bold;
		align-self: flex-start;
		&--after {
			margin-left: 2px;
			margin-bottom: auto;
			margin-top: 2px;
		}
	}
	&__description {
		background: rgba(255, 255, 255, 0.8);
		box-sizing: border-box;
		color: black;
		font-size: 0.8em;
		font-weight: bold;
		left: 0;
		padding: 0.8em;
		position: absolute;
		top: 100%;
		text-transform: none;
		width: 100%;
		@media screen and (max-width: 450px) {
			font-size: 0.69em;
		}
	}
}

.rebateTagNew {
	align-items: center;
	background: $red;
	bottom: 10px;
	color: white;
	display: flex;
	font-family: $font-normal;
	font-size: 14px;
	font-weight: bold;
	left: 0;
	line-height: 1;
	min-height: 46px;
	padding: 8px 10px 8px 10px;
	position: absolute;
	max-width: calc(100% - 10px);
	text-transform: uppercase;
	@media screen and (max-width: 475px) {
		font-size: 11px;
	}
	@media screen and (max-width: 374px) {
		font-size: 10px;
	}
	&__text {
		@media screen and (max-width: 374px) {
			font-size: 12px;
		}
		font-family: $font-normal;
		font-size: 14px;
		display: flex;
		align-items: center;
		b {
			font-family: $font-bold;
			font-size: 30px;
			margin: auto 5px;
			sup {
				font-size: 18px;
			}
		}
		sup {
			text-transform: none;
		}
	}
	&__number {
		align-items: center;
		display: flex;
		font-family: $font-bold;
		font-size: 30px;
		margin: auto 5px;
	}
	&__number-regularPrice {
		align-self: flex-end;
		display: flex;
		font-family: $font-normal;
		font-size: 14px;
		margin: auto 5px 0;
		text-decoration: line-through;
		.rebateTagNew__number-text--decimal {
			font-size: 10px;
		}
	}
	&__number-text {
		&--XFORY {
			align-items: center;
			display: flex;
			line-height: 1;
			span {
				display: inline-block;
				line-height: 1;
				margin: auto 5px;
			}
		}
	}
	&__number-sign {
		align-self: flex-start;
		font-size: 18px;
	}
	&__number-text--decimal {
		align-self: flex-start;
		font-size: 18px;
	}
}
