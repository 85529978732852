@media (max-width: 767px) {
  .footer__top .container .row:first-of-type .col-md-6.col-sm-6:not(.col-xs-12) {
    clear: both;
  }
}

@media screen and (max-width: 450px) {
  .rebateTag .rebateTag__description {
    font-size: 1em;
  }
}

.price-box {
  height: 75px;
  &--product-page {
    height: initial;
  }
}

sup {
  top: -0.5em;
}

#personalized-offer {
  margin: 0 0 20px !important;
}

#homePage #box.layoutBuilder {
  opacity: 0;
  transition: opacity 300ms ease;
  &.active {
    opacity: 1;
  }
}

.element-secure-transactions {
  display: none !important;
}

.submenu {
  height: 100%;
}

// Home & Garden RONA / Edmonton (South)-Edmonton
#productPage.store-62800 .page-product__store-inventory {
  display: none;
}

// Home & Garden RONA / Rona Calgary MacLeod
#productPage.store-62850 .page-product__cart__info,
#productPage.store-62800 .page-product__cart__info {
  display: none;
}

.select2-container {
  z-index: 49; //under header
}
@media screen and (max-width: 990px) {
  .select2-container--open {
    z-index: 51; //over header;
  }
}

// Paypal button z-index fix

.zoid-component-frame {
  z-index: 0 !important;
}

@media screen and (max-width: 991px) {
  #js-productFilter {
    min-height: 600px;
  }
}

// Category descriptions on the side of the pages
// Can be removed after 01 March 2019

.category-description--active:after {
  opacity: 0;
  height: 40px;
}

.ie9,
.ie10,
.ie11 {
  .product-tile__image-link {
    overflow: hidden;
  }
}

// Temporary hover effect on trending tiles in homepage
.c_1073874844 .promo {
  box-shadow: 0px 0px 10px #00000000;
  transition: all 250ms ease;
}
.c_1073874844 .promo:hover {
  box-shadow: 0px 0px 10px #00000059;
}

.c_1073874844 .promo__button {
  min-height: 60px;
}

.menu-mob__item__title {
  cursor: pointer;
}

//Temporary overwrite for .generic-product-slider--with-nav margin-top from 60px to 45px
.generic-product-slider--with-nav {
  margin-top: 45px;
}

// BC pointer events on rating stars
.bv-fieldset-rating a.bv-rating-link span {
  pointer-events: none;
}

.header__bottom__dropdown {
  top: 100%;
  border-top: 1px solid #dedede;
}

.search-autocomplete {
  z-index: 251;
}

.espot-mainHeaderBottom section {
  padding: 0;
}
