.background-color--main {
  background-color: $main-color;
  color: #ffffff;
}

.background-color--secondary {
  background-color: $secondary-color;
  color: #ffffff;
}

.background-color--gray {
  background-color: $gray;
}
