.common-mosaic {
  clear: both;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: space-between;
  @media (min-width: 992px) {
    @for $i from 1 through 24 {
      .order-md-#{$i} {
        order: $i;
      }
    }
  }
  @media (max-width: 767px) {
    @for $i from 1 through 24 {
      .order-xs-#{$i} {
        order: $i;
      }
    }
  }
  &--column {
    flex-flow: column;
    flex: 1 0;
    .col--grow-align-items-center {
      flex: 1 0 auto !important;
      align-items: center;
    }
  }
  &--justify-content-center {
    justify-content: center;
  }
  &--margin50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &.common-mosaic--marginLeftRight0 {
    &.row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &--padding-top-50-bottom-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &.common-mosaic--padding0 {
    &.row {
      margin-left: 0;
      margin-right: 0;
      > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &--padding30mobile15 {
    &.row {
      margin-left: -30px !important;
      margin-right: -30px !important;
      @media screen and (max-width: 767px) {
        margin-left: -15px !important;
        margin-right: -15px !important;
      }
      > [class*="col-"] {
        padding-left: 30px;
        padding-right: 30px;
        @media screen and (max-width: 767px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  .col--block {
    display: block !important;
  }
  .col--flex-column {
    flex-flow: column;
  }
  &.row {
    margin-left: -10px;
    margin-right: -10px;
  }
  > [class*="col-"] {
    display: flex;
    padding: 0 10px;
    .mosaic-tile {
      //background-color: #ebebeb;
      picture {
        flex: 1 0 auto;
        min-height: 1px;
      }
    }
  }
}
