.promo {
  position: relative;
  margin-bottom: 20px;
  &__title {
    color: black;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 50px 0 25px;
    position: relative;
    .topOffers__countdown {
      border-left: 9px solid white;
      color: white;
      display: inline-block;
      background: #ff0000;
      font-size: 22px;
      line-height: 1.2;
      padding: 5px 9px;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1;
      @media screen and (min-width: 992px) {
        transform: translateY(-50%);
      }
      @media screen and (max-width: 991px) {
        border: none;
        position: relative;
        right: 0;
        top: 50%;
        margin-top: 5px;
      }
      @media screen and (max-width: 767px) {
        border-left: 9px solid white;
        border-right: 9px solid white;
        font-size: 20px;
        line-height: 1.2;
      }
    }
    &:after {
      content: "";
      background: #c4c4c4;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
    }
    &--countdown {
      &:after {
        top: 16px;
        @media screen and (max-width: 767px) {
          top: 13px;
        }
      }
    }
    span {
      background: white;
      display: inline-block;
      padding-right: 22px;
      position: relative;
      z-index: 1;
    }
    @media screen and (max-width: 767px) {
      font-size: 22px;
      padding: 0 25px;
      text-align: center;
      span {
        padding: 0 15px;
      }
    }
  }
  &__subtitle {
    color: #3e3e3e;
    font-weight: bold;
    margin-top: 0;
    text-transform: uppercase;
  }
}

.promo__legal-modal-link {
  &:hover {
    text-decoration: underline;
  }
}

.promo-banner {
  &__topText {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    top: 0;
    padding: 10px;
    position: absolute;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
      text-align: right;
    }
  }
}

.promo__wrapper {
  font-size: 0;
}

#categoryPage .promo,
#departmentPage .promo {
  margin-top: 0;
  margin-bottom: 20px;
}

.promo.promo--with-legal {
  margin-bottom: 70px;
}

#categoryPage .promo--with-legal,
#departmentPage .promo--with-legal {
  margin-top: 0;
  margin-bottom: 65px;
}

.promo img {
  width: 100%;
}

.promo__inline-legal {
  color: white;
  font-size: 11px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-shadow: 0 0 5px rgba(66, 66, 66, 0.6);
}

.promo__button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.promo__button-strip {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.promo__button-strip .promo__button {
  position: static;
}

@media screen and (max-width: 767px) {
  .promo__button-strip {
    position: static;
    padding: 5px 0;
  }

  .promo__button-strip .promo__button {
    display: block;
  }

  .promo__button-strip .promo__button + .promo__button {
    margin-top: 5px;
  }
}

.button-strip--vertical {
  text-align: right;
}

.button-strip--vertical .button-cta {
  clear: both;
  float: right;
  display: inline-block;
}

.button-strip--vertical .button-cta + .button-cta {
  margin-left: 0;
  margin-top: 5px;
}

.promo__button-bar {
  position: relative;
  background: #00315c;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-top: 1px solid #fff;
}

.promo__button-bar .promo__button {
  flex: 1;
  color: #fff;
  padding: 10px 5px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $screen-lg-min) {
    flex: 1 0 33%;
    padding: 10px 5px;
    border-bottom: 1px solid #fff;
  }

  @media screen and (max-width: $screen-sm) {
    flex: 1 0 50%;
  }
}

.promo__button-bar .promo__button:hover {
  background: #fff;
  color: #00315d;
}

.promo__button-bar .promo__button + .promo__button {
  border-left: 1px solid #fff;
}

// TODO: deprecate, replaced by promo__dates
.datesPromo {
  font-size: 18px;
  text-transform: uppercase;
  color: $white;
  top: 0;
  padding: 10px;
  width: 100%;
  position: relative;
  background: #00264d;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-sm-min) {
    font-size: 3vw;
  }

  // TODO: deprecate, replaced by promo__date--left
  .texteGauche {
    text-align: left;
    padding-right: 10px;
    position: relative;
    flex: 1 1 50%;
    max-width: 45%;

    @media screen and (max-width: $screen-sm-min) {
      position: relative;
    }
  }

  // TODO: deprecate, replaced by promo__date--right
  .texteDroite {
    text-align: right;
    right: 10px;
    font-weight: bold;
    position: relative;
    font-weight: normal;
    flex: 1 1 50%;
    max-width: 45%;

    @media screen and (max-width: $screen-sm-min) {
      position: relative;
      text-align: left;
      right: auto;
    }
  }
}

.promo__dates {
  font-size: 18px;
  text-transform: uppercase;
  color: $white;
  top: 0;
  padding: 10px;
  width: 100%;
  position: relative;
  background: #00264d;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-sm-min) {
    font-size: 3vw;
  }
}

.promo__date {
  position: relative;
  flex: 1 1 50%;
  max-width: 45%;
}

.promo__date--left {
  text-align: left;
  padding-right: 10px;
}

.promo__date--right {
  text-align: right;
  right: 10px;
  font-weight: normal; // TODO: is this necessary?

  @media screen and (max-width: $screen-sm-min) {
    text-align: left;
    right: auto;
  }
}

.promo__date--center {
  flex: 1 1 100%;
  text-align: center;
  color: #fff;
  line-height: 1.42;
}

.promo--style-gen {
  .promo__inline-legal {
    position: absolute;
    bottom: auto;
    left: 30px;
    top: 30px;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff !important;
    max-width: 40%;
    @media screen and (max-width: $screen-lg-min) {
      left: 7%;
      top: 10%;
      font-size: 20px;
      line-height: 22px;
    }
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      max-width: 40%;
    }
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      max-width: 35%;
      font-size: 2vw;
      left: 5%;
      top: 7%;
    }
    @media screen and (max-width: $screen-xs-max) {
      font-size: 4vw;
      line-height: 5vw;
      max-width: 35%;
    }
    span {
      text-transform: none;
      font-size: 15px;
      display: block;
      width: 88%;
      line-height: 18px;
      font-weight: normal;
      color: #fff;
      margin-top: 5px;
      @media screen and (max-width: $screen-lg-min) {
        width: 100%;
        font-size: 13px;
        line-height: 15px;
      }
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 85%;
      }
      @media screen and (max-width: $screen-xs-max) {
        font-size: 3vw;
        line-height: 3.5vw;
        width: 85%;
      }
      &::after {
        content: "";
        -webkit-mask: url(https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg)
          no-repeat 100% 100%;
        mask: url(https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg)
          no-repeat 100% 100%;
        background-color: #fff;
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        display: inline-block;
        vertical-align: middle;
        -webkit-transition: margin-left 0.15s;
        transition: margin-left 0.15s;
        margin-right: -10px;
      }
    }
  }
}
