@mixin special-offer($main-color, $main-color-light, $tag-color, $rebate-color, $after-rebate-color, $arrow-size: 1.5em) {
	.special-offer {
		&__title {
			h2 {
				border-bottom: $main-color-light solid 1px;
			}

			background-color: $main-color;

			&__image {
				.special-offer-tag {
					fill: $tag-color;
				}
			}
		}

		&__rebate {
			background-color: $main-color-light;
			color: $rebate-color;

			p {
				color: $after-rebate-color;
			}

			&:after {
				right: calc(-#{$arrow-size});
				top: calc(50% - #{$arrow-size});
				border-left: $arrow-size solid $main-color-light;
				border-bottom: $arrow-size solid transparent;
				border-top: $arrow-size solid transparent;
			}
		}

		&__stores {
			border: $main-color-light solid 1px;

			.location-pin {
				fill: $main-color-light;
			}
		}
	}
}
