.common-card {
  align-content: center;
  align-items: center;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(black, 0.05);
  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: space-between;
  margin: 10px;
  min-width: 1px;
  text-decoration: none;
  transition: all 200ms ease;
  &[href]:hover {
    border: 1px solid #ddd !important;
    box-shadow: 0 0 10px rgba(black, 0.2);
  }

  &--no-border,&--no-border & {
    border: none;
    box-shadow: none;

    .common-card:hover {
      border: none !important;
      box-shadow: 0 1px 0 rgba(black, 0.2);
    }
  }

  &--round-corner {
    border-radius: 10px;
  }

  @media screen and (max-width: 767px) {
    &--hide-image-mobile .common-card__icon {
      display: none;
    }
    &--hide-description-mobile .common-card__description {
      display: none;
    }
  }

  &--no-margin-text-card {
    margin: 0 0 20px;
    .common-card__description {
      align-self: flex-start;
      font-size: 18px;
      line-height: 1.2;
      text-align: left;
    }
  }

  &__wrapper {
    text-decoration: none;
    display: flex;
    width: auto;
    height: auto;
  }

  &__title {
    align-self: flex-start;
    h3,
    h2 {
      padding-left: 5px;
      padding-top: 5px;
      margin: 0.2em;
      font-size: 3.5em;
    }
    font-family: DIN Next LT Pro Medium, sans-serif;

    text-transform: uppercase;
    span {
      color: $main-color;
    }
  }

  &__image {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    position: relative;
    text-align: center;
    width: 100%;
    border: none;
    body.notie & {
      flex: 0 0 auto;
    }
    body:not(.notie) & {
      min-height: 1px;
    }
    img {
      margin: 0;
      width: 100%;
    }
  }
  &__body {
    border-top: 1px solid #ddd;
    display: flex;
    flex: 1 0 auto;
    padding: 15px;
    justify-content: center;
    width: 100%;
  }

  &__description {
    align-self: center;
    text-align: center;
    span {
      font-weight: bolder;
      font-size: 1.55em;
    }
    p {
      font-size: 2.6em;
    }
    color: black;
  }

  &__cta {
    svg {
      display: none;
    }
    padding-bottom: 1.5em;
    .button {
      font-size: 2.6em;
      padding: 5px 15px;
      margin-left: auto;
      margin-right: auto;
    }
    &--text-rebate {
      justify-content: space-between;
      .common-card__cta__text-rebate {
        color: inherit;
        font-size: 2em;
      }
    }
  }

  &__icon {
    width:100%;
  }

  &__rebate {
    background: #fff10a;
    color: #000000;
    font-family: DIN Next LT Pro Bold, sans-serif;
    font-size: 3.6em;
    font-weight: bolder;
    left: 0px;
    min-height: 1.2em;
    min-width: 7em;
    padding: 2px 0;
    position: absolute;
    top: 10%;
  }
}

.common-card--vippro {
  background-color: $vip-pro-gray;

  .common-card__title {
    h3,
    h2 {
      color: #ffffff;
    }
    span {
      color: #fff10a;
    }
  }

  .common-card__description {
    color: #ffffff;
    //@include font(medium);
  }

  .common-card__cta {
    .button {
      background: #fff10a;
      color: #000000;
      font-size: 1.5em;
      padding: 15px 20px 10px;
      //@include font(bold);
    }
  }
}

.common-card--install {
  &__blue-model {
    background: #617694;
    margin-left: 0;
    margin-right: 0;
    padding: 30px 10px;
    width: 100%;
    .common-card__body {
      display: block;
      border-top: none;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
    .common-card__description--title {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      margin: 1em 0;
      text-align: center;
    }
    .common-card__description {
      color: #ffffff;
      font-size: 1.5em;
      line-height: 1.6em;
    }
    .common-card__icon {
      svg {
        width: 65px;
        height: 65px;
        margin: 0 auto;
        display: block;
      }
    }
    .common-card__cta {
      padding: 0;
      .button {
        background: transparent;
        color: #ffffff;
        text-transform: inherit;
        text-decoration: underline;
        font-size: 13px;
        padding: 0;
        font-weight: normal;
      }
    }
  }
}

.common-card--pill-title {
  .common-card__title {
    h3,
    h2 {
      background: #fff10a;
      color: black;
      border-top-right-radius: 31px;
      border-bottom-right-radius: 31px;
      display: inline-block;
      height: 62px;
      line-height: 1;
      padding: 15px;
      padding-right: 35px;
      position: relative;
      span {
        color: black;
        font-size: 24px;
      }
    }
  }
}

.common-card--border-top-cta {
  .common-card__cta {
    border-top: #ddd 1px solid;
  }
}

.common-card--arrow-cta {
  .common-card {
    &__body {
      padding: 10px;
      h4 {
        margin: 0;
        font-size: 2.4em;
        font-weight: normal;
      }
      justify-content: flex-start;
    }
    &__description--title {
      align-self: flex-start;
      justify-self: flex-start;
      color: #000000;
    }
  }
  .common-card__cta {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-items: flex-start;
    align-items: center;
    padding: 1.25em 1em;
    flex: 1 0 auto;
    .common-card__cta__icon-container {
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 1.5em;
    }
    .common-card__cta__button-container {
      margin-right: 20px;
    }
    svg {
      display: inline-block;
      height: 1.5em;
      margin-left: 1em;
      width: 1em;
      flex: 0 0 auto;
      fill: #000000;
    }
  }
  .button {
    background: none;
    background-color: unset;
    color: #000000;
    display: inline;
    font-size: 1.6em;
    font-weight: normal;
    margin: 0;
    max-width: 90%;
    padding: 0;
    text-transform: unset;
    flex: 0 0 auto;
    vertical-align: middle;
  }
}

.common-card--arrow-cta-main-color {
  .common-card__cta {
    svg {
      fill: $main-color;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .button,
  .common-card__cta__text-rebate {
    color: $main-color;
  }

  .common-card {
    &:hover {
      .common-card__cta {
        background-color: transparent;

        svg {
          fill: #000000;
        }

        .button,
        .common-card__cta__text-rebate {
          color: #000000;
        }
      }
    }
  }
}

.common-card--title-text-button {
  .common-card__body {
    border-top: none;
    flex-flow: column;
  }
  .common-card__description--title {
    color: black;
    font-size: 18px;
    margin-top: 0;
  }
  .common-card__description {
    align-self: flex-start;
    font-size: 14px;
    flex: 1 0 auto;
    text-align: left;
  }
  .common-card__cta .button {
    font-size: 14px;
    padding: 12px 20px;
  }
}

.common-card--small-title {
  .common-card__body {
    flex-flow: column;
    text-align: left;
    align-items: flex-start;
    .common-card__description {
      align-self: flex-start;
      font-size: 14px;
      text-align: left;
    }
    .common-card__description--title {
      margin: 0.25em 0 0 0;
      font-size: 16px;
      color: #000000;
    }
  }
}
.common-card--no-grow {
  margin: auto;
}
