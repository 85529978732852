.page-department {
  h1.page__title {
    display: block;
    font-size: 2.2em
  }
}

.espot-DepartmentBottomContent {
  .dept-useful-info__title {
    margin-top: 40px;
    margin-bottom: 0;
    padding-top: 40px;
    border-top: 1px solid #ddd;
  }

  .useful-info {
    padding-top: 0;
    border-top: 0;
  }
}

.useful-info__item {
  @media screen and (max-width: $screen-sm-max) {
    padding-left: 70px !important;
  }
}
