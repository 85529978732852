#enewsSubscribe .modal-content {
  width: 400px !important;
  max-width: 90%;
}

#subEnews {
  padding: 20px 0 0;
  width: 100%;

  .close {
    position: absolute;
    top: 24px;
    right: 20px;
  }

  h3 {
    font-size: 18px;
    font-family: $roboto;
    text-transform: none;
    padding-bottom: 15px;
    color: $black;
    border-bottom: 1px solid $form-gray;
    font-weight: normal;
    margin: 0;
    padding-right: 25px;
  }

  .airmilesLogo {
    &--title {
      font-size: 16px;
      color: $black;
      margin: 15px 0 0 50px;
    }

    &--amount {
      color: $amblue;
      font-size: 85px;
      font-weight: bold;
      padding-left: 0;
    }

    &--logo {
      padding-right: 0;
    }

    svg {
      float: right;
      width: 100px;
      height: 110px;
    }
  }

  .info {
    text-align: center;
    color: $dark-gray;

    &--title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 0 20px;
    }

    &--text {
      font-size: 12px;
      line-height: 2em;
    }

    &--term {
      color: $light-blue;
      font-size: 12px;
    }
  }

  #enewsForm {
    font-size: 12px;

    .formrow {
      max-width: 75%;
      margin: 10px auto;
    }

    .checkbox-custom {
      vertical-align: top;
    }

    .checkbox-label,
    p {
      font-size: 12px;
    }

    .checkbox {
      border-bottom: 1px solid $form-gray;
      padding-bottom: 10px;
    }

    #moreCS {
      display: none;
      color: $dark-gray;
      margin-top: 10px;

      ul {
        padding: 0;
      }

      li {
        list-style: none;
        text-indent: -5px;
        margin: 5px 0 5px 20px;

        &:before {
          content: "•";
          color: $dark-gray;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }

  .login {
    background: $am-dark-blue;
    color: $white;
    margin: 10px auto;
    padding: 10px 20px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    text-align: center;
    width: 50%;
    transition: background-color ease-in-out 0.2s;

    &:hover {
      background: $light-blue;
      transition: background-color ease-in-out 0.2s;
    }
  }
}
