.department-01-103 {
	position: relative;
	padding: 38px 0 25px;

	&:after {
		content: "";
		height: 13px;
		width: 100%;
		display: block;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		left: 50%;
		width: 100vw;
		background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/pattern_oblique_beige3.png");

		@media screen and (max-width: $screen-sm-min) {
			left: 100%;
		}
	}
}

body.fr {
	.page-department__inner--01-103 .category-page-box {
		&.DIY {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_1280x307_FR.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_768x320_FR.jpg");
			}
		}

		&.concours {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_635x307_FR.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_768x320_FR.jpg");
			}
		}

		&.petite-reno {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_635x307_FR.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_768x320_FR.jpg");
			}
		}

		&.gift {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_1280x307_FR.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_768x320_FR.jpg");
			}
		}
	}
}

.page-department__inner--01-103 {
	.category-page-box {
		height: 307px;
		margin-bottom: 10px;
		position: relative;
		display: block;
		background-size: auto 100%;
		background-repeat: no-repeat;

		@media screen and (max-width: 768px) {
			height: 44vw;
		}

		&.DIY {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_1280x307_EN.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_768x320_EN.jpg");
			}
		}

		&.concours {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_635x307_EN.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_768x320_EN.jpg");
			}
		}

		&.petite-reno {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_635x307_EN.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_768x320_EN.jpg");
			}
		}

		&.gift {
			background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_1280x307_EN.jpg");

			@media screen and (max-width: 768px) {
				background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_768x320_EN.jpg");
			}
		}

		.button-link {
			bottom: 10px;
			right: 10px;
			position: absolute;
			background: #fff;
			border: 1px solid #000;
			color: #000;
			background: #fff;
			padding: 10px 18px;
			display: inline-block;
			font-family: $roboto;
			font-size: 13px;
			font-weight: 400;
			line-height: 1em;
			margin-top: 10px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: all 250ms ease;

			&.red {
				background: #d80e3c;
				border: 1px solid #d80e3c;
				color: #fff;
			}

			&.blue {
				background: #0073b9;
				border: 1px solid #0073b9;
				color: #fff;
			}

			&.gold {
				background: #baa14c;
				border: 1px solid #baa14c;
				color: #fff;
			}

			&:hover {
				background: #fff;
				color: #000;
			}
		}
	}
}
