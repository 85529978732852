.banner5050 {
  display: flex;
  flex-flow: row;
  margin: 1em 0;
  position: relative;
  @include font-size-container;

  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
  &--content-padding-bottom {
    padding-bottom: 50px;
  }
  &--content-light {
    h3 {
      margin-top: 20px;
      span {
        color: $spring-hub-color-main;
        margin-top: 10px;
      }
    }
    p {
      color: #fff;
    }
    .button {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    picture,
    img {
      height: 100%;
    }
  }

  &--reverse-mobile {
    .banner5050-right {
      @media screen and (max-width: 767px) {
        order: -1;
      }
    }
  }
  &--margin50 {
    margin: 5em 0;
  }
}
@mixin banner5050colors {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 50%;
  flex: 1 1 auto;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 1em 0px;
  }

  > *:not(picture) {
    margin-left: 10px;
  }
  p {
    font-size: 1.8em;
  }

  h1 {
    margin-top: 1em;
    font-size: 3.6em;
  }

  h2 {
    margin-top: 1em;
    font-size: 2.2em;
  }

  .button {
    font-size: 1.4em;
  }

  &--main & {
    @extend .background-color--main;
  }
  &--secondary & {
    @extend .background-color--secondary;
  }
  &--gray & {
    @extend .background-color--gray;
  }
  &--content-center & {
    align-items: center;
    justify-content: center;
  }
  &--content-padding & {
    padding: 0 5em;
    @media screen and (max-width: 767px) {
      padding: 1em 0px;
    }
  }
}
%background {
}
.banner5050-left {
  @include banner5050colors;
}
.banner5050-right {
  @include banner5050colors;
}
