.flashsale-stores {
  margin-top: 10px;
  position: relative;

  &__header {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    padding: 25px 20px;
    background: #00315d;
  }

  &__title {
    max-width: 200px;
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    &__title {
      flex-basis: auto;
      max-width: calc(50% - 20px);
    }
  }

  &__logo,
  &__logo svg {
    width: 200px;
    max-width: 100%;
    max-height: 40px;
  }

  &__logo > div {
    display: flex;
  }

  &__date {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px;
  }

  @media screen and (max-width: 767px) {
    &__date {
      margin-top: 10px;
      order: 3;
      flex-basis: 100%;
      justify-content: center;
    }
  }

  &__day-of-week {
    margin-right: 0.35em;
    color: #fd0;
  }

  &__content {
    background: #fff;
    padding: 10px;
  }

  &__today-only {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: #fd0;
    color: #00315d;
    text-transform: uppercase;
    padding: 0 20px;
    font-weight: 800;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    &__today-only {
      flex-basis: auto;
      max-width: 50%;
    }
  }
}
