.flashsale {
	margin-bottom: 20px;
	background: #00315d;
	padding: 0 10px 10px;
	position: relative;

	&--with-legal {
		margin-bottom: 70px;
	}

	&--vertical {
		@media screen and (min-width: $screen-lg-min) {
			padding-top: 10px;

			& .flashsale__inner {
				display: flex;
				flex-direction: row;
			}

			& .flashsale__header {
				flex: 0 0 450px;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				background: radial-gradient(#004888, #00315d);
			}

			& .flashsale__content {
				flex: 1 0 auto;
			}

			& .flashsale__title {
				margin: 0;
				max-width: 300px;
			}

			& .flashsale__logo,
			& .flashsale__logo svg {
				width: 300px;
				margin: 0;
			}

			& .flashsale__date {
				margin: 20px 0;
				font-size: 20px;
			}

			& .flashsale__today-only {
				margin: 0;
				padding: 10px 20px;
				font-size: 19px;
			}
		}
	}

	&__header {
		display: flex;
		flex-wrap: wrap;
		color: #fff;
		padding: 15px 10px;
	}

	&__title {
		max-width: 200px;
		margin-right: auto;
		display: flex;
		align-items: center;

		@media screen and (max-width: 767px) {
			flex-basis: auto;
			max-width: calc(50% - 20px);
		}
	}

	&__logo,
	&__logo svg {
		width: 200px;
		max-width: 100%;
		max-height: 40px;
	}

	&__logo > div {
		display: flex;
	}

	&__date {
		display: flex;
		align-items: center;
		text-transform: uppercase;
		font-size: 18px;

		@media screen and (max-width: 767px) {
			margin-top: 10px;
			order: 3;
			flex-basis: 100%;
			justify-content: center;
		}
	}

	&__day-of-week {
		margin-right: 0.35em;
		color: #fd0;
	}

	&__inner {
		position: relative;
	}

	&__content {
		background: #fff;
		padding: 10px;
		position: relative;
	}

	&__today-only {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		background: #fd0;
		color: #00315d;
		text-transform: uppercase;
		padding: 0 20px;
		font-weight: 800;
		text-align: center;

		@media screen and (max-width: 767px) {
			flex-basis: auto;
			max-width: 50%;
		}
	}

	&__item {
		position: relative;
	}

	&__item__online-only {
		position: absolute;
		right: 40px;
		width: 75px;
		top: 5px;
	}

	&__item__image {
		margin-left: 100px;
		max-height: 250px;
		max-width: calc(100% - 100px);

		@media screen and (max-width: 768px) {
			margin: 0 auto;
			max-width: 100%;
		}
	}

	// on desktop, if we only have 1 or 2 products, center the images regardless
	@media screen and (min-width: 769px) {
		.slick-slider[data-count="1"] &__item__image,
		.slick-slider[data-count="2"] &__item__image {
			margin: 0 auto;
		}
	}

	.slick-slider__nav {
		position: static;
	}

	.slick-slider__nav .slick-prev,
	.slick-slider__nav .slick-next {
		position: absolute;
		top: auto;
		bottom: 10px;
		height: 36px;
		width: 36px;
		display: flex !important;
		align-items: center;
		justify-content: center;
	}

	.slick-slider__nav .slick-next.slick-disabled,
	.slick-slider__nav .slick-prev.slick-disabled {
		opacity: 0;
		pointer-events: none;
	}

	.slick-slider__nav .slick-prev svg,
	.slick-slider__nav .slick-next svg {
		width: 16px;
	}

	.slick-slider__nav .slick-prev svg path,
	.slick-slider__nav .slick-next svg path {
		fill: #00315d;
	}

	.slick-slider__nav .slick-prev svg {
		margin-top: 1px;
	}

	.slick-slider__nav .slick-next svg {
		margin-left: 3px;
	}

	.slick-slider__nav .slick-prev {
		left: 0;
	}

	.slick-slider__nav .slick-next {
		right: 0;
	}

	.slick-slider__nav .slick-dots {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 5px;
	}

	.slick-slider__nav .slick-dots li {
		margin: 0 4px 4px;
	}

	.slick-slider__nav .slick-dots button {
		box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.75);
		width: 14px;
		height: 14px;
	}

	&__inline-legal {
		background: #fff;
		padding: 10px 10px 0;
		color: #000;
		font-size: 12px;
	}

	.rebateTag .rebateTag__number:after {
		content: "‡";
	}

	.rebateTag .rebateTag__unitOfMeasure {
		display: none !important;
	}
}

// this might need to be extracted once we implement a sticky module
.flashsale--sticky {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 10px 0 0;

	@media screen and (max-width: 991px) {
		padding-right: 25px;
	}

	.flashsale__header {
		padding: 4px 10px 0;
		max-width: 1000px;
		margin: 0 auto;
		color: #fff;

		&:hover {
			color: #fff;
		}
	}

	.flashsale__label {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		background: none;
		color: #fff;
		text-decoration: underline;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 800;
	}
}
