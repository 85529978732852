.espot-DepartmentBottomContent {
  display: block; // temp
}
.logo--install:after {
  content: "";
  width: 30%;
  max-width: 150px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  background: url(https://www.rona.ca/documents/ronaResponsive/Install/laissez-nous-installer.png) no-repeat;
  background-size: 100% auto;
}

body.en .logo--install:after {
  background: url(https://www.rona.ca/documents/ronaResponsive/Install/get-it-installed.png) no-repeat;
  background-size: 100% auto;
}

.dept-install-diy {
  @include dept-block();
}

@import "_dept-install-diy-01-103";
