.standard-content-bg {
  color: white;
  padding: 3em;
  .standard-content__title {
    color: white;
    font-weight: bold;
    margin: 0;
    > span {
      font-size: 5em !important;
      @media screen and (max-width: 991px) {
        font-size: 3em !important;
      }
    }
    @media screen and (max-width: 767px) {
      align-self: center;
      text-align: center;
      > span {
        font-size: 4em !important;
      }
    }
  }
}

.standard-content {
  $background-path: "//www.renodepot.com/documents/RD/specialpages/spring-hub/" !default;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 10px;
  margin-bottom: 20px;
  width: 100%;
  .standard-content__title {
    font-size: inherit;
    line-height: 1.2;
    text-transform: none;
  }
  h2.standard-content__title {
    margin-bottom: 20px;
    > span {
      font-size: 3.2em;
    }
  }
  h3.standard-content__title {
    color: black;
    margin-bottom: 20px;
    > span {
      font-size: 2.4em;
    }
  }
  .standard-content__text {
    font-size: inherit;
    line-height: 1.2;
    margin-bottom: 20px;
    max-width: 1000px;
    > span {
      font-size: 1.8em;
    }
  }
  blockquote {
    font-size: 1.6em;
  }
  .standard-content__image {
    margin-bottom: 20px;
  }

  li {
    font-size: 1.6em;
    margin: 0.5em;
  }

  &--title-stroke {
    .standard-content__title {
      position: relative;
      padding-bottom: 10px;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0px;
        height: 1px;
        width: 100%;
        border-top: #dddddd solid 2px;
      }
    }
  }

  &--title-small {
    h2.standard-content__title {
      > span {
        font-size: 2.4em;
      }
    }

    h3.standard-content__title {
      > span {
        font-size: 2em !important;
      }
    }
  }

  &--big-title {
    .standard-content__title {
      > span {
        font-size: 4.5em !important;
        @media screen and (max-width: 991px) {
          font-size: 3.5em !important;
        }
        @media screen and (max-width: 767px) {
          font-size: 2.5em !important;
        }
      }
    }
  }
  &--no-margin {
    margin: 0;
  }
  &--bg-topo {
    @extend .standard-content-bg;
    background-image: url("#{$background-path}/yard-care-bg-video.jpg");
    @media screen and (max-width: 767px) {
      background-image: url("#{$background-path}/yard-care-bg-video-mobile.jpg");
    }
    .standard-content__title > span span {
      color: $spring-hub-color-main;
    }
  }
  &--bg-leaves {
    @extend .standard-content-bg;
    background-image: url("#{$background-path}/RD-bg-video-desktop.jpg");
    @media screen and (max-width: 767px) {
      background-image: url("#{$background-path}/RD-bg-video-mobile.jpg");
    }
  }
  &--bg-light-leaves {
    @extend .standard-content-bg;
    background-image: url("#{$background-path}/Lowes-bg-video-desktop.jpg");
    color: black;
    @media screen and (max-width: 767px) {
      background-image: url("#{$background-path}/Lowes-bg-video-mobile.jpg");
    }
  }

  &--spring-hub--font-highlight {
    span {
      color: #ffffff;
      text-transform: none;
    }
    span > span {
      font-size: 1em;
      color: $spring-hub-color-main;
    }
  }
  &--text-centered {
    background: $spring-hub-color-blue;
    color: $spring-hub-color-main;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    .button {
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 30px;
    }
    .standard-content__text {
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
      span {
        font-size: 2.2em;
        > span {
          font-size: 1em;
        }
      }
    }

    .standard-content__title {
      color: $spring-hub-color-main;
      font-size: 2.4em;
    }
  }

  &--background-main-color {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    margin-bottom: 20px;

    background: $main-color;
    color: #ffffff;
    .button {
      background: #ffffff;
      color: $main-color;

      &:hover {
        background: $main-color;
        color: #ffffff;
      }
    }
  }

  &--image-first {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    .standard-content__title,
    .standard-content__text {
      width: 100%;
    }
    .standard-content__image {
      order: -1;
      width: 100%;
      min-height: 1px;
    }
    .button {
      margin-top: auto;
    }
  }

  &--svg-first {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    .standard-content__title,
    .standard-content__text {
      width: 100%;
    }
    .standard-content__svg {
      order: -1;
      width: 100%;
      min-height: 1px;
    }
    .button {
      margin-top: auto;
    }
  }

  &--justify-center {
    justify-content: center;
  }

  &--step {
    margin-bottom: 8em;
    .standard-content__step-number {
      color: $main-color;
      font-size: 45px;
      margin-right: 5px;
    }
    .standard-content__text {
      flex: 1 0 auto;
    }
  }

  &--text-normal {
    .standard-content__text {
      font-weight: normal;
    }
  }
  &--flex-column-text-grow {
    display: flex;
    flex-flow: column;
    .standard-content__text {
      flex: 1 0 auto;
    }
  }
  &--center-max-width-800 {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
  &--text-align-center {
    text-align: center;
    .standard-content__text {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--text-align-center-xs {
    @media screen and (max-width: 767px) {
      text-align: center;
      .standard-content__text {
        margin-left: auto;
        margin-right: auto;
      }
      ul {
        text-align: left;
      }
    }
  }
  &--align-self-center {
    align-self: center;
  }
  &--align-items-center {
    align-items: center;
    text-align: center;
    .standard-content__content-wrapper {
      align-items: center;
    }
  }
  &--padding-left {
    padding-left: 50px;
  }
  &--padding-right {
    padding-right: 50px;
  }
  &--padding-left-min-sm {
    @media screen and (min-width: 768px) {
      padding-left: 30px;
    }
  }

  &-text--white {
    color: white;
    h1,
    h2,
    h3,
    h4,
    span {
      color: white;
    }
  }
}
