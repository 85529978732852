.matc__button {
	position: relative;
	.la-line-scale {
		position: absolute;
		top: calc(50% - 16px);
		left: calc(50% - 20px);
		opacity: 0;
		pointer-events: none;
		transition: all 300ms ease;
	}
	&.loading {
		color: #dedede;
		.la-line-scale {
			opacity: 1;
		}
	}
}
