.productsModal {
}

.productsModal__content {
	max-width: 1400px;
	width: calc(100% - 20px);
	//overflow: hidden;
	display: flex;
	flex-direction: column;
	@media all and (max-width: 1199px) {
		overflow-y: auto;
	}
}

.productsModal__wysiwyg {
	overflow-y: auto;
	height: 100%;
	color: black;

	img {
		margin-bottom: 15px;
	}

	h2 {
		font-size: 22px;
		text-transform: none;
		color: #8c8c8c;
		font-weight: normal;
	}
	h3 {
		font-size: 18px;
		text-transform: none;
		color: #a5a5a5;
		margin-bottom: 0.5em;
		font-weight: normal;
	}
	p {
		line-height: 1.5em;
		font-size: 15px;
	}
}

.productsModal__products {
	display: flex;
	flex-wrap: wrap;
}
.productsModal__product {
	border: 1px solid #f5f5f5;
	flex: 1 0 auto;
	padding: 10px;
	margin: 0 0 10px;
	color: black;
	transition: all 250ms ease;
	&:hover {
		border-color: #e0e0e0;
		box-shadow: 0 0 10px rgba(0, 0, 5px, 0.03);
		@media all and (min-width: 1200px) {
			.overlayIconButton {
				span {
					width: 150px;
					opacity: 1;
				}
			}
		}
	}
	&__description {
		line-height: 1.5em;
		font-size: 15px;
		margin-bottom: 0;
	}
	&__price {
		font-size: 18px;
		margin-top: 5px;
	}
	&__image {
		position: relative;
		font-size: 0;
		display: block;
		background-size: contain;
		background-repeat: no-repeat;
		height: 240px;
		background-position: 50% 50%;
		&--from-sku {
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	&__more {
		text-decoration: underline;
	}
	.overlayIconButton {
		display: none;
		@media all and (max-width: 1199px) {
			span {
				display: block;
				width: auto;
				padding: 0 15px 0 5px;
			}
		}
	}
}
.productsModal__row {
	height: 100%;
	@media all and (min-width: 1200px) {
		display: flex;
	}
}
.productsModal__col {
	padding: 10px;
	&:first-of-type {
		width: 45%;
	}
	&:last-of-type {
		width: 55%;
		display: flex;
		flex: 1;
		min-height: 0px;
	}
	@media all and (max-width: 1199px) {
		&:first-of-type,
		&:last-of-type {
			padding: 0 0 10px;
			width: 100%;
		}
	}
}
