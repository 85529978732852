.espot-categoryPageTopPromoBox,
.espot-productPageTopPromoBox {
  display: block;
}

.espot-mainHeaderBottom {
	overflow: hidden;
	&--closed {
		display: none !important;
	}
}

//LayoutBuilder need to be overwrited to be responsive
.layoutBuilder {
	padding-bottom: 30px;
	width: auto !important;
}

.espot-debug-style {
	display: inline-block;
	padding: 5px;
	max-width: 100%;
	background: $mid-gray;
	font-size: 12px;
}

.espot-container:after,
.espot-container:before {
	content:" ";
	display:table;
}
.espot-container:after{
	clear:both
}

.espot-product-tile {
	background-repeat: no-repeat;
	background-position: center center;
	border: 1px solid #ddd;
	display: block;
	margin: -1px 0 0 -1px;
	position: relative;
	.promo__button {
		white-space: nowrap;
		right: 50%;
		transform: translateX(50%);
	}
}

.espot-calculator {
	display: none;
	&.active {
		display: block;
	}
	.button {
		margin-top: 15px;
	}
}

/*
	Espot ID:
	Jsp file:
	Url:
	Width:
	Location:

	Old Width:
	Old Location:
*/

/*
	Espot ID: DepartmentPullDown-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Top of every department pages, Under the Title

	Old Width: 749px
	Old Location: Top Right of every department pages
*/
.espot-DepartmentPullDown {
	display: none;
}

/*
	Espot ID: DepartmentTopContent-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Top of every department pages, Under DepartmentPullDown

	Old Width: 749px
	Old Location: Top Right of every department pages, Under espot-DepartmentPullDown
*/
.espot-DepartmentTopContent {
	display: none;
}

/*
	Espot ID: DepartmentSidebar-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Top of every department pages, Under DepartmentPullDown

	Old Width: 224px
	Old Location: Top Right of every department pages, Under espot-DepartmentPullDown
*/
.espot-DepartmentSidebar {
	display: none;
}

/*
	Espot ID: DepartmentMidContent-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Under the department listing in every department pages

	Old Width: 749px
	Old Location: Under the department listing in every department pages (right)
*/
.espot-DepartmentMidContent {
	display: block;
}

/*
	Espot ID: ScrollingTiles-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Under the department listing in every department pages, Under espot-DepartmentMidContent

	Old Width: 749px
	Old Location: Under the department listing in every department pages, Under espot-DepartmentMidContent (right)
*/
.espot-ScrollingTiles {
	// display: none;
}

/*
	Espot ID: DepartmentBottomContent-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Under the department listing in every department pages, Under espot-ScrollingTiles

	Old Width: 749px
	Old Location: Under the department listing in every department pages, Under espot-ScrollingTiles (right)
*/
.espot-DepartmentBottomContent {
	// display: none;
}

/*
	Espot ID: Category page top promo box
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100%
	Location: Top of every category page

	Old Width: 100%/1000px
	Old Location: Top of every category page
*/
.espot-categoryPageTopPromoBox {
	display: block;
}

/*
	Espot ID: Product Category Sidebar
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100% of sidebar / smallest width possible : 212px
	Location: Under the filters in every category page

	Old Width: 224px
	Old Location: Under the filters in every category page
*/
.espot-productCategorySidebar {
	display: none;
}

/*
	Espot ID: Category page footer promo box left
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100%
	Location: Under the product listing and paging

	Old Width: 100%
	Old Location: Under the product listing and paging
*/
.espot-categoryPageFooterPromoBoxLeft {
	display: none;
}

/*
	Espot ID: Category page footer promo box right
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100%
	Location: Under the product listing and paging, Under espot-categoryPageFooterPromoBoxLeft

	Old Width: 100%
	Old Location: Under the product listing and paging, Under espot-categoryPageFooterPromoBoxLeft
*/
.espot-categoryPageFooterPromoBoxRight {
	display: none;
}

/*
	Espot ID: Shopping cart promo box A
	Jsp file: RonaShopcartEspots.jsp ( included in : RonaOrderBillingDetails.jsp, RonaCheckoutPayment.jsp, RonaOrderItemDisplay.jsp )
	Url: /webapp/wcs/stores/servlet/AjaxOrderItemDisplayView?langId=-1&catalogId=10051&storeId=10151&krypto=pWhATButUOlqH5kcfZBXl%2BmYK6ItUCV33XpCMppoM0fvO9aLQeGGXL%2BV4lAdPWBzKRQmTtF9ue5N%0D%0AuJrPZOt1sckpYeUCcUgBK%2F%2FGObD1Jb0hx487NbRDeKjBma47V8GQdFbCAPeacT6X3lrHw2PrPH%2Fx%0D%0AVdO0tfnVYnEUWNIZfQBMkdhy7d192SfGEGmqwCF8gE7zgE5t%2Bx80XEJe7638h53D1nCu4lZXXr0S%0D%0Avv72Yp1ivilv%2B57IqBQu4u3Gd7ZAl5ZIhmE48AKaiJKVfkqagN%2Fl5Tcnd1CZzPaS40ZLf%2Fgjz7uN%0D%0AqcRediN8%2B2JX4pKNUwB1vHCK2kC4Hy6efrPGLHvgYxH6J2L620vsZ9qlV%2Fv6GQ1U8XZcWQ%3D%3D&ddkey=https:OrderCalculate
	Width: 100%
	Location: Under the cart, just above the disclaimers

	Old Width: 100%
	Old Location: Under the cart, just above the disclaimers
*/
.espot-shoppingCartPromoBoxA {
	display: block;
}

/*
	Espot ID: PromotionsDropDown
	Jsp file: RonaCachedHeaderNavPromo.jspf
	Url: All
	Width: 100% of the submenu
	Location: Under DEALS & FLYER in the header main menu

	Old Width: 100% of the submenu
	Old Location: Under Promotions
*/
.espot-promotionsDropDown {

}

/*
	Espot ID: ProductMenuCategory-[Department ID]
	Jsp file: RonaCachedHeaderNavProductsOverlay.jsp
	Url: All
	Width: 33.333333% of the department submenu
	Location: right side In every department submenu under the see all button (desktop Only)

	Old Width: 209px
	Old Location: At the right of every department submenu
*/
.espot-productMenuCategory {

}

/*
	Espot ID: Installation-service-dropdown-right
	Jsp file: RonaCachedHeaderNabInstall.jspf
	Url: All
	Width: 66.666666% of the install menu
	Location: right side of the installation services submenu

	Old Width: N/A
	Old Location: N/A
*/
.espot-InstallationServiceDropdownRight {

}

/*
	Espot ID: Product page bottom promo box
	Jsp file: RonaProductDetailsDisplay.jsp
	Url: /webapp/wcs/stores/servlet/Product1_10151_10051_315015_-1
	Width: 100%
	Location: Bottom of product page under the disclaimers

	Old Width: 100%
	Old Location: Bottom of product page under the disclaimers
*/
.espot-productPageBottomPromoBox {
	display: none;
}


/*
	Espot ID: Product page top promo box
	Jsp file: RonaProductDetailsDisplay.jsp
	Url: /webapp/wcs/stores/servlet/Product1_10151_10051_315015_-1
	Width: 100%
	Location: Top of product page under the breadcrumb

	Old Width: 100%
	Old Location: Top of product page under the breadcrumb
*/
.espot-productPageTopPromoBox {
	display: block;
}

/*
	Espot ID: HeaderTopLinks
	Jsp file: RonaCachedHeaderDisplay.jsp
	Url: All pages
	Width: 100%
	Location: Top of the page over the main header
*/
.espot-headerTopLinks {
	// display: none;
}

/*
	Espot ID: Main Header Bottom
	Jsp file: RonaCachedHeaderDisplay.jsp
	Url: All pages
	Width: 100%
	Location: Under header__bottom

	Old Espot ID: Main Header Right
	Old Width: 600px
	Old Location: At the Right of the logo in the header
*/
#homePage .espot-mainHeaderBottom {
	display: none;
}

/*
	Espot ID: CartItem{SKU}
	Jsp file: RonaOrderItemDetail.jsp
	Url: /webapp/wcs/stores/servlet/AjaxOrderItemDisplayView?langId=-2&catalogId=10051&storeId=10151
	Width: 100%
	Location: Under quantity and price fields
*/
.espot-cartItem {
	display: none;
}

/*
	Espot ID: MiniCart
	Jsp file: RonaAddedOrderItemDisplay.jsp
	Url: None
	Width: 100%
	Location:  When adding a product to cart, in the modal on the left of the subtotal
*/
// .espot-miniCart {
// 	display: none;
// }

/*
	Espot ID: OrderConfirmation
	Jsp file: RonaCheckoutConfirmationView.jsp
	Url: None
	Width: 100%
	Location: Any order confirmation page
*/
.espot-orderConfirmation {
	display: none;
}

/*
	Espot ID: allProductsPageTop
	Jsp file: RonaViewAllProductsFiltersDisplay.jsp
	Url: /webapp/wcs/stores/servlet/RonaViewAllProductsDisplayView?catalogId=10051&urlLangId=-2&langId=-2&storeId=10151
	Width: 100%
	Location: top of the all product page

	Old Espot ID: All products page top promo box right
	Old Width: 408px
	Old Location: At top right of the allproduct page
*/
.espot-allProductsPageTop {
	// display: none;
}


/*
	Espot ID: allProductsPageFooter
	Jsp file: RonaViewAllProductsDisplay.jsp
	Url: /webapp/wcs/stores/servlet/RonaViewAllProductsDisplayView?catalogId=10051&urlLangId=-2&langId=-2&storeId=10151
	Width: 100%
	Location: bottom of the all product page

	Old Espot ID: All products page footer promo box left, All products page footer promo box right
	Old Width: half and half with 2 espots
	Old Location: At bottom right of the allproduct page
*/
.espot-allProductsPageFooter {
	// display: none;
}

/*
	Espot ID: FooterCatfishTop
	Jsp file: RonaCachedFooterDisplay.jsp
	Url: None
	Width: 100%
	Location: At the top of the footer
*/
.espot-FooterCatfishTop {

}

.layoutBuilder div[class^="c_"] {
	width: auto !important;
	float: none !important;
}
