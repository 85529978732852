body:not(.en) .show-EN,
body:not(.fr) .show-FR {
	display: none !important;
}

body:not(.BIG_BOX) .show-BB,
body:not(.PROXIMITY) .show-PX {
	display: none !important;
}

.BC-AB-SK-MB,
body.QC .ROC,
body.QC .show-ROC-only {
	display: none !important;
}

body:not(.QC) .show-QC-only {
	display: none !important;
}

body.AB,
body.BC,
body.MB,
body.SK {
	.BC-AB-SK-MB {
		display: block !important;
	}
}

.show-BC,
.show-AB,
.show-SK,
.show-MB,
.show-ON,
.show-QC,
.show-NL,
.show-NT,
.show-NS,
.show-PE,
.show-YT,
.show-NB {
	display: none;
}
body.BC .show-BC,
body.AB .show-AB,
body.SK .show-SK,
body.MB .show-MB,
body.ON .show-ON,
body.QC .show-QC,
body.NL .show-NL,
body.NT .show-NT,
body.NS .show-NS,
body.PE .show-PE,
body.YT .show-YT,
body.NB .show-NB {
	display: block;
}

.show-BC-inline-block,
.show-AB-inline-block,
.show-SK-inline-block,
.show-MB-inline-block,
.show-ON-inline-block,
.show-QC-inline-block,
.show-NL-inline-block,
.show-NT-inline-block,
.show-NS-inline-block,
.show-PE-inline-block,
.show-YT-inline-block,
.show-NB-inline-block {
	display: none;
}

body.BC .show-BC-inline-block,
body.AB .show-AB-inline-block,
body.SK .show-SK-inline-block,
body.MB .show-MB-inline-block,
body.ON .show-ON-inline-block,
body.QC .show-QC-inline-block,
body.NL .show-NL-inline-block,
body.NT .show-NT-inline-block,
body.NS .show-NS-inline-block,
body.PE .show-PE-inline-block,
body.YT .show-YT-inline-block,
body.NB .show-NB-inline-block {
	display: inline-block;
}

#Enews__banner img {
	width: 100%;
}

body:not(.parcel) .show-parcel {
	display: none;
}

body:not(.corporate) .show-corporate {
	display: none;
}

body.corporate .show-affiliate {
	display: none;
}


[data-element-start-date]:not(.js-date-confirmed),
[data-element-end-date]:not(.js-date-confirmed) {
	display: none !important;
}
