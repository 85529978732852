.product-tile-rebate-container {
	padding-bottom: 30px;
	height: 100%;
	position: relative;
	@media screen and (max-width: 767px) {
		padding-bottom: 20px;
	}
	.product-tile-rebate {
		margin-bottom: 0;
		height: 100%;
	}
	.banner-legal-container {
		position: absolute;
		bottom: 6px;
		width: 100%;
		left: 0;
	}

	picture {
		display: flex;
	}
}

.product-tile-rebate {
	background: white;
	border: 1px solid #dbdbdb;
	display: flex;
	line-height: 1.4;
	margin-bottom: 30px;
	text-decoration: none;
	position: relative;

	&--small {
		margin-bottom: 15px;
		.product-tile-rebate__info {
			display: flex;
			width: 65%;
		}
		.product-tile-rebate__info__text {
			align-items: center;
			display: flex;
		}
		.product-tile-rebate__image-container {
			width: auto;
			margin-left: auto;
			margin-bottom: 0;
			flex: auto;
		}
		.button {
			padding: 10px 15px;
		}
		.banner-legal-text-rebate {
			left: 160px;
		}
	}
	@media screen and (max-width: 767px) {
		margin-bottom: 10px;
	}
	.button {
		bottom: 15px;
		position: absolute;
		right: 15px;
		@media screen and (max-width: 767px) {
			bottom: 10px;
			right: 10px;
		}
	}
	&--oe {
		border: none;

		.product-tile-rebate-new__info__text:after {
			content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill: %231b636f;' data-name='icon-arrow-right' viewBox='0 0 257.33 171.57'%3E%3Cpath d='M254.48,121.56h.58q18.21,18.66,36.44,37.31c.66.68,1.44,1.25,2.16,1.87v.58c-.78.72-1.59,1.4-2.33,2.16q-62,63.48-123.92,127a17.43,17.43,0,0,0-1.79,2.65l-1.24,0a18,18,0,0,0-1.8-2.64Q100.75,227.06,38.88,163.7c-.81-.83-1.69-1.58-2.54-2.37q0-.29,0-.58c.72-.62,1.5-1.19,2.16-1.87q18.23-18.65,36.44-37.32h.58a20.2,20.2,0,0,0,1.75,2.19q42.84,40.79,85.71,81.54c.62.59,1.28,1.14,2,1.8.53-.45,1-.8,1.39-1.19q43.39-41.27,86.76-82.56A12.82,12.82,0,0,0,254.48,121.56Z' transform='translate(-36.33 -121.56)'/%3E%3C/svg%3E");
		}

		.product-tile-rebate-new__info {
			background: #9dcacf;
			border-top: none;
			color: $main-color;
		}

		.rebateTagNew {
			background: $main-color;
			color: $rebate-tag-new;
		}

		.product-tile-rebate-new__image-container {
			background: $main-color-light;
		}
	}
}

.product-tile-rebate__info {
	flex: 0 0 auto;
	width: 162px;
	&__text {
		color: black;
		font-family: $font-normal;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.2;
		padding: 15px;
		text-transform: uppercase;
		@media screen and (max-width: 767px) {
			padding: 10px;
		}
	}
}

.product-tile-rebate__image-container {
	flex: 1 1 auto;
	margin-bottom: 30px;
	img {
		display: block;
		width: 100%;
	}
}

.product-tile-rebate--b {
	&:after {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		content: "";
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		transition: opacity 0.3s ease;
		top: 0;
		width: 100%;
	}
	&:hover {
		&:after {
			opacity: 1;
		}
	}
	.button {
		display: none;
	}
	.product-tile-rebate__image-container {
		margin-bottom: 0px;
	}
	.product-tile-rebate__info__text {
		&:after {
			content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill: %23000000;' data-name='icon-arrow-right' viewBox='0 0 257.33 171.57'%3E%3Cpath d='M254.48,121.56h.58q18.21,18.66,36.44,37.31c.66.68,1.44,1.25,2.16,1.87v.58c-.78.72-1.59,1.4-2.33,2.16q-62,63.48-123.92,127a17.43,17.43,0,0,0-1.79,2.65l-1.24,0a18,18,0,0,0-1.8-2.64Q100.75,227.06,38.88,163.7c-.81-.83-1.69-1.58-2.54-2.37q0-.29,0-.58c.72-.62,1.5-1.19,2.16-1.87q18.23-18.65,36.44-37.32h.58a20.2,20.2,0,0,0,1.75,2.19q42.84,40.79,85.71,81.54c.62.59,1.28,1.14,2,1.8.53-.45,1-.8,1.39-1.19q43.39-41.27,86.76-82.56A12.82,12.82,0,0,0,254.48,121.56Z' transform='translate(-36.33 -121.56)'/%3E%3C/svg%3E");
			display: inline-block;
			font-size: 0;
			height: 5px;
			width: 9px;
			transform: rotate(-90deg);
			margin-left: 2px;
			position: relative;
			top: -1px;
		}
	}
}

.product-tile-rebate-new {
	background: white;
	border: 1px solid #dfdfdf;
	color: black;
	display: flex;
	flex-flow: column;
	position: relative;
	text-decoration: none;
	&:after {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		content: "";
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		transition: opacity 0.3s ease;
		top: 0;
		width: 100%;
	}
	&:hover:not(div) {
		&:after {
			opacity: 1;
		}
	}
	&__image-container {
		min-height: 1px;
		position: relative;
		flex: 1 0 auto;
		display: flex;
	}
	&__info {
		align-items: center;
		border-top: 1px solid #dfdfdf;
		display: flex;
		min-height: 60px;
		&__text {
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 1.2;
			padding-left: 10px;
			@media screen and (max-width: 767px) {
				padding-right: 10px;
				&:after {
					content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill: %23000000;' data-name='icon-arrow-right' viewBox='0 0 257.33 171.57'%3E%3Cpath d='M254.48,121.56h.58q18.21,18.66,36.44,37.31c.66.68,1.44,1.25,2.16,1.87v.58c-.78.72-1.59,1.4-2.33,2.16q-62,63.48-123.92,127a17.43,17.43,0,0,0-1.79,2.65l-1.24,0a18,18,0,0,0-1.8-2.64Q100.75,227.06,38.88,163.7c-.81-.83-1.69-1.58-2.54-2.37q0-.29,0-.58c.72-.62,1.5-1.19,2.16-1.87q18.23-18.65,36.44-37.32h.58a20.2,20.2,0,0,0,1.75,2.19q42.84,40.79,85.71,81.54c.62.59,1.28,1.14,2,1.8.53-.45,1-.8,1.39-1.19q43.39-41.27,86.76-82.56A12.82,12.82,0,0,0,254.48,121.56Z' transform='translate(-36.33 -121.56)'/%3E%3C/svg%3E");
					display: inline-block;
					font-size: 0;
					height: 6px;
					width: 10px;
					transform: rotate(-90deg);
					margin-left: 4px;
					position: relative;
					top: -1px;
				}
			}
		}
		.button {
			flex: 0 0 auto;
			margin: 10px;
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
	}
	&__top-text {
		text-transform: uppercase;
		font-family: $font-normal;
		background: $main-color-dark;
		color: #fff;
		font-size: 22px;
		padding: 20px 15px;
		text-align: center;
		span {
			@media (min-width: 768px) and (max-width: 1199px) {
				display: block;
			}
			color: $main-color-light;
		}
	}
}

.col-xs-6 {
	@media screen and (max-width: 475px) {
		.product-tile-rebate-new__image-container {
			padding-bottom: 35px;
		}
	}
}
