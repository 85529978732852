.button-text-arrow {
  display: block;
  margin-bottom: 15px;
  text-align: right;
  &--red {
    &:after {
      border-left: 5px solid #e82535;
    }
  }
  span {
    color: black;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
  }
  &:after {
    content: "";
    display: inline-block;
    border-left: 10px solid $blue;
    border-right: 0px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin-left: 10px;
    vertical-align: middle;
  }
}

.button {
  &--white {
    background: white;
    color: black;
    &:hover {
    }
  }
}
