.accordion-title-arrow {
  color: $dark-gray;
  margin-left: 17px;
  text-indent: -17px;

  svg {
    fill: $dark-gray;
    transform: rotate(-90deg);
    transition: transform 0.2s;
    top: -2px;
    position: relative;
    margin-right: 5px;
  }

  &:after,
  &:before {
    content: none;
  }

  &.active {
    color: $blue;

    svg {
      transform: rotate(0deg);
      transition: transform 0.2s;
    }
  }
}
