.workshop-projects {
	display: flex;
	flex-wrap: wrap;
	margin: -10px;
	.workshop-project {
		width: calc(25% - 20px);
		margin: 10px;
		@media all and (max-width: 991px) {
			width: calc(33.33% - 20px);
			margin: 10px;
		}
		@media all and (max-width: 767px) {
			width: calc(50% - 20px);
			margin: 10px;
		}
	}
}

.workshop-project {
	min-height: 1px;
	background: #f3f3f3;
	box-shadow: 0 0 17px rgba(black, 0);
	transition: all 300ms ease;
	margin: 10px 0;
	display: block;
	&:hover {
		box-shadow: 0 0 17px rgba(black, 0.18);
		.workshop-project__image:before {
			opacity: 0.15;
		}
	}
	&--featured {
		flex: 1 0 auto;
		.workshop-project__tag {
			opacity: 1;
		}
	}
	&__color-mosaic {
		flex: 0 0 44px;
		font-size: 0;
		margin: 0 15px 0 -1px;
		span {
			display: inline-block;
			height: 20px;
			width: 20px;
			margin: 1px;
		}
	}
	&__image {
		display: block;
		margin: 0;
		position: relative;
		font-size: 0px;
		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background: black;
			top: 0px;
			left: 0px;
			opacity: 0;
			transition: all 300ms ease;
		}
		.workshop-project__image__play,
		.related-article__image__play {
			fill: white;
			position: absolute;
			height: 70px;
			width: 70px;
			display: block;
			top: calc(50% - 35px);
			left: calc(50% - 35px);
			opacity: 0.9;
			transition: all 300ms ease;
			@media screen and (min-width: 992px) and (max-width: 1300px) {
				height: 40px;
				width: 40px;
				display: block;
				top: calc(50% - 20px);
				left: calc(50% - 20px);
			}
		}
		.workshop-project__image__play {
			opacity: 0;
			transition: all 300ms ease;
			transition-delay: 200ms;
		}
	}
	&__tag {
		position: absolute;
		top: 10px;
		left: 0px;
		width: 135px;
		opacity: 0;
		transition: all 300ms ease;
		transition-delay: 200ms;
		&--attribute {
			height: 21px;
			z-index: 1;
			width: auto;
		}
		&--big {
			top: 20px;
			width: 200px;
			@media screen and (max-width: 767px) {
				width: 135px;
				top: 10px;
			}
			&.workshop-project__tag--attribute {
				height: 32px;
				width: auto;
			}
		}
		@media screen and (max-width: 767px) {
			display: none !important;
		}
	}
	&__duration {
		position: absolute;
		bottom: 10px;
		display: block;
		right: 20px;
		z-index: 3;
		font-size: 14px;
		color: white;
		background: rgba(black, 0.25);
		padding: 2px 5px;
		border-radius: 6px;
	}
	&__title {
		color: black;
		flex: 1 1 auto;
		font-size: 17px;
		font-weight: normal;
		line-height: 1.4em;
		margin: 0;
		padding: 25px 20px;
		text-align: center;
		text-transform: none;
		@media screen and (max-width: 767px) {
			padding: 15px 10px;
			font-size: 15px;
		}
	}
	&__title-container {
		display: flex;
		align-items: center;
	}
}
