// this file is roughly based on the 7-1 architecture:
// https://sass-guidelin.es/#architecture
// any group is sorted alphabetically, please keep it that way!

// base
@import "base/_variables";
@import "base/_general";
@import "base/_temporary-fix";

@import "mixins/_dept-block";

// layout
@import "layout/_espots";
@import "layout/_espots-home";
@import "layout/_utility";
@import "layout/_espots-dept";

// components
@import "components/_accordion-arrow";
@import "components/_alert-message";
@import "components/_button";
@import "components/_dept-collection";
@import "components/_dept-cross-sale";
@import "components/_dept-inspiration";
@import "components/_dept-install-diy";
@import "components/_dept-top-brands";
@import "components/_header";
@import "components/_legal-cart";
@import "components/_flashsale";
@import "components/_flashsale-stores";
@import "components/_popup-aquisition";
@import "components/_promo";
//@import "components/_rebate-tag";
@import "components/_shopping-cart-legal";
@import "components/_tablelayout";
@import "components/_participating-stores";
@import "components/_multipleAddToCart";
@import "components/_sticky-menu";
@import "components/_slick-slider";
@import "components/_workshop-project";
@import "components/_productsModal";
@import "components/_overlayIconButton";
@import "components/_productsTile";

@import "common/_common";

// templates
@import "templates/_page-departments";

// Sanity fix for search bar in mobile
//@media(max-width: 767px) {
//  header.sanity-header nav.navbar .mobile-search-bar {
//
//    display:block !important;
//    opacity: 1;
//    transition: ease-in-out 0.2s;
//  }
//}
//
//@media(max-width: 767px) {
//  header.sanity-header  nav.navbar.newHeaderSticky #mobile-search-bar.mobile-search-bar {
//    display:none !important;
//    opacity: 0;
//    transition: ease-in-out 0.2s;
//  }
//}
.Suggestions .cio-item-SearchSuggestions {
  padding: 0 !important;
}
.Suggestions .cio-suggestion-text {
  padding: 8px 0;
}

//.Suggestions .cio-term-in-group {
//  display: none !important;
//}
.cio-results {
  display: none;
}
.search-bar-active .cio-results {
  display: block;
}
@media (max-width: 991px) {
  .sidebar__filters {
    z-index: 1000 !important;
  }
}
// Sanity fix currentstore dans storeDetail

#storeDetails.currentStore {
  width: auto;
  height: auto;
  font-style: inherit;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  font-family: Roboto, sans-serif;
}

// Sanity fix for constructor
.cio-search-bar {
  @media (max-width: 767px) {
    padding: 5px 10px;
  }
}
.cio-search-bar > div {
  @media (max-width: 767px) {
    max-width: none;
  }
}
.cio-search-bar > div.search-bar-active {
  position: relative;
  z-index: 100;
  border-radius: 8px !important;
  @media (max-width: 767px) {
    padding: 10px;
  }
}

// Sanity fix for filter when you add to cart
/*#productAddedToCartModal.modal-container {
  z-index: 1052;
}*/
.filter-container {
  position: sticky;
}
// Sanity fix mobile header

@media (max-width: 767px) {
  .cio-search-bar>div.search-bar-active {
    padding: 16px !important;
    border-radius: 0!important;
    background: White;
    bottom: 0;
  }

  .cio-search-bar>div.search-bar-active .cio-results {
    margin-left:-16px;
    border-radius: 0;
    margin-right: 0;

    box-shadow: none;
    max-height: 80%;
  }

}
