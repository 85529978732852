body#productPage,
body#shoppingCartPage {
	.alert-message {
		display: none !important;
	}
}

.alert-message {
	font-size: 14px;
	position: fixed;
	top: 100%;
	left: 0;
	transform: translateY(0);
	width: 100%;
	z-index: 1000;
	transition: transform 300ms ease;
	&.active {
		transform: translateY(-100%);
		.alert-message__button svg {
			transform: rotate(0deg);
		}
	}

	&__button {
		background: white;
		border: 1px solid #dddddd;
		color: black;
		padding: 10px;
		position: absolute;
		top: 1px;
		left: 0;
		transform: translateY(-100%);
		&:hover {
			background: white;
			color: black;
		}
		img,
		svg:not(.icon-arrow-down) {
			display: inline-block;
			margin-right: 5px;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			fill: red;
		}
		span {
			display: inline-block;
			vertical-align: middle;
		}
		svg.icon-arrow-down {
			display: inline-block;
			margin-left: 7px;
			vertical-align: middle;
			transform: rotate(180deg);
		}
	}

	&__content {
		background: white;
		color: black;
		border-top: 1px solid #dddddd;
		padding: 15px;
	}
}
