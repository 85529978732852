.section {
  &--gray {
    background-color: $gray;
    .section-header .section-header__container {
      h1,
      h2,
      h3 {
        background-color: transparent;
      }
    }
  }
  &--main {
    background-color: $main-color;
    .section-header .section-header__container {
      h1,
      h2,
      h3 {
        background-color: transparent;
      }
    }
  }
  &--secondary {
    background-color: $secondary-color;
    .section-header .section-header__container {
      h1,
      h2,
      h3 {
        background-color: transparent;
      }
    }
  }

  &--install-blue-opacity-90 {
    background-color: rgba($install-color-blue-bg, 0.9);
  }

  &--full-width-float{
    &.section--margin-30top10bottom {
      margin: 5em 1em 0em;
    }
    //Fix for reno
    z-index: 1;
  }
  &--full-width-float-background {
    position: absolute;
    width: 100vw;
    min-height: calc(100% + 30px);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: -1;
    max-width: 100vw;
  }
  &--margin-40top10bottom {
    margin: 4em 1em 0em;
  }
}

.common-section {
  position: relative;
}
