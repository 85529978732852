.listeMagasins {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 33%;
    padding: 5px 15px;
  }
}

[name="romo_mag_participants"] {
  margin-top: 80px;
}
