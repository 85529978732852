picture {
  width: 100%;
  display: flex;
  align-items: flex-start;
  img {
    width: 100%;
  }
}

.picturelang--object-fit-cover {
  img {
    height: 100%;
    object-fit: cover;
  }
}
