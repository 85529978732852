.product-tile{
	&__badge {
		position: absolute;
		top: 16px;
		left: 16px;
		padding: 4px 12px;
		border-radius: 500px;
		font-size:14px;
	}
	.product-tile__eco {
		background: #8DC63F;
		color: #fff;
	}
	.product-tile__great-buy {
		background: #2437FE;
		color:  #F2FF61;
	}
	.product-tile__buy-in-bulk {
		background: #00315D;
		color:  #fff;
	}
	.product-tile__pro-pick {
		background: #00315D;
		color:  #F2FF61;
	}
	.product-tile__clearance {
		background: #DD1728;
		color:  #F2FF61;
	}

}
