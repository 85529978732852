.widget__storeInfo-trigger {
	* {
		vertical-align: middle;
	}
	&:before {
		background: url(//www.rona.ca/documents/ronaResponsive/Espots/MarketingSegmentsAndScripts/ABtestPin/pin-map.png)
			center center no-repeat;
		background-size: auto 16px;
		content: "";
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		margin-left: 5px;
		height: 16px;
		width: 13px;
		@media screen and (max-width: 767px) {
			background: url(//www.rona.ca/documents/ronaResponsive/Espots/MarketingSegmentsAndScripts/ABtestPin/pin-map--white.png)
				center center no-repeat;
			background-size: auto 16px;
			margin-left: 0;
		}
	}
}
.header__top-store .widget__storeInfo-trigger span {
	max-width: calc(100% - 40px);
}
