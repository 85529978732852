.legal-cart-links {
  background: #f5f5f5;
  padding: 25px;
  color: black;
  font-size: 20px;
  text-align: center;
  &__title {
    margin-bottom: 15px;
  }
  a {
    color: #666;
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.4;
    text-transform: uppercase;
    &:hover {
      color: #4071ac;
    }
    &:not(:last-of-type) {
      &:after {
        color: #666;
        content: "|";
        font-weight: normal;
        margin: 0 8px;
      }
    }
  }
}