@media screen and (min-width: $screen-sm-min) {
  #departmentPage .espot--50,   #categoryPage .full-espot--50 {
      position: relative;
      margin-left:-15px;
      margin-right:-15px;
      .promo {
          width: 50%;
          float: left;
          position: relative;
          min-height: 1px;
          padding-left: 15px;
          padding-right: 15px;
          &__button {
            //  margin-left:15px;
              margin-right:15px;
          }
          &__inline-legal {
            left: 25px;
          }

      }

      .legal__container {
          margin: 0 15px;
          width : calc(100% - 30px);
      }
      .rebateTag {
          left: auto;
      }
  }
}

.categorytag{
    min-width: 120px !important;
}



.PCXX_full, .PCXX_full .container {
    position: relative;
    background: #00315C;
}
#homePage .PCXX_full{
    display: none !important;
}
.PCXX_full .detail {
    font-size: 11px;
    text-decoration: underline;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #fff
}

/* couleur principale */
.PCXX_full .mobileversion {
    color: #fff;
    font-size: 1em;
    text-align: center;
}
.PCXX_full .mobileversion p{
    margin: 5px 40px;
      line-height: 1em;
}
.PCXX_full .main {
    font-size: 1.5em;

}
/*couelur secondaire*/
.PCXX_full .sec-color {
         color: #FFDD00; /*yellow*/

}
.PCXX_full .cap {
    text-transform: uppercase;
}
