.common-categoryselector {
  display: flex;
  align-content: center;
  align-items: center;
  background: $light-cxp-grey;
  flex-direction: row;
  flex-flow: row;
  justify-content: space-between;
  padding: 20px 10px;
  min-width: 1px;
  text-decoration: none;
  flex-wrap: wrap;
  @media screen and (max-width: 991px) {
    padding: 10px 5px;
  }
  & > * {
    flex: 1 1 0;
    align-self: stretch;
    @media screen and (max-width: 991px) {
      flex: 1 1 auto;
      margin: 5px !important;
      width: calc(50% - 10px);
      align-items: center !important;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.4em;
    line-height: 26px;
    text-transform: initial;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 30px;
    }
  }
  &.common-categoryselector--4 .common-header__title {
    @media screen and (min-width: 992px) {
      min-width: 300px;
    }
  }
  .common-header__title {
    align-self: center;
    @media screen and (min-width: 992px) {
      min-width: 200px;
    }
    @media screen and (max-width: 991px) {
      flex: 100%;
    }
  }
  a.common-card {
    border: 4px solid #ffffff;
    display: flex;
    align-items: start;
    padding: 6px;
    margin: 0 10px;
    justify-content: flex-start;
    @media screen and (max-width: 991px) {
      border: none;
      flex-flow: row;
      justify-content: left;
    }

    .common-card__text {
      margin: 0px;

      span {
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        font-weight: bold;
        @media screen and (max-width: 991px) {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    &:hover {
      border: 4px solid $secondary-color !important;
      box-shadow: none;
      @media screen and (max-width: 991px) {
        border: none !important;
      }
    }
    .common-card__image {
      max-width: 150px;
      margin: 0 auto 10px auto;
      @media screen and (max-width: 991px) {
        max-width: 60px;
        margin: 0 5px 0 0;
      }
      @media screen and (max-width: 350px) {
        max-width: 40px;
        margin: 0;
      }
    }
  }
  /** Added custom class **/
  &--margin-bottom {
    margin-bottom: 40px;
  }
}
