.show-homePage,
.show-departmentPage,
.show-categoryPage,
.show-productPage,
.show-searchPage {
  display: none;
}

#homePage .show-homePage,
#departmentPage .show-departmentPage,
#categoryPage .show-categoryPage,
#productPage .show-productPage,
#searchPage .show-searchPage {
  display: block;
}

.u-lowercase {
  text-transform: lowercase;
}

ul.u-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0;
  }
}

.u-optimal-line-length {
  max-width: 45em;
  margin: 0 auto;
}
