.container {
  .section-header {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: start;
    margin-top: 2em;
    margin-bottom: 2em;

    h1 {
      font-size: 3.3em;
      font-weight: bold;
      font-family: $font-bold;
    }
    h2 {
      font-size: 2.2em;
    }

    h3 {
      font-size: 1.6em;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline-block;
      //padding-left: 0.5em;
      //padding-right: 0.5em;
      background: #ffffff;
      z-index: 1;
      margin: 0;
      color: #000000;
      text-transform: none;
    }

    p {
      font-size: 1.8em;
      margin: 10px 0;
    }

    &__container {
      display: flex;
      justify-content: start;
      position: relative;
      width: 100%;
      flex-flow: column;
    }

    &__icon {
      padding-bottom: 1em;
    }
  }

  .section-header--center {
    align-items: center;
    display: flex;
    .section-header__container {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: flex;
        margin: auto;
        max-width: 100%;
      }
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-flow: column;
      text-align: center;

      &:after {
        top: 50%;
      }
    }
  }

  .section-header--stroke {
    .section-header__container {
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        height: 1px;
        width: 100%;
        border-top: #dddddd solid 2px;
      }
    }
  }

  .section-header--main-color {
    fill: $main-color;
    border-color: $main-color;
  }
  .section-header--short-content {
    .section-header__container {
      max-width: 1000px;
    }
  }
  .section-header--margin2em {
    margin-top: 2em;
  }
  .section-header--black {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #000000;
    }
    color: #000000;
    fill: #000000;
    border-color: #000000;
  }
  .section-header--big-title {
    h1 {
      font-size: 4.5em;
      @media screen and (max-width: 767px) {
        font-size: 4em;
      }
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 4.5em;
    }
    p {
      color: black;
      font-size: 1.8em;
      font-weight: bold;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    margin: 5em 0;
  }

  .section-header--icon-left {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-content: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-left: 0;
    }

    p {
      margin-left: 0;
    }

    .section-header__icon {
      flex: 0 0 auto;
      width: 20%;
    }
    picture {
      flex: 0 0 auto;
      width: 20%;
      padding: 2em;
      display: flex;
      justify-content: center;
      align-content: center;
      max-width: 150px;
      .section-header__icon {
        width: 100px;
      }
    }
    .section-header__container {
      flex: 0 1 auto;
      width: auto;
      flex-flow: column;
      padding-left: 1em;
      &:after {
        content: none;
      }
    }
  }
}
