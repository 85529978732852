.tablelayout {
  background: $lighter-gray;
  padding: 10px;
  margin-top: 30px;

  &__title {
    padding-left: 20px;
  }

  &__list {
    background: $white;
    padding: 30px 20px 0;
    margin: 0;
  }
}
