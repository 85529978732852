@mixin dept-block {
	$block: &;
	margin-top: 35px;
	color: $black;
	transition: color ease-in-out 0.2s;
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%; // necessary for IE to behave.

	&:hover {
		#{$block}__title {
			color: $light-blue;
		}

		#{$block}__extra p:after {
			transform: translate(4px) rotate(-90deg);
		}

		#{$block}__image {
			transform: scale(1.05) translate3d(0, 0, 0);
		}
	}

	&__image-wrapper {
		position: relative;
		margin-top: auto;
		overflow: hidden;
		border: 2px solid #ededed;
	}

	&__image {
		width: 100%;
		display: block;
		transform: scale(1) translate3d(0, 0, 0);
		transition: transform ease-in-out 0.4s;
	}

	&__cta {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}

	&__title {
		font-size: 22px;
		font-weight: 400;
		margin: 0;
		color: #000;
		transition: color ease-in-out 0.2s;
		text-transform: none;
	}

	&__extra {
		font-size: 16px;
		max-width: 90%;
		color: $black;
		transition: color ease-in-out 0.2s;
		display: flex;
		align-items: flex-end;

		@media screen and (max-width: $screen-xs-max) {
			max-width: none;
		}

		p {
			display: inline;
			margin-top: 0;
			font-size: 16px;
			padding-right: 20px;

			&:after {
				content: url("https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg");
				width: 10px;
				height: 10px;
				display: inline-block;
				vertical-align: middle;
				transition: transform 150ms ease;
				transform: translate(0) rotate(-90deg);
				margin-right: -10px;
			}
		}
	}
}

.dept .col--flex {
	justify-content: space-between;
	flex-direction: row;
}
