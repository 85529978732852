.slick-slider[data-theme="minimalist"] {
	.product-tile {
		.product-tile__addToWL {
			opacity: 0;
			transition: all 250ms ease;
			transform: translateY(10px);
			@media all and(max-width: 768px) {
				opacity: 1;
				transform: translateY(0px);
			}
		}
		&:hover {
			.product-tile__addToWL {
				opacity: 1;
				transform: translateY(0px);
			}
		}
	}
	.price-box {
		height: 55px;
		min-height: 55px;

		.price-box__promoTag + .price-box__price {
			margin-top: 7px;
		}
		.price-box__price__amount {
			font-size: 18px;
		}
		.price-box__price__amount__decimal {
			font-size: 14px;
			left: -2px;
			width: auto;
		}
		.price-box__price__amount__integer {
			font-size: 1.2em;
		}
		.price-box__price__amount__currency {
			position: static;
			font-size: 1em;
			width: auto;
		}
		.price-box__price__amount__currency + .price-box__price__amount__integer {
			margin-left: -4px;
		}
		.price-box__price__spec {
			margin-left: 0;
		}
		.price-box__rebate {
			display: none;
		}
	}
}
