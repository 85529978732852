.banner {
  display: block;
  line-height: 1.4;
  position: relative;
  text-decoration: none;

  &__top-text {
    background: $main-color;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 20px 15px;
    text-align: center;
  }

  > .button {
    position: absolute;
    bottom: 15px;
    right: 15px;

    &--centered {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }

  &--tile {
    img {
      display: block;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      .button {
        display: none;

        &.button-mobile {
          display: block !important;
        }
      }
    }
  }

  &--button-right {
    @media screen and (min-width: 768px) {
      display: flex;
      .buttons-container {
        flex: 0 0 auto;
        flex-flow: column;
        margin: 0 0 0 4px;
        padding: 0;
        width: 33.33333%;

        .button {
          width: 100%;
          margin: 0 0 4px 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  .button {
    background: $main-color;
    color: #fff;
    font-family: $font-normal;
    font-weight: bold;
    padding: 11px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 300ms ease;

    @media screen and (max-width: 767px) {
      font-size: 13px;
      padding: 10px 15px;
    }

    &:hover {
      color: $main-color;
      background: $main-color-light;
    }

    &--white {
      background: white;
      color: $main-color;

      &:hover {
        opacity: 0.9;
        background: white;
      }
    }

    &--lightblue {
      background-color: $lightblue;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: $lightblue;
      }
    }

    &--white-border-grey {
      border: 4px solid #e7eded;
      background: white;
      color: $main-color;

      &:hover {
        background: $main-color-light;
        color: $main-color;
      }
    }

    &--white-border-black {
      border: 4px solid black;
      background: white;
      color: black;

      &:hover {
        background: black;
        border: 4px solid $red;
        color: white;
      }
    }

    &--tile6 {
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
      bottom: auto !important;
      text-align: center;
      width: 100%;
      text-transform: none;
      color: #000 !important;
      font-size: 12px;
      font-weight: normal;
      background: transparent !important;
      display: block !important;
    }
  }
}

.banner-info {
  left: 0;
  max-width: 200px;
  position: absolute;
  top: 0;
  z-index: 1;

  &__text {
    background: white;
    color: black;
    font-family: $font-normal;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    padding: 15px;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size: 13px;
      padding: 10px;
    }
  }
}

.banner-container {
  margin-bottom: 30px;
  position: relative;

  .button {
    &.hidden {
      display: none;
    }

    &--coupon {
      bottom: 60px;
      border: 2px dotted #ffffff;
      background: transparent;
      @media screen and (min-width: 768px) {
        position: absolute;
        width: 28%;
        right: 15px;
        bottom: 60px;
        color: #ffffff;
      }
      @media (min-width: 768px) AND (max-width: 1199px) {
        bottom: 42px;
        color: #ffffff;
        width: 28%;
        font-size: 12px;
        padding: 7px 12px;
      }
    }

    &--coupon:hover {
      background: #ffffff;
      color: $main-color;
    }

    &--shop-all {
      background: #ffffff;
      color: $main-color;
      @media screen and (max-width: 767px) {
        background: #fff;
        color: $main-color;
        margin-top: 10px;
      }
      @media screen and (min-width: 768px) {
        position: absolute;
        width: 28%;
        bottom: 15px;
        right: 15px;
        background: #ffffff;
        color: $main-color;
      }
      @media (min-width: 768px) AND (max-width: 1199px) {
        bottom: 10px;
        font-size: 12px;
        padding: 7px 12px;
        background: #ffffff;
        color: $main-color;
        width: 28%;
      }
    }

    &--shop-all:hover {
      background: $main-color;
      color: #ffffff;
    }
  }
}

.banner-legal {
  color: black;
  display: block;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0;
  margin-left: auto;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;

  &-text-rebate {
    position: absolute;
    bottom: 0px;
    left: 5px;
    margin-right: 140px;
    @media screen and (max-width: 767px) {
      bottom: 0px;
    }
  }
}

.banner-legal-container {
  display: flex;
}

.banner-legal-text {
  font-size: 10px;
  padding: 5px 0;
}

.banner--tile6 {
  &:hover {
    box-shadow: 0 0 10px #000000;
    transition: all 250ms ease;
  }
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
  padding-top: 2px;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
  }

  .button {
    flex: 1 0 auto;
    width: calc(25% - 4px);
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 2px 0 0;
    }
  }
}

.button-container {
  &--centered {
    text-align: center;
  }
}
