.espot-mainHeaderBottom--sticky {
	overflow: visible;
}

.main__container--withSticky {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0);
		z-index: 9;
		transition: background 300ms ease;
		pointer-events: none;
	}
	&.opened:before {
		background: rgba(0, 0, 0, 0.5);
		pointer-events: auto;
	}
}

.sticky-menu {
	position: relative;
	z-index: 0;
}

.sticky-menu__heading {
	background: #00315d;
	display: block;
	text-align: center;
	padding: 10px 0;
	color: white;
	font-size: 0;
	position: relative;
	z-index: 2;
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		@media all and (max-width: 1199px) {
			padding: 0;
			width: 100%;
		}
	}
	&:hover {
		color: white;
		background: #02427b;
	}
	&.opened {
		.sticky-menu__arrow {
			transform: rotate(-90deg);
		}
	}
}

.sticky-menu__title {
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
}

.sticky-menu__arrow {
	height: 18px;
	width: 18px;
	margin-left: 6px;
	transition: all 200ms ease;
	transform: rotate(0deg);
}

.sticky-menu__close {
	position: absolute;
	right: 15px;
	padding: 0px;
	top: 50%;
	transform: translateY(-6px);
	background: none;
	color: white;
	border: none;
	font-size: 0;
	svg {
		height: 12px;
		width: 12px;
	}
}

.sticky-menu__dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding: 10px 0px;
	color: black;
	font-size: 14px;
	background: white;
	opacity: 0;
	transform: translateY(-20px);
	transition: all 400ms ease;
	pointer-events: none;
	z-index: 1;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	display: none;
	&.opened {
		opacity: 1;
		transform: translateY(0px);
		pointer-events: auto;
	}
	&.with-dots {
		padding-bottom: 50px;
	}
	.container {
		@media all and (max-width: 1199px) {
			width: 100%;
		}
	}
	.slick-dots {
		position: absolute;
		list-style: none;
		padding-left: 0;
		bottom: -35px;
		left: 50%;
		transform: translateX(-50%);
		li {
			display: inline-block;
			&.slick-active button {
				background: #00315d;
			}
		}
		button {
			display: inline-block;
			background: transparent;
			border: 1px solid #666;
			border-radius: 50%;
			outline: 0;
			height: 19px;
			position: relative;
			width: 19px;
			margin-bottom: 7px;
			margin-right: 10px;
			font-size: 0;
			transition: background 300ms ease;
			cursor: pointer;
		}
	}
}

.sticky-menu__tiles {
	width: calc(100% + 20px);
	transition: all 400ms ease;
	margin-left: -10px;
	margin-right: -10px;
	.slick-track {
		margin: auto;
		min-width: 0;
		// @media all and (max-width: 768px) {
		//   width: 100% !important;
		// }
	}
}

.sticky-menu__tile {
	border: 1px solid #e0dfdf;
	margin: 10px;
	&__mention {
		color: white;
		position: absolute;
		bottom: 10px;
		left: 10px;
		font-size: 12px;
		text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
	}
	&__media {
		font-size: 0;
		position: relative;
		display: block;
		img {
			width: 100%;
			display: block;
		}
	}
	&__media--text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 25px;
		font-size: 16px;
	}
	&__title {
		&--level-1 {
			font-size: 24px;
			font-weight: 700;
			text-transform: uppercase;
		}
		&--level-2 {
			font-size: 35px;
			line-height: 1.2em;
			font-weight: 700;
			text-transform: uppercase;
		}
		&--level-3 {
			font-size: 20px;
		}
	}
	&__details {
		padding: 8px;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.sticky-menu__tile__legal-link {
	color: #888;
	text-decoration: underline;
	font-size: 12px;
}
