@charset "UTF-8";
/* Bootstrap */
/* BEGIN box-shadow */
/* END box-shadow */
/* BEGIN Colors */
/* END Colors */
/* BEGIN Fonts */
/* END Fonts*/
/* COMMON VARIABLES */
/* END COMMON VARIABLES */
body:not(.en) .show-EN,
body:not(.fr) .show-FR {
  display: none !important; }

body:not(.BIG_BOX) .show-BB,
body:not(.PROXIMITY) .show-PX {
  display: none !important; }

.BC-AB-SK-MB,
body.QC .ROC,
body.QC .show-ROC-only {
  display: none !important; }

body:not(.QC) .show-QC-only {
  display: none !important; }

body.AB .BC-AB-SK-MB,
body.BC .BC-AB-SK-MB,
body.MB .BC-AB-SK-MB,
body.SK .BC-AB-SK-MB {
  display: block !important; }

.show-BC,
.show-AB,
.show-SK,
.show-MB,
.show-ON,
.show-QC,
.show-NL,
.show-NT,
.show-NS,
.show-PE,
.show-YT,
.show-NB {
  display: none; }

body.BC .show-BC,
body.AB .show-AB,
body.SK .show-SK,
body.MB .show-MB,
body.ON .show-ON,
body.QC .show-QC,
body.NL .show-NL,
body.NT .show-NT,
body.NS .show-NS,
body.PE .show-PE,
body.YT .show-YT,
body.NB .show-NB {
  display: block; }

.show-BC-inline-block,
.show-AB-inline-block,
.show-SK-inline-block,
.show-MB-inline-block,
.show-ON-inline-block,
.show-QC-inline-block,
.show-NL-inline-block,
.show-NT-inline-block,
.show-NS-inline-block,
.show-PE-inline-block,
.show-YT-inline-block,
.show-NB-inline-block {
  display: none; }

body.BC .show-BC-inline-block,
body.AB .show-AB-inline-block,
body.SK .show-SK-inline-block,
body.MB .show-MB-inline-block,
body.ON .show-ON-inline-block,
body.QC .show-QC-inline-block,
body.NL .show-NL-inline-block,
body.NT .show-NT-inline-block,
body.NS .show-NS-inline-block,
body.PE .show-PE-inline-block,
body.YT .show-YT-inline-block,
body.NB .show-NB-inline-block {
  display: inline-block; }

#Enews__banner img {
  width: 100%; }

body:not(.parcel) .show-parcel {
  display: none; }

body:not(.corporate) .show-corporate {
  display: none; }

body.corporate .show-affiliate {
  display: none; }

[data-element-start-date]:not(.js-date-confirmed),
[data-element-end-date]:not(.js-date-confirmed) {
  display: none !important; }

@media (max-width: 767px) {
  .footer__top .container .row:first-of-type .col-md-6.col-sm-6:not(.col-xs-12) {
    clear: both; } }

@media screen and (max-width: 450px) {
  .rebateTag .rebateTag__description {
    font-size: 1em; } }

.price-box {
  height: 75px; }
  .price-box--product-page {
    height: initial; }

sup {
  top: -0.5em; }

#personalized-offer {
  margin: 0 0 20px !important; }

#homePage #box.layoutBuilder {
  opacity: 0;
  transition: opacity 300ms ease; }
  #homePage #box.layoutBuilder.active {
    opacity: 1; }

.element-secure-transactions {
  display: none !important; }

.submenu {
  height: 100%; }

#productPage.store-62800 .page-product__store-inventory {
  display: none; }

#productPage.store-62850 .page-product__cart__info,
#productPage.store-62800 .page-product__cart__info {
  display: none; }

.select2-container {
  z-index: 49; }

@media screen and (max-width: 990px) {
  .select2-container--open {
    z-index: 51; } }

.zoid-component-frame {
  z-index: 0 !important; }

@media screen and (max-width: 991px) {
  #js-productFilter {
    min-height: 600px; } }

.category-description--active:after {
  opacity: 0;
  height: 40px; }

.ie9 .product-tile__image-link,
.ie10 .product-tile__image-link,
.ie11 .product-tile__image-link {
  overflow: hidden; }

.c_1073874844 .promo {
  box-shadow: 0px 0px 10px #00000000;
  transition: all 250ms ease; }

.c_1073874844 .promo:hover {
  box-shadow: 0px 0px 10px #00000059; }

.c_1073874844 .promo__button {
  min-height: 60px; }

.menu-mob__item__title {
  cursor: pointer; }

.generic-product-slider--with-nav {
  margin-top: 45px; }

.bv-fieldset-rating a.bv-rating-link span {
  pointer-events: none; }

.header__bottom__dropdown {
  top: 100%;
  border-top: 1px solid #dedede; }

.search-autocomplete {
  z-index: 251; }

.espot-mainHeaderBottom section {
  padding: 0; }

.dept .col--flex {
  justify-content: space-between;
  flex-direction: row; }

.espot-categoryPageTopPromoBox,
.espot-productPageTopPromoBox {
  display: block; }

.espot-mainHeaderBottom {
  overflow: hidden; }
  .espot-mainHeaderBottom--closed {
    display: none !important; }

.layoutBuilder {
  padding-bottom: 30px;
  width: auto !important; }

.espot-debug-style {
  display: inline-block;
  padding: 5px;
  max-width: 100%;
  background: #e7e7e7;
  font-size: 12px; }

.espot-container:after,
.espot-container:before {
  content: " ";
  display: table; }

.espot-container:after {
  clear: both; }

.espot-product-tile {
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ddd;
  display: block;
  margin: -1px 0 0 -1px;
  position: relative; }
  .espot-product-tile .promo__button {
    white-space: nowrap;
    right: 50%;
    transform: translateX(50%); }

.espot-calculator {
  display: none; }
  .espot-calculator.active {
    display: block; }
  .espot-calculator .button {
    margin-top: 15px; }

/*
	Espot ID:
	Jsp file:
	Url:
	Width:
	Location:

	Old Width:
	Old Location:
*/
/*
	Espot ID: DepartmentPullDown-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Top of every department pages, Under the Title

	Old Width: 749px
	Old Location: Top Right of every department pages
*/
.espot-DepartmentPullDown {
  display: none; }

/*
	Espot ID: DepartmentTopContent-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Top of every department pages, Under DepartmentPullDown

	Old Width: 749px
	Old Location: Top Right of every department pages, Under espot-DepartmentPullDown
*/
.espot-DepartmentTopContent {
  display: none; }

/*
	Espot ID: DepartmentSidebar-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Top of every department pages, Under DepartmentPullDown

	Old Width: 224px
	Old Location: Top Right of every department pages, Under espot-DepartmentPullDown
*/
.espot-DepartmentSidebar {
  display: none; }

/*
	Espot ID: DepartmentMidContent-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Under the department listing in every department pages

	Old Width: 749px
	Old Location: Under the department listing in every department pages (right)
*/
.espot-DepartmentMidContent {
  display: block; }

/*
	Espot ID: ScrollingTiles-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Under the department listing in every department pages, Under espot-DepartmentMidContent

	Old Width: 749px
	Old Location: Under the department listing in every department pages, Under espot-DepartmentMidContent (right)
*/
/*
	Espot ID: DepartmentBottomContent-[Department ID]
	Jsp file: RonaDepartmentDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top=Y&urlLangId=-1&categoryId=16509&langId=-1&storeId=10151&productCategory=1000038878
	Width: 100%
	Location: Under the department listing in every department pages, Under espot-ScrollingTiles

	Old Width: 749px
	Old Location: Under the department listing in every department pages, Under espot-ScrollingTiles (right)
*/
/*
	Espot ID: Category page top promo box
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100%
	Location: Top of every category page

	Old Width: 100%/1000px
	Old Location: Top of every category page
*/
.espot-categoryPageTopPromoBox {
  display: block; }

/*
	Espot ID: Product Category Sidebar
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100% of sidebar / smallest width possible : 212px
	Location: Under the filters in every category page

	Old Width: 224px
	Old Location: Under the filters in every category page
*/
.espot-productCategorySidebar {
  display: none; }

/*
	Espot ID: Category page footer promo box left
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100%
	Location: Under the product listing and paging

	Old Width: 100%
	Old Location: Under the product listing and paging
*/
.espot-categoryPageFooterPromoBoxLeft {
  display: none; }

/*
	Espot ID: Category page footer promo box right
	Jsp file: RonaCategoryDisplay.jsp
	Url: /webapp/wcs/stores/servlet/CategoryDisplay?catalogId=10051&top_category=16511&urlLangId=-1&categoryId=16639&langId=-1&storeId=10151&productCategory=1000038915
	Width: 100%
	Location: Under the product listing and paging, Under espot-categoryPageFooterPromoBoxLeft

	Old Width: 100%
	Old Location: Under the product listing and paging, Under espot-categoryPageFooterPromoBoxLeft
*/
.espot-categoryPageFooterPromoBoxRight {
  display: none; }

/*
	Espot ID: Shopping cart promo box A
	Jsp file: RonaShopcartEspots.jsp ( included in : RonaOrderBillingDetails.jsp, RonaCheckoutPayment.jsp, RonaOrderItemDisplay.jsp )
	Url: /webapp/wcs/stores/servlet/AjaxOrderItemDisplayView?langId=-1&catalogId=10051&storeId=10151&krypto=pWhATButUOlqH5kcfZBXl%2BmYK6ItUCV33XpCMppoM0fvO9aLQeGGXL%2BV4lAdPWBzKRQmTtF9ue5N%0D%0AuJrPZOt1sckpYeUCcUgBK%2F%2FGObD1Jb0hx487NbRDeKjBma47V8GQdFbCAPeacT6X3lrHw2PrPH%2Fx%0D%0AVdO0tfnVYnEUWNIZfQBMkdhy7d192SfGEGmqwCF8gE7zgE5t%2Bx80XEJe7638h53D1nCu4lZXXr0S%0D%0Avv72Yp1ivilv%2B57IqBQu4u3Gd7ZAl5ZIhmE48AKaiJKVfkqagN%2Fl5Tcnd1CZzPaS40ZLf%2Fgjz7uN%0D%0AqcRediN8%2B2JX4pKNUwB1vHCK2kC4Hy6efrPGLHvgYxH6J2L620vsZ9qlV%2Fv6GQ1U8XZcWQ%3D%3D&ddkey=https:OrderCalculate
	Width: 100%
	Location: Under the cart, just above the disclaimers

	Old Width: 100%
	Old Location: Under the cart, just above the disclaimers
*/
.espot-shoppingCartPromoBoxA {
  display: block; }

/*
	Espot ID: PromotionsDropDown
	Jsp file: RonaCachedHeaderNavPromo.jspf
	Url: All
	Width: 100% of the submenu
	Location: Under DEALS & FLYER in the header main menu

	Old Width: 100% of the submenu
	Old Location: Under Promotions
*/
/*
	Espot ID: ProductMenuCategory-[Department ID]
	Jsp file: RonaCachedHeaderNavProductsOverlay.jsp
	Url: All
	Width: 33.333333% of the department submenu
	Location: right side In every department submenu under the see all button (desktop Only)

	Old Width: 209px
	Old Location: At the right of every department submenu
*/
/*
	Espot ID: Installation-service-dropdown-right
	Jsp file: RonaCachedHeaderNabInstall.jspf
	Url: All
	Width: 66.666666% of the install menu
	Location: right side of the installation services submenu

	Old Width: N/A
	Old Location: N/A
*/
/*
	Espot ID: Product page bottom promo box
	Jsp file: RonaProductDetailsDisplay.jsp
	Url: /webapp/wcs/stores/servlet/Product1_10151_10051_315015_-1
	Width: 100%
	Location: Bottom of product page under the disclaimers

	Old Width: 100%
	Old Location: Bottom of product page under the disclaimers
*/
.espot-productPageBottomPromoBox {
  display: none; }

/*
	Espot ID: Product page top promo box
	Jsp file: RonaProductDetailsDisplay.jsp
	Url: /webapp/wcs/stores/servlet/Product1_10151_10051_315015_-1
	Width: 100%
	Location: Top of product page under the breadcrumb

	Old Width: 100%
	Old Location: Top of product page under the breadcrumb
*/
.espot-productPageTopPromoBox {
  display: block; }

/*
	Espot ID: HeaderTopLinks
	Jsp file: RonaCachedHeaderDisplay.jsp
	Url: All pages
	Width: 100%
	Location: Top of the page over the main header
*/
/*
	Espot ID: Main Header Bottom
	Jsp file: RonaCachedHeaderDisplay.jsp
	Url: All pages
	Width: 100%
	Location: Under header__bottom

	Old Espot ID: Main Header Right
	Old Width: 600px
	Old Location: At the Right of the logo in the header
*/
#homePage .espot-mainHeaderBottom {
  display: none; }

/*
	Espot ID: CartItem{SKU}
	Jsp file: RonaOrderItemDetail.jsp
	Url: /webapp/wcs/stores/servlet/AjaxOrderItemDisplayView?langId=-2&catalogId=10051&storeId=10151
	Width: 100%
	Location: Under quantity and price fields
*/
.espot-cartItem {
  display: none; }

/*
	Espot ID: MiniCart
	Jsp file: RonaAddedOrderItemDisplay.jsp
	Url: None
	Width: 100%
	Location:  When adding a product to cart, in the modal on the left of the subtotal
*/
/*
	Espot ID: OrderConfirmation
	Jsp file: RonaCheckoutConfirmationView.jsp
	Url: None
	Width: 100%
	Location: Any order confirmation page
*/
.espot-orderConfirmation {
  display: none; }

/*
	Espot ID: allProductsPageTop
	Jsp file: RonaViewAllProductsFiltersDisplay.jsp
	Url: /webapp/wcs/stores/servlet/RonaViewAllProductsDisplayView?catalogId=10051&urlLangId=-2&langId=-2&storeId=10151
	Width: 100%
	Location: top of the all product page

	Old Espot ID: All products page top promo box right
	Old Width: 408px
	Old Location: At top right of the allproduct page
*/
/*
	Espot ID: allProductsPageFooter
	Jsp file: RonaViewAllProductsDisplay.jsp
	Url: /webapp/wcs/stores/servlet/RonaViewAllProductsDisplayView?catalogId=10051&urlLangId=-2&langId=-2&storeId=10151
	Width: 100%
	Location: bottom of the all product page

	Old Espot ID: All products page footer promo box left, All products page footer promo box right
	Old Width: half and half with 2 espots
	Old Location: At bottom right of the allproduct page
*/
/*
	Espot ID: FooterCatfishTop
	Jsp file: RonaCachedFooterDisplay.jsp
	Url: None
	Width: 100%
	Location: At the top of the footer
*/
.layoutBuilder div[class^="c_"] {
  width: auto !important;
  float: none !important; }

#homePage .layoutBuilder {
  margin-left: -10px !important;
  margin-right: -10px !important; }

#homePage [class^="c_"] {
  float: left !important; }

#homePage .layoutBuilder [class=c_1073874885],
#homePage .layoutBuilder [class=c_1073874884],
#homePage .layoutBuilder [class=c_1073874840],
#homePage .layoutBuilder [class=c_1073874879],
#homePage .layoutBuilder [class=c_1073874846],
#homePage .layoutBuilder [class=c_1073874856],
#homePage .layoutBuilder [class=c_1073897835],
#homePage .layoutBuilder [class=c_1073897836],
#homePage .layoutBuilder [class=c_1073897837],
#homePage .layoutBuilder [class=c_1073874864],
#homePage .layoutBuilder [class=c_1073874870],
#homePage .layoutBuilder [class=c_1073874841],
#homePage .layoutBuilder [class=c_1073874858],
#homePage .layoutBuilder [class=c_1073864847],
#homePage .layoutBuilder [class=c_1073874875],
#homePage .layoutBuilder [class=c_1073874836],
#homePage .layoutBuilder [class=c_1073874868],
#homePage .layoutBuilder [class=c_1073874851],
#homePage .layoutBuilder [class=c_1073874872],
#homePage .layoutBuilder [class=c_1073874852],
#homePage .layoutBuilder [class=c_1073874869],
#homePage .layoutBuilder [class=c_1073874880],
#homePage .layoutBuilder [class=c_1073874855],
#homePage .layoutBuilder [class=c_1073874861],
#homePage .layoutBuilder [class=c_1073874828],
#homePage .layoutBuilder [class=c_1073874876],
#homePage .layoutBuilder [class=c_1073874845],
#homePage .layoutBuilder [class=c_1073874853],
#homePage .layoutBuilder [class=c_1073874865],
#homePage .layoutBuilder [class=c_1073874831],
#homePage .layoutBuilder [class=c_1073874830],
#homePage .layoutBuilder [class=c_1073874832],
#homePage .layoutBuilder [class=c_1073874849],
#homePage .layoutBuilder [class=c_1073874842],
#homePage .layoutBuilder [class=c_1073874866],
#homePage .layoutBuilder [class=c_1073874834],
#homePage .layoutBuilder [class=c_1073874835],
#homePage .layoutBuilder [class=c_1073874881],
#homePage .layoutBuilder [class=c_1073874860],
#homePage .layoutBuilder [class=c_1073874882],
#homePage .layoutBuilder [class=c_1073874873],
#homePage .layoutBuilder [class=c_1073874847],
#homePage .layoutBuilder [class=c_1073874862],
#homePage .layoutBuilder [class=c_1073874859],
#homePage .layoutBuilder [class=c_1073874871],
#homePage .layoutBuilder [class=c_1073874867],
#homePage .layoutBuilder [class=c_1073874874],
#homePage .layoutBuilder [class=c_1073874829],
#homePage .layoutBuilder [class=c_1073874854],
#homePage .layoutBuilder [class=c_1073874844],
#homePage .layoutBuilder [class=c_1073874839],
#homePage .layoutBuilder [class=c_1073874857],
#homePage .layoutBuilder [class=c_1073874838],
#homePage .layoutBuilder [class=c_1073874877],
#homePage .layoutBuilder [class=c_1073874883],
#homePage .layoutBuilder [class=c_1073874878],
#homePage .layoutBuilder [class=c_1073864831],
#homePage .layoutBuilder [class=c_1073864836],
#homePage .layoutBuilder [class=c_1073864841],
#homePage .layoutBuilder [class=c_1073864843],
#homePage .layoutBuilder [class=c_1073864844],
#homePage .layoutBuilder [class=c_1073864858],
#homePage .layoutBuilder [class=c_1073893335],
#homePage .layoutBuilder [class=c_1073893333],
#homePage .layoutBuilder [class=c_1073893334],
#homePage .layoutBuilder [class=c_1073893336],
#homePage .layoutBuilder [class=c_1073893338],
#homePage .layoutBuilder [class=c_1073893337] {
  position: relative;
  float: left !important;
  width: 100% !important;
  min-height: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
  position: relative; }
  #homePage .layoutBuilder [class=c_1073874885] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874884] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874840] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874879] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874846] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874856] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073897835] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073897836] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073897837] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874864] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874870] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874841] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874858] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864847] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874875] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874836] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874868] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874851] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874872] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874852] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874869] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874880] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874855] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874861] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874828] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874876] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874845] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874853] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874865] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874831] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874830] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874832] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874849] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874842] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874866] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874834] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874835] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874881] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874860] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874882] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874873] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874847] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874862] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874859] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874871] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874867] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874874] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874829] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874854] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874844] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874839] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874857] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874838] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874877] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874883] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073874878] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864831] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864836] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864841] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864843] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864844] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073864858] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073893335] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073893333] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073893334] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073893336] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073893338] img[data-original][src*="data:image/"],
  #homePage .layoutBuilder [class=c_1073893337] img[data-original][src*="data:image/"] {
    height: 305px; }

@media (min-width: 768px) {
  #homePage .layoutBuilder [class="c_1073864831"],
  #homePage .layoutBuilder [class="c_1073864836"],
  #homePage .layoutBuilder [class="c_1073864841"],
  #homePage .layoutBuilder [class="c_1073864844"] {
    float: left !important;
    width: 25% !important; } }

@media (min-width: 768px) {
  #homePage .layoutBuilder [class=c_1073865826],
  #homePage .layoutBuilder [class=c_1073865827],
  #homePage .layoutBuilder [class=c_1073865825],
  #homePage .layoutBuilder [class=c_1073874868],
  #homePage .layoutBuilder [class=c_1073874851],
  #homePage .layoutBuilder [class=c_1073874872],
  #homePage .layoutBuilder [class=c_1073874855],
  #homePage .layoutBuilder [class=c_1073874861],
  #homePage .layoutBuilder [class=c_1073874828],
  #homePage .layoutBuilder [class=c_1073874865],
  #homePage .layoutBuilder [class=c_1073874831],
  #homePage .layoutBuilder [class=c_1073874830],
  #homePage .layoutBuilder [class=c_1073874866],
  #homePage .layoutBuilder [class=c_1073874834],
  #homePage .layoutBuilder [class=c_1073874835],
  #homePage .layoutBuilder [class=c_1073874873],
  #homePage .layoutBuilder [class=c_1073874847],
  #homePage .layoutBuilder [class=c_1073874862],
  #homePage .layoutBuilder [class=c_1073874874],
  #homePage .layoutBuilder [class=c_1073874829],
  #homePage .layoutBuilder [class=c_1073874854],
  #homePage .layoutBuilder [class=c_1073893335],
  #homePage .layoutBuilder [class=c_1073893333],
  #homePage .layoutBuilder [class=c_1073893334],
  #homePage .layoutBuilder [class=c_1073893336],
  #homePage .layoutBuilder [class=c_1073893338],
  #homePage .layoutBuilder [class=c_1073874840],
  #homePage .layoutBuilder [class=c_1073874879],
  #homePage .layoutBuilder [class=c_1073874846],
  #homePage .layoutBuilder [class=c_1073897835],
  #homePage .layoutBuilder [class=c_1073897836],
  #homePage .layoutBuilder [class=c_1073897837],
  #homePage .layoutBuilder [class=c_1073893337] {
    float: left !important;
    width: 33.333333333% !important; }
    #homePage .layoutBuilder [class=c_1073865826] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073865827] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073865825] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874868] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874851] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874872] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874855] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874861] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874828] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874865] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874831] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874830] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874866] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874834] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874835] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874873] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874847] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874862] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874874] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874829] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874854] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073893335] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073893333] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073893334] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073893336] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073893338] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874840] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874879] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874846] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073897835] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073897836] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073897837] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073893337] img[data-original][src*="data:image/"] {
      height: 305px; } }

@media (min-width: 768px) {
  #homePage .layoutBuilder [class=c_1073874875],
  #homePage .layoutBuilder [class=c_1073874836],
  #homePage .layoutBuilder [class=c_1073874869],
  #homePage .layoutBuilder [class=c_1073874880],
  #homePage .layoutBuilder [class=c_1073874845],
  #homePage .layoutBuilder [class=c_1073874853],
  #homePage .layoutBuilder [class=c_1073874849],
  #homePage .layoutBuilder [class=c_1073874842],
  #homePage .layoutBuilder [class=c_1073874860],
  #homePage .layoutBuilder [class=c_1073874882],
  #homePage .layoutBuilder [class=c_1073874871],
  #homePage .layoutBuilder [class=c_1073874867],
  #homePage .layoutBuilder [class=c_1073874839],
  #homePage .layoutBuilder [class=c_1073874857],
  #homePage .layoutBuilder [class=c_1073874877],
  #homePage .layoutBuilder [class=c_1073874883] {
    float: left !important;
    width: 50% !important; }
    #homePage .layoutBuilder [class=c_1073874875] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874836] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874869] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874880] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874845] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874853] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874849] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874842] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874860] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874882] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874871] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874867] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874839] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874857] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874877] img[data-original][src*="data:image/"],
    #homePage .layoutBuilder [class=c_1073874883] img[data-original][src*="data:image/"] {
      height: 305px; } }

.show-homePage,
.show-departmentPage,
.show-categoryPage,
.show-productPage,
.show-searchPage {
  display: none; }

#homePage .show-homePage,
#departmentPage .show-departmentPage,
#categoryPage .show-categoryPage,
#productPage .show-productPage,
#searchPage .show-searchPage {
  display: block; }

.u-lowercase {
  text-transform: lowercase; }

ul.u-unstyled {
  padding: 0;
  margin: 0;
  list-style: none; }
  ul.u-unstyled li {
    padding: 0; }

.u-optimal-line-length {
  max-width: 45em;
  margin: 0 auto; }

@media screen and (min-width: 768px) {
  #departmentPage .espot--50, #categoryPage .full-espot--50 {
    position: relative;
    margin-left: -15px;
    margin-right: -15px; }
    #departmentPage .espot--50 .promo, #categoryPage .full-espot--50 .promo {
      width: 50%;
      float: left;
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      #departmentPage .espot--50 .promo__button, #categoryPage .full-espot--50 .promo__button {
        margin-right: 15px; }
      #departmentPage .espot--50 .promo__inline-legal, #categoryPage .full-espot--50 .promo__inline-legal {
        left: 25px; }
    #departmentPage .espot--50 .legal__container, #categoryPage .full-espot--50 .legal__container {
      margin: 0 15px;
      width: calc(100% - 30px); }
    #departmentPage .espot--50 .rebateTag, #categoryPage .full-espot--50 .rebateTag {
      left: auto; } }

.categorytag {
  min-width: 120px !important; }

.PCXX_full, .PCXX_full .container {
  position: relative;
  background: #00315C; }

#homePage .PCXX_full {
  display: none !important; }

.PCXX_full .detail {
  font-size: 11px;
  text-decoration: underline;
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #fff; }

/* couleur principale */
.PCXX_full .mobileversion {
  color: #fff;
  font-size: 1em;
  text-align: center; }

.PCXX_full .mobileversion p {
  margin: 5px 40px;
  line-height: 1em; }

.PCXX_full .main {
  font-size: 1.5em; }

/*couelur secondaire*/
.PCXX_full .sec-color {
  color: #FFDD00;
  /*yellow*/ }

.PCXX_full .cap {
  text-transform: uppercase; }

.accordion-title-arrow {
  color: #666666;
  margin-left: 17px;
  text-indent: -17px; }
  .accordion-title-arrow svg {
    fill: #666666;
    transform: rotate(-90deg);
    transition: transform 0.2s;
    top: -2px;
    position: relative;
    margin-right: 5px; }
  .accordion-title-arrow:after, .accordion-title-arrow:before {
    content: none; }
  .accordion-title-arrow.active {
    color: #00315d; }
    .accordion-title-arrow.active svg {
      transform: rotate(0deg);
      transition: transform 0.2s; }

body#productPage .alert-message,
body#shoppingCartPage .alert-message {
  display: none !important; }

.alert-message {
  font-size: 14px;
  position: fixed;
  top: 100%;
  left: 0;
  transform: translateY(0);
  width: 100%;
  z-index: 1000;
  transition: transform 300ms ease; }
  .alert-message.active {
    transform: translateY(-100%); }
    .alert-message.active .alert-message__button svg {
      transform: rotate(0deg); }
  .alert-message__button {
    background: white;
    border: 1px solid #dddddd;
    color: black;
    padding: 10px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: translateY(-100%); }
    .alert-message__button:hover {
      background: white;
      color: black; }
    .alert-message__button img,
    .alert-message__button svg:not(.icon-arrow-down) {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      fill: red; }
    .alert-message__button span {
      display: inline-block;
      vertical-align: middle; }
    .alert-message__button svg.icon-arrow-down {
      display: inline-block;
      margin-left: 7px;
      vertical-align: middle;
      transform: rotate(180deg); }
  .alert-message__content {
    background: white;
    color: black;
    border-top: 1px solid #dddddd;
    padding: 15px; }

.button-text-arrow {
  display: block;
  margin-bottom: 15px;
  text-align: right; }
  .button-text-arrow--red:after {
    border-left: 5px solid #e82535; }
  .button-text-arrow span {
    color: black;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase; }
  .button-text-arrow:after {
    content: "";
    display: inline-block;
    border-left: 10px solid #00315d;
    border-right: 0px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin-left: 10px;
    vertical-align: middle; }

.button--white {
  background: white;
  color: black; }

.collection_slider {
  padding: 10px 0 0; }
  .collection_slider .slick-list {
    padding: 0 !important; }
  .collection_slider:after, .collection_slider:before {
    position: absolute;
    height: 100%;
    top: 0;
    width: 220px;
    z-index: 110;
    max-width: 20%; }
    @media screen and (max-width: 990px) {
      .collection_slider:after, .collection_slider:before {
        content: ""; } }
  .collection_slider:before {
    left: 0; }
    @media screen and (max-width: 990px) {
      .collection_slider:before {
        background: linear-gradient(90deg, white 33%, rgba(255, 255, 255, 0.85) 66%, rgba(255, 255, 255, 0.6) 80%, transparent); } }
  .collection_slider:after {
    right: 0; }
    @media screen and (max-width: 990px) {
      .collection_slider:after {
        background: linear-gradient(-90deg, white 33%, rgba(255, 255, 255, 0.85) 66%, rgba(255, 255, 255, 0.6) 80%, transparent); } }
  .collection_slider img {
    width: 100%; }
  .collection_slider .slick-next,
  .collection_slider .slick-prev {
    display: none;
    background: url("//www.rona.ca/documents/rona/specialpages/2016/smart-home/assets/images/slider-arrow.png") center center/15px auto no-repeat;
    position: absolute;
    top: 50%;
    height: 42px;
    margin-top: 0;
    width: 29px;
    z-index: 120;
    transform: translateY(-50%); }
    @media screen and (max-width: 767px) {
      .collection_slider .slick-next,
      .collection_slider .slick-prev {
        height: 30px;
        width: 20px; } }
  .collection_slider .slick-prev {
    transform: rotate(180deg) translateY(50%);
    left: 25px; }
  .collection_slider .slick-next {
    right: 25px; }
  .collection_slider .swiper-slide {
    padding: 0 5px; }
    .collection_slider .swiper-slide a {
      display: block;
      position: relative; }
      .collection_slider .swiper-slide a:hover h2:after {
        width: 100%; }
    .collection_slider .swiper-slide h2 {
      bottom: 0;
      color: white;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 28px;
      font-weight: bold;
      left: 0;
      position: absolute;
      text-transform: uppercase;
      width: 100%;
      padding: 6px 12px;
      margin: 0; }
      @media screen and (max-width: 767px) {
        .collection_slider .swiper-slide h2 {
          font-size: 16px; } }
      .collection_slider .swiper-slide h2 span {
        position: relative;
        z-index: 1; }
      .collection_slider .swiper-slide h2:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        height: 100%;
        width: 0;
        transition: width 300ms ease; }

.button-link {
  background: #fff;
  color: black;
  border: 1px solid #9d9f9c;
  padding: 10px 18px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1em;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease; }
  .button-link:hover {
    background: black;
    color: white; }
  .button-link.brown {
    float: right;
    background: #605954;
    border: 2px solid #605954;
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0; }
    .button-link.brown:hover {
      background: white;
      color: #605954; }
  .button-link.red {
    background: #e9251f;
    border: 1px solid #e9251f;
    color: white;
    margin-top: 15px; }
    .button-link.red:hover {
      background: white;
      color: black; }

.bloctitle {
  position: absolute;
  bottom: -125px;
  left: 0;
  right: 0;
  text-align: center; }
  .bloctitle h1 {
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    line-height: 1;
    margin: 0 auto;
    padding: 20px 0;
    background: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/tag_center.png") center center repeat-x;
    position: relative;
    height: 70px;
    display: inline-block; }
    .bloctitle h1::after, .bloctitle h1::before {
      content: "";
      background: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/tag_end.png") center center repeat-x;
      height: 70px;
      width: 39px;
      position: absolute;
      top: 0;
      left: -39px;
      display: block; }
    .bloctitle h1::after {
      transform: rotate(180deg);
      left: auto;
      right: -39px;
      top: -1px; }
  .bloctitle p {
    font-size: 15px;
    line-height: 1.2;
    font-family: "Roboto Condensed", sans-serif;
    color: #827035;
    margin: 0; }

.espot-DepartmentBottomContent {
  display: block; }

.dept-cross-sale {
  margin-top: 35px;
  color: #000000;
  transition: color ease-in-out 0.2s;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%; }
  .dept-cross-sale:hover .dept-cross-sale__title {
    color: #0073b9; }
  .dept-cross-sale:hover .dept-cross-sale__extra p:after {
    transform: translate(4px) rotate(-90deg); }
  .dept-cross-sale:hover .dept-cross-sale__image {
    transform: scale(1.05) translate3d(0, 0, 0); }
  .dept-cross-sale__image-wrapper {
    position: relative;
    margin-top: auto;
    overflow: hidden;
    border: 2px solid #ededed; }
  .dept-cross-sale__image {
    width: 100%;
    display: block;
    transform: scale(1) translate3d(0, 0, 0);
    transition: transform ease-in-out 0.4s; }
  .dept-cross-sale__cta {
    position: absolute;
    bottom: 10px;
    right: 10px; }
  .dept-cross-sale__title {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    color: #000;
    transition: color ease-in-out 0.2s;
    text-transform: none; }
  .dept-cross-sale__extra {
    font-size: 16px;
    max-width: 90%;
    color: #000000;
    transition: color ease-in-out 0.2s;
    display: flex;
    align-items: flex-end; }
    @media screen and (max-width: 767px) {
      .dept-cross-sale__extra {
        max-width: none; } }
    .dept-cross-sale__extra p {
      display: inline;
      margin-top: 0;
      font-size: 16px;
      padding-right: 20px; }
      .dept-cross-sale__extra p:after {
        content: url("https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg");
        width: 10px;
        height: 10px;
        display: inline-block;
        vertical-align: middle;
        transition: transform 150ms ease;
        transform: translate(0) rotate(-90deg);
        margin-right: -10px; }

.espot-DepartmentTopContent {
  display: block; }

.dept-inspiration {
  font-size: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  position: relative; }
  @media screen and (max-width: 1199px) {
    .dept-inspiration {
      font-size: 0.9vw; } }
  @media screen and (max-width: 767px) {
    .dept-inspiration {
      font-size: 1.2vw; } }
  .dept-inspiration__title {
    color: white;
    font-size: 4em;
    font-weight: bold;
    line-height: 1.1;
    margin: 0;
    text-align: center;
    text-transform: uppercase; }
    .dept-inspiration__title small {
      display: block;
      font-size: 0.7em; }
    @media screen and (max-width: 767px) {
      .dept-inspiration__title {
        white-space: nowrap; } }
  .dept-inspiration__title-box {
    background: rgba(0, 0, 0, 0.4);
    left: 50%;
    padding: 2.5em 3em;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); }

.dept-page-link {
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  color: black;
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  padding: 25px;
  text-align: center;
  transition: all 0.3s ease; }
  @media screen and (max-width: 991px) {
    .dept-page-link {
      margin-top: 0; } }
  .dept-page-link:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .dept-page-link__text {
    font-size: 16px;
    margin: 15px 0; }

.hub {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border: 4px solid #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  color: #fff;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .hub {
      padding: 2vw 5vw; } }
  .hub__title {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 75px;
    text-transform: uppercase;
    padding-right: 20px; }
    @media screen and (max-width: 768px) {
      .hub__title {
        font-size: 10vw;
        padding-right: 5vw; } }
  .hub__date {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 30px;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .hub__date {
        font-size: 5vw; } }
    .hub__date span:first-child {
      border-bottom: 2px solid #fff; }

.dept-inspiration__experience {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 30px 0; }

.dept-inspiration__experience__title {
  text-align: center;
  font-size: 22px;
  color: #00315d; }

.dept-inspiration__experience__icon {
  background: #00315d;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  border-radius: 80px;
  margin: 0 auto; }

.dept-inspiration__experience__icon--return,
.dept-inspiration__experience__icon--price {
  background: transparent; }

.dept-inspiration__experience__subtitle {
  color: #00315d;
  font-size: 13px;
  padding: 0px 20px; }

.dept-inspiration__experience__subtitle span {
  display: block;
  margin: 5px 0px; }

.dept-inspiration__experience__subtitle strong {
  font-size: 30px;
  margin-bottom: 5px;
  display: block; }

.dept-inspiration__experience__box {
  flex: 1;
  text-align: center; }

.dept-inspiration__experience__icon svg {
  fill: white;
  width: 43px; }

.dept-inspiration__experience__icon--price svg,
.dept-inspiration__experience__icon--return svg {
  height: 80px;
  width: 80px;
  fill: #00315d; }

@media screen and (max-width: 767px) {
  .dept-inspiration__experience__title {
    font-size: 16px; }
  .dept-inspiration__experience__subtitle {
    font-size: 10px;
    padding: 0px 10px; }
  .dept-inspiration__experience__box {
    flex: 1 0 33.33%;
    width: 33.33%;
    margin-bottom: 5px; }
  .dept-inspiration__experience__icon svg {
    width: 33px; }
  .dept-inspiration__experience__icon,
  .dept-inspiration__experience__icon--price svg,
  .dept-inspiration__experience__icon--return svg {
    width: 60px;
    height: 60px; }
  .dept-inspiration__experience {
    padding-bottom: 15px; } }

@media screen and (max-width: 400px) {
  .dept-inspiration__experience__title {
    font-size: 12px;
    padding-bottom: 5px; }
  .dept-inspiration__experience__subtitle strong {
    font-size: 24px; } }

.espot-DepartmentBottomContent {
  display: block; }

.logo--install:after {
  content: "";
  width: 30%;
  max-width: 150px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  background: url(https://www.rona.ca/documents/ronaResponsive/Install/laissez-nous-installer.png) no-repeat;
  background-size: 100% auto; }

body.en .logo--install:after {
  background: url(https://www.rona.ca/documents/ronaResponsive/Install/get-it-installed.png) no-repeat;
  background-size: 100% auto; }

.dept-install-diy {
  margin-top: 35px;
  color: #000000;
  transition: color ease-in-out 0.2s;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%; }
  .dept-install-diy:hover .dept-install-diy__title {
    color: #0073b9; }
  .dept-install-diy:hover .dept-install-diy__extra p:after {
    transform: translate(4px) rotate(-90deg); }
  .dept-install-diy:hover .dept-install-diy__image {
    transform: scale(1.05) translate3d(0, 0, 0); }
  .dept-install-diy__image-wrapper {
    position: relative;
    margin-top: auto;
    overflow: hidden;
    border: 2px solid #ededed; }
  .dept-install-diy__image {
    width: 100%;
    display: block;
    transform: scale(1) translate3d(0, 0, 0);
    transition: transform ease-in-out 0.4s; }
  .dept-install-diy__cta {
    position: absolute;
    bottom: 10px;
    right: 10px; }
  .dept-install-diy__title {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    color: #000;
    transition: color ease-in-out 0.2s;
    text-transform: none; }
  .dept-install-diy__extra {
    font-size: 16px;
    max-width: 90%;
    color: #000000;
    transition: color ease-in-out 0.2s;
    display: flex;
    align-items: flex-end; }
    @media screen and (max-width: 767px) {
      .dept-install-diy__extra {
        max-width: none; } }
    .dept-install-diy__extra p {
      display: inline;
      margin-top: 0;
      font-size: 16px;
      padding-right: 20px; }
      .dept-install-diy__extra p:after {
        content: url("https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg");
        width: 10px;
        height: 10px;
        display: inline-block;
        vertical-align: middle;
        transition: transform 150ms ease;
        transform: translate(0) rotate(-90deg);
        margin-right: -10px; }

.department-01-103 {
  position: relative;
  padding: 38px 0 25px; }
  .department-01-103:after {
    content: "";
    height: 13px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    width: 100vw;
    background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/pattern_oblique_beige3.png"); }
    @media screen and (max-width: 768px) {
      .department-01-103:after {
        left: 100%; } }

body.fr .page-department__inner--01-103 .category-page-box.DIY {
  background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_1280x307_FR.jpg"); }
  @media screen and (max-width: 768px) {
    body.fr .page-department__inner--01-103 .category-page-box.DIY {
      background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_768x320_FR.jpg"); } }

body.fr .page-department__inner--01-103 .category-page-box.concours {
  background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_635x307_FR.jpg"); }
  @media screen and (max-width: 768px) {
    body.fr .page-department__inner--01-103 .category-page-box.concours {
      background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_768x320_FR.jpg"); } }

body.fr .page-department__inner--01-103 .category-page-box.petite-reno {
  background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_635x307_FR.jpg"); }
  @media screen and (max-width: 768px) {
    body.fr .page-department__inner--01-103 .category-page-box.petite-reno {
      background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_768x320_FR.jpg"); } }

body.fr .page-department__inner--01-103 .category-page-box.gift {
  background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_1280x307_FR.jpg"); }
  @media screen and (max-width: 768px) {
    body.fr .page-department__inner--01-103 .category-page-box.gift {
      background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_768x320_FR.jpg"); } }

.page-department__inner--01-103 .category-page-box {
  height: 307px;
  margin-bottom: 10px;
  position: relative;
  display: block;
  background-size: auto 100%;
  background-repeat: no-repeat; }
  @media screen and (max-width: 768px) {
    .page-department__inner--01-103 .category-page-box {
      height: 44vw; } }
  .page-department__inner--01-103 .category-page-box.DIY {
    background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_1280x307_EN.jpg"); }
    @media screen and (max-width: 768px) {
      .page-department__inner--01-103 .category-page-box.DIY {
        background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_diy_768x320_EN.jpg"); } }
  .page-department__inner--01-103 .category-page-box.concours {
    background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_635x307_EN.jpg"); }
    @media screen and (max-width: 768px) {
      .page-department__inner--01-103 .category-page-box.concours {
        background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_concours_768x320_EN.jpg"); } }
  .page-department__inner--01-103 .category-page-box.petite-reno {
    background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_635x307_EN.jpg"); }
    @media screen and (max-width: 768px) {
      .page-department__inner--01-103 .category-page-box.petite-reno {
        background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_reno_768x320_EN.jpg"); } }
  .page-department__inner--01-103 .category-page-box.gift {
    background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_1280x307_EN.jpg"); }
    @media screen and (max-width: 768px) {
      .page-department__inner--01-103 .category-page-box.gift {
        background-image: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/dept_cadeaux_768x320_EN.jpg"); } }
  .page-department__inner--01-103 .category-page-box .button-link {
    bottom: 10px;
    right: 10px;
    position: absolute;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    background: #fff;
    padding: 10px 18px;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1em;
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 250ms ease; }
    .page-department__inner--01-103 .category-page-box .button-link.red {
      background: #d80e3c;
      border: 1px solid #d80e3c;
      color: #fff; }
    .page-department__inner--01-103 .category-page-box .button-link.blue {
      background: #0073b9;
      border: 1px solid #0073b9;
      color: #fff; }
    .page-department__inner--01-103 .category-page-box .button-link.gold {
      background: #baa14c;
      border: 1px solid #baa14c;
      color: #fff; }
    .page-department__inner--01-103 .category-page-box .button-link:hover {
      background: #fff;
      color: #000; }

.espot-DepartmentBottomContent {
  display: block; }

.dept-top-brands__title {
  color: #000;
  text-transform: none;
  font-size: 24px;
  font-weight: 400; }

.dept-top-brands__item {
  margin: 0 5px;
  border: 1px solid #d7d5d5;
  display: block;
  transition: border-color ease-in-out 0.2s; }
  .dept-top-brands__item:hover {
    border-color: #0073b9; }
  .dept-top-brands__item:hover .dept-top-brands__logo {
    opacity: 0.6 !important; }

.dept-top-brands__logo {
  display: block;
  margin: 0 auto;
  opacity: 1 !important;
  transition: opacity ease-in-out 0.2s; }

.dept-top-brands .slick-slider__container {
  margin-left: 20px;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .dept-top-brands .slick-slider__container {
      margin-left: 0;
      margin-right: 0; } }
  .dept-top-brands .slick-slider__container .slick-list {
    min-height: 94px; }

.dept-top-brands .slick-slider__nav {
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -12px; }

.top-brands__item {
  width: 205px;
  display: inline-block !important;
  margin: 0 5px; }
  .top-brands__item:hover .top-brands__logo {
    opacity: 0.6 !important; }

.top-brands__logo {
  opacity: 1 !important; }

.widget__storeInfo-trigger * {
  vertical-align: middle; }

.widget__storeInfo-trigger:before {
  background: url(//www.rona.ca/documents/ronaResponsive/Espots/MarketingSegmentsAndScripts/ABtestPin/pin-map.png) center center no-repeat;
  background-size: auto 16px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
  height: 16px;
  width: 13px; }
  @media screen and (max-width: 767px) {
    .widget__storeInfo-trigger:before {
      background: url(//www.rona.ca/documents/ronaResponsive/Espots/MarketingSegmentsAndScripts/ABtestPin/pin-map--white.png) center center no-repeat;
      background-size: auto 16px;
      margin-left: 0; } }

.header__top-store .widget__storeInfo-trigger span {
  max-width: calc(100% - 40px); }

.legal-cart-links {
  background: #f5f5f5;
  padding: 25px;
  color: black;
  font-size: 20px;
  text-align: center; }
  .legal-cart-links__title {
    margin-bottom: 15px; }
  .legal-cart-links a {
    color: #666;
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.4;
    text-transform: uppercase; }
    .legal-cart-links a:hover {
      color: #4071ac; }
    .legal-cart-links a:not(:last-of-type):after {
      color: #666;
      content: "|";
      font-weight: normal;
      margin: 0 8px; }

.flashsale {
  margin-bottom: 20px;
  background: #00315d;
  padding: 0 10px 10px;
  position: relative; }
  .flashsale--with-legal {
    margin-bottom: 70px; }
  @media screen and (min-width: 1200px) {
    .flashsale--vertical {
      padding-top: 10px; }
      .flashsale--vertical .flashsale__inner {
        display: flex;
        flex-direction: row; }
      .flashsale--vertical .flashsale__header {
        flex: 0 0 450px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: radial-gradient(#004888, #00315d); }
      .flashsale--vertical .flashsale__content {
        flex: 1 0 auto; }
      .flashsale--vertical .flashsale__title {
        margin: 0;
        max-width: 300px; }
      .flashsale--vertical .flashsale__logo,
      .flashsale--vertical .flashsale__logo svg {
        width: 300px;
        margin: 0; }
      .flashsale--vertical .flashsale__date {
        margin: 20px 0;
        font-size: 20px; }
      .flashsale--vertical .flashsale__today-only {
        margin: 0;
        padding: 10px 20px;
        font-size: 19px; } }
  .flashsale__header {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    padding: 15px 10px; }
  .flashsale__title {
    max-width: 200px;
    margin-right: auto;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .flashsale__title {
        flex-basis: auto;
        max-width: calc(50% - 20px); } }
  .flashsale__logo,
  .flashsale__logo svg {
    width: 200px;
    max-width: 100%;
    max-height: 40px; }
  .flashsale__logo > div {
    display: flex; }
  .flashsale__date {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px; }
    @media screen and (max-width: 767px) {
      .flashsale__date {
        margin-top: 10px;
        order: 3;
        flex-basis: 100%;
        justify-content: center; } }
  .flashsale__day-of-week {
    margin-right: 0.35em;
    color: #fd0; }
  .flashsale__inner {
    position: relative; }
  .flashsale__content {
    background: #fff;
    padding: 10px;
    position: relative; }
  .flashsale__today-only {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: #fd0;
    color: #00315d;
    text-transform: uppercase;
    padding: 0 20px;
    font-weight: 800;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .flashsale__today-only {
        flex-basis: auto;
        max-width: 50%; } }
  .flashsale__item {
    position: relative; }
  .flashsale__item__online-only {
    position: absolute;
    right: 40px;
    width: 75px;
    top: 5px; }
  .flashsale__item__image {
    margin-left: 100px;
    max-height: 250px;
    max-width: calc(100% - 100px); }
    @media screen and (max-width: 768px) {
      .flashsale__item__image {
        margin: 0 auto;
        max-width: 100%; } }
  @media screen and (min-width: 769px) {
    .slick-slider[data-count="1"] .flashsale__item__image,
    .slick-slider[data-count="2"] .flashsale__item__image {
      margin: 0 auto; } }
  .flashsale .slick-slider__nav {
    position: static; }
  .flashsale .slick-slider__nav .slick-prev,
  .flashsale .slick-slider__nav .slick-next {
    position: absolute;
    top: auto;
    bottom: 10px;
    height: 36px;
    width: 36px;
    display: flex !important;
    align-items: center;
    justify-content: center; }
  .flashsale .slick-slider__nav .slick-next.slick-disabled,
  .flashsale .slick-slider__nav .slick-prev.slick-disabled {
    opacity: 0;
    pointer-events: none; }
  .flashsale .slick-slider__nav .slick-prev svg,
  .flashsale .slick-slider__nav .slick-next svg {
    width: 16px; }
  .flashsale .slick-slider__nav .slick-prev svg path,
  .flashsale .slick-slider__nav .slick-next svg path {
    fill: #00315d; }
  .flashsale .slick-slider__nav .slick-prev svg {
    margin-top: 1px; }
  .flashsale .slick-slider__nav .slick-next svg {
    margin-left: 3px; }
  .flashsale .slick-slider__nav .slick-prev {
    left: 0; }
  .flashsale .slick-slider__nav .slick-next {
    right: 0; }
  .flashsale .slick-slider__nav .slick-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px; }
  .flashsale .slick-slider__nav .slick-dots li {
    margin: 0 4px 4px; }
  .flashsale .slick-slider__nav .slick-dots button {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.75);
    width: 14px;
    height: 14px; }
  .flashsale__inline-legal {
    background: #fff;
    padding: 10px 10px 0;
    color: #000;
    font-size: 12px; }
  .flashsale .rebateTag .rebateTag__number:after {
    content: "‡"; }
  .flashsale .rebateTag .rebateTag__unitOfMeasure {
    display: none !important; }

.flashsale--sticky {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0; }
  @media screen and (max-width: 991px) {
    .flashsale--sticky {
      padding-right: 25px; } }
  .flashsale--sticky .flashsale__header {
    padding: 4px 10px 0;
    max-width: 1000px;
    margin: 0 auto;
    color: #fff; }
    .flashsale--sticky .flashsale__header:hover {
      color: #fff; }
  .flashsale--sticky .flashsale__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    background: none;
    color: #fff;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800; }

.flashsale-stores {
  margin-top: 10px;
  position: relative; }
  .flashsale-stores__header {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    padding: 25px 20px;
    background: #00315d; }
  .flashsale-stores__title {
    max-width: 200px;
    margin-right: auto;
    display: flex;
    align-items: center; }
  @media screen and (max-width: 767px) {
    .flashsale-stores__title {
      flex-basis: auto;
      max-width: calc(50% - 20px); } }
  .flashsale-stores__logo,
  .flashsale-stores__logo svg {
    width: 200px;
    max-width: 100%;
    max-height: 40px; }
  .flashsale-stores__logo > div {
    display: flex; }
  .flashsale-stores__date {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px; }
  @media screen and (max-width: 767px) {
    .flashsale-stores__date {
      margin-top: 10px;
      order: 3;
      flex-basis: 100%;
      justify-content: center; } }
  .flashsale-stores__day-of-week {
    margin-right: 0.35em;
    color: #fd0; }
  .flashsale-stores__content {
    background: #fff;
    padding: 10px; }
  .flashsale-stores__today-only {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: #fd0;
    color: #00315d;
    text-transform: uppercase;
    padding: 0 20px;
    font-weight: 800;
    text-align: center; }
  @media screen and (max-width: 767px) {
    .flashsale-stores__today-only {
      flex-basis: auto;
      max-width: 50%; } }

#enewsSubscribe .modal-content {
  width: 400px !important;
  max-width: 90%; }

#subEnews {
  padding: 20px 0 0;
  width: 100%; }
  #subEnews .close {
    position: absolute;
    top: 24px;
    right: 20px; }
  #subEnews h3 {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    text-transform: none;
    padding-bottom: 15px;
    color: #000000;
    border-bottom: 1px solid #dddddd;
    font-weight: normal;
    margin: 0;
    padding-right: 25px; }
  #subEnews .airmilesLogo--title {
    font-size: 16px;
    color: #000000;
    margin: 15px 0 0 50px; }
  #subEnews .airmilesLogo--amount {
    color: #1fb4fc;
    font-size: 85px;
    font-weight: bold;
    padding-left: 0; }
  #subEnews .airmilesLogo--logo {
    padding-right: 0; }
  #subEnews .airmilesLogo svg {
    float: right;
    width: 100px;
    height: 110px; }
  #subEnews .info {
    text-align: center;
    color: #666666; }
    #subEnews .info--title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 0 20px; }
    #subEnews .info--text {
      font-size: 12px;
      line-height: 2em; }
    #subEnews .info--term {
      color: #0073b9;
      font-size: 12px; }
  #subEnews #enewsForm {
    font-size: 12px; }
    #subEnews #enewsForm .formrow {
      max-width: 75%;
      margin: 10px auto; }
    #subEnews #enewsForm .checkbox-custom {
      vertical-align: top; }
    #subEnews #enewsForm .checkbox-label,
    #subEnews #enewsForm p {
      font-size: 12px; }
    #subEnews #enewsForm .checkbox {
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px; }
    #subEnews #enewsForm #moreCS {
      display: none;
      color: #666666;
      margin-top: 10px; }
      #subEnews #enewsForm #moreCS ul {
        padding: 0; }
      #subEnews #enewsForm #moreCS li {
        list-style: none;
        text-indent: -5px;
        margin: 5px 0 5px 20px; }
        #subEnews #enewsForm #moreCS li:before {
          content: "•";
          color: #666666;
          margin-right: 5px;
          display: inline-block; }
  #subEnews .login {
    background: #002f5f;
    color: #fff;
    margin: 10px auto;
    padding: 10px 20px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    text-align: center;
    width: 50%;
    transition: background-color ease-in-out 0.2s; }
    #subEnews .login:hover {
      background: #0073b9;
      transition: background-color ease-in-out 0.2s; }

.promo {
  position: relative;
  margin-bottom: 20px; }
  .promo__title {
    color: black;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 50px 0 25px;
    position: relative; }
    .promo__title .topOffers__countdown {
      border-left: 9px solid white;
      color: white;
      display: inline-block;
      background: #ff0000;
      font-size: 22px;
      line-height: 1.2;
      padding: 5px 9px;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1; }
      @media screen and (min-width: 992px) {
        .promo__title .topOffers__countdown {
          transform: translateY(-50%); } }
      @media screen and (max-width: 991px) {
        .promo__title .topOffers__countdown {
          border: none;
          position: relative;
          right: 0;
          top: 50%;
          margin-top: 5px; } }
      @media screen and (max-width: 767px) {
        .promo__title .topOffers__countdown {
          border-left: 9px solid white;
          border-right: 9px solid white;
          font-size: 20px;
          line-height: 1.2; } }
    .promo__title:after {
      content: "";
      background: #c4c4c4;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%; }
    .promo__title--countdown:after {
      top: 16px; }
      @media screen and (max-width: 767px) {
        .promo__title--countdown:after {
          top: 13px; } }
    .promo__title span {
      background: white;
      display: inline-block;
      padding-right: 22px;
      position: relative;
      z-index: 1; }
    @media screen and (max-width: 767px) {
      .promo__title {
        font-size: 22px;
        padding: 0 25px;
        text-align: center; }
        .promo__title span {
          padding: 0 15px; } }
  .promo__subtitle {
    color: #3e3e3e;
    font-weight: bold;
    margin-top: 0;
    text-transform: uppercase; }

.promo__legal-modal-link:hover {
  text-decoration: underline; }

.promo-banner__topText {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  top: 0;
  padding: 10px;
  position: absolute; }
  .promo-banner__topText--left {
    left: 0; }
  .promo-banner__topText--right {
    right: 0;
    text-align: right; }

.promo__wrapper {
  font-size: 0; }

#categoryPage .promo,
#departmentPage .promo {
  margin-top: 0;
  margin-bottom: 20px; }

.promo.promo--with-legal {
  margin-bottom: 70px; }

#categoryPage .promo--with-legal,
#departmentPage .promo--with-legal {
  margin-top: 0;
  margin-bottom: 65px; }

.promo img {
  width: 100%; }

.promo__inline-legal {
  color: white;
  font-size: 11px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-shadow: 0 0 5px rgba(66, 66, 66, 0.6); }

.promo__button {
  position: absolute;
  bottom: 10px;
  right: 10px; }

.promo__button-strip {
  position: absolute;
  bottom: 10px;
  right: 10px; }

.promo__button-strip .promo__button {
  position: static; }

@media screen and (max-width: 767px) {
  .promo__button-strip {
    position: static;
    padding: 5px 0; }
  .promo__button-strip .promo__button {
    display: block; }
  .promo__button-strip .promo__button + .promo__button {
    margin-top: 5px; } }

.button-strip--vertical {
  text-align: right; }

.button-strip--vertical .button-cta {
  clear: both;
  float: right;
  display: inline-block; }

.button-strip--vertical .button-cta + .button-cta {
  margin-left: 0;
  margin-top: 5px; }

.promo__button-bar {
  position: relative;
  background: #00315c;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-top: 1px solid #fff; }

.promo__button-bar .promo__button {
  flex: 1;
  color: #fff;
  padding: 10px 5px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 1200px) {
    .promo__button-bar .promo__button {
      flex: 1 0 33%;
      padding: 10px 5px;
      border-bottom: 1px solid #fff; } }
  @media screen and (max-width: 768px) {
    .promo__button-bar .promo__button {
      flex: 1 0 50%; } }

.promo__button-bar .promo__button:hover {
  background: #fff;
  color: #00315d; }

.promo__button-bar .promo__button + .promo__button {
  border-left: 1px solid #fff; }

.datesPromo {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  top: 0;
  padding: 10px;
  width: 100%;
  position: relative;
  background: #00264d;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .datesPromo {
      font-size: 3vw; } }
  .datesPromo .texteGauche {
    text-align: left;
    padding-right: 10px;
    position: relative;
    flex: 1 1 50%;
    max-width: 45%; }
    @media screen and (max-width: 768px) {
      .datesPromo .texteGauche {
        position: relative; } }
  .datesPromo .texteDroite {
    text-align: right;
    right: 10px;
    font-weight: bold;
    position: relative;
    font-weight: normal;
    flex: 1 1 50%;
    max-width: 45%; }
    @media screen and (max-width: 768px) {
      .datesPromo .texteDroite {
        position: relative;
        text-align: left;
        right: auto; } }

.promo__dates {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  top: 0;
  padding: 10px;
  width: 100%;
  position: relative;
  background: #00264d;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .promo__dates {
      font-size: 3vw; } }

.promo__date {
  position: relative;
  flex: 1 1 50%;
  max-width: 45%; }

.promo__date--left {
  text-align: left;
  padding-right: 10px; }

.promo__date--right {
  text-align: right;
  right: 10px;
  font-weight: normal; }
  @media screen and (max-width: 768px) {
    .promo__date--right {
      text-align: left;
      right: auto; } }

.promo__date--center {
  flex: 1 1 100%;
  text-align: center;
  color: #fff;
  line-height: 1.42; }

.promo--style-gen .promo__inline-legal {
  position: absolute;
  bottom: auto;
  left: 30px;
  top: 30px;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff !important;
  max-width: 40%; }
  @media screen and (max-width: 1200px) {
    .promo--style-gen .promo__inline-legal {
      left: 7%;
      top: 10%;
      font-size: 20px;
      line-height: 22px; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .promo--style-gen .promo__inline-legal {
      max-width: 40%; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .promo--style-gen .promo__inline-legal {
      max-width: 35%;
      font-size: 2vw;
      left: 5%;
      top: 7%; } }
  @media screen and (max-width: 767px) {
    .promo--style-gen .promo__inline-legal {
      font-size: 4vw;
      line-height: 5vw;
      max-width: 35%; } }
  .promo--style-gen .promo__inline-legal span {
    text-transform: none;
    font-size: 15px;
    display: block;
    width: 88%;
    line-height: 18px;
    font-weight: normal;
    color: #fff;
    margin-top: 5px; }
    @media screen and (max-width: 1200px) {
      .promo--style-gen .promo__inline-legal span {
        width: 100%;
        font-size: 13px;
        line-height: 15px; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .promo--style-gen .promo__inline-legal span {
        width: 85%; } }
    @media screen and (max-width: 767px) {
      .promo--style-gen .promo__inline-legal span {
        font-size: 3vw;
        line-height: 3.5vw;
        width: 85%; } }
    .promo--style-gen .promo__inline-legal span::after {
      content: "";
      -webkit-mask: url(https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg) no-repeat 100% 100%;
      mask: url(https://www.rona.ca/documents/ronaResponsive/Dept/icon-arrow-right.svg) no-repeat 100% 100%;
      background-color: #fff;
      width: 10px;
      height: 10px;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      display: inline-block;
      vertical-align: middle;
      -webkit-transition: margin-left 0.15s;
      transition: margin-left 0.15s;
      margin-right: -10px; }

.shopping-cart-legal--new {
  margin: 5px auto;
  padding-bottom: 75px;
  border-bottom: 1px solid #e6e6e6;
  height: 65px;
  overflow: hidden;
  position: relative;
  transition: height 0.3s;
  cursor: pointer; }
  .shopping-cart-legal--new .shopping-cart-legal__content {
    margin-right: 50px;
    padding: 10px 0; }
  .shopping-cart-legal--new .shopping-cart-legal__collapse {
    position: absolute;
    top: 20px;
    right: 15px; }
  .shopping-cart-legal--new .shopping-cart-legal__collapse svg {
    width: 20px;
    height: 20px; }
  .shopping-cart-legal--new .shopping-cart-legal__collapse .icon-minus {
    display: none; }
  .shopping-cart-legal--new.is-expanded .shopping-cart-legal__collapse .icon-minus {
    display: block; }
  .shopping-cart-legal--new.is-expanded .shopping-cart-legal__collapse .icon-plus {
    display: none; }
  .shopping-cart-legal--new:after {
    content: "";
    display: block;
    position: absolute;
    height: 45px;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 75%);
    bottom: 0;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s; }
  .shopping-cart-legal--new.is-expanded:after {
    opacity: 0; }

.tablelayout {
  background: #f2f2f2;
  padding: 10px;
  margin-top: 30px; }
  .tablelayout__title {
    padding-left: 20px; }
  .tablelayout__list {
    background: #fff;
    padding: 30px 20px 0;
    margin: 0; }

.listeMagasins {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .listeMagasins li {
    width: 33%;
    padding: 5px 15px; }

[name="romo_mag_participants"] {
  margin-top: 80px; }

.matc__button {
  position: relative; }
  .matc__button .la-line-scale {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 20px);
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease; }
  .matc__button.loading {
    color: #dedede; }
    .matc__button.loading .la-line-scale {
      opacity: 1; }

.espot-mainHeaderBottom--sticky {
  overflow: visible; }

.main__container--withSticky {
  position: relative; }
  .main__container--withSticky:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 9;
    transition: background 300ms ease;
    pointer-events: none; }
  .main__container--withSticky.opened:before {
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto; }

.sticky-menu {
  position: relative;
  z-index: 0; }

.sticky-menu__heading {
  background: #00315d;
  display: block;
  text-align: center;
  padding: 10px 0;
  color: white;
  font-size: 0;
  position: relative;
  z-index: 2; }
  .sticky-menu__heading .container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media all and (max-width: 1199px) {
      .sticky-menu__heading .container {
        padding: 0;
        width: 100%; } }
  .sticky-menu__heading:hover {
    color: white;
    background: #02427b; }
  .sticky-menu__heading.opened .sticky-menu__arrow {
    transform: rotate(-90deg); }

.sticky-menu__title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase; }

.sticky-menu__arrow {
  height: 18px;
  width: 18px;
  margin-left: 6px;
  transition: all 200ms ease;
  transform: rotate(0deg); }

.sticky-menu__close {
  position: absolute;
  right: 15px;
  padding: 0px;
  top: 50%;
  transform: translateY(-6px);
  background: none;
  color: white;
  border: none;
  font-size: 0; }
  .sticky-menu__close svg {
    height: 12px;
    width: 12px; }

.sticky-menu__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px 0px;
  color: black;
  font-size: 14px;
  background: white;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 400ms ease;
  pointer-events: none;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: none; }
  .sticky-menu__dropdown.opened {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: auto; }
  .sticky-menu__dropdown.with-dots {
    padding-bottom: 50px; }
  @media all and (max-width: 1199px) {
    .sticky-menu__dropdown .container {
      width: 100%; } }
  .sticky-menu__dropdown .slick-dots {
    position: absolute;
    list-style: none;
    padding-left: 0;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%); }
    .sticky-menu__dropdown .slick-dots li {
      display: inline-block; }
      .sticky-menu__dropdown .slick-dots li.slick-active button {
        background: #00315d; }
    .sticky-menu__dropdown .slick-dots button {
      display: inline-block;
      background: transparent;
      border: 1px solid #666;
      border-radius: 50%;
      outline: 0;
      height: 19px;
      position: relative;
      width: 19px;
      margin-bottom: 7px;
      margin-right: 10px;
      font-size: 0;
      transition: background 300ms ease;
      cursor: pointer; }

.sticky-menu__tiles {
  width: calc(100% + 20px);
  transition: all 400ms ease;
  margin-left: -10px;
  margin-right: -10px; }
  .sticky-menu__tiles .slick-track {
    margin: auto;
    min-width: 0; }

.sticky-menu__tile {
  border: 1px solid #e0dfdf;
  margin: 10px; }
  .sticky-menu__tile__mention {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); }
  .sticky-menu__tile__media {
    font-size: 0;
    position: relative;
    display: block; }
    .sticky-menu__tile__media img {
      width: 100%;
      display: block; }
  .sticky-menu__tile__media--text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 25px;
    font-size: 16px; }
  .sticky-menu__tile__title--level-1 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase; }
  .sticky-menu__tile__title--level-2 {
    font-size: 35px;
    line-height: 1.2em;
    font-weight: 700;
    text-transform: uppercase; }
  .sticky-menu__tile__title--level-3 {
    font-size: 20px; }
  .sticky-menu__tile__details {
    padding: 8px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.sticky-menu__tile__legal-link {
  color: #888;
  text-decoration: underline;
  font-size: 12px; }

.slick-slider[data-theme="minimalist"] .product-tile .product-tile__addToWL {
  opacity: 0;
  transition: all 250ms ease;
  transform: translateY(10px); }
  @media all and (max-width: 768px) {
    .slick-slider[data-theme="minimalist"] .product-tile .product-tile__addToWL {
      opacity: 1;
      transform: translateY(0px); } }

.slick-slider[data-theme="minimalist"] .product-tile:hover .product-tile__addToWL {
  opacity: 1;
  transform: translateY(0px); }

.slick-slider[data-theme="minimalist"] .price-box {
  height: 55px;
  min-height: 55px; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__promoTag + .price-box__price {
    margin-top: 7px; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__price__amount {
    font-size: 18px; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__price__amount__decimal {
    font-size: 14px;
    left: -2px;
    width: auto; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__price__amount__integer {
    font-size: 1.2em; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__price__amount__currency {
    position: static;
    font-size: 1em;
    width: auto; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__price__amount__currency + .price-box__price__amount__integer {
    margin-left: -4px; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__price__spec {
    margin-left: 0; }
  .slick-slider[data-theme="minimalist"] .price-box .price-box__rebate {
    display: none; }

.workshop-projects {
  display: flex;
  flex-wrap: wrap;
  margin: -10px; }
  .workshop-projects .workshop-project {
    width: calc(25% - 20px);
    margin: 10px; }
    @media all and (max-width: 991px) {
      .workshop-projects .workshop-project {
        width: calc(33.33% - 20px);
        margin: 10px; } }
    @media all and (max-width: 767px) {
      .workshop-projects .workshop-project {
        width: calc(50% - 20px);
        margin: 10px; } }

.workshop-project {
  min-height: 1px;
  background: #f3f3f3;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0);
  transition: all 300ms ease;
  margin: 10px 0;
  display: block; }
  .workshop-project:hover {
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.18); }
    .workshop-project:hover .workshop-project__image:before {
      opacity: 0.15; }
  .workshop-project--featured {
    flex: 1 0 auto; }
    .workshop-project--featured .workshop-project__tag {
      opacity: 1; }
  .workshop-project__color-mosaic {
    flex: 0 0 44px;
    font-size: 0;
    margin: 0 15px 0 -1px; }
    .workshop-project__color-mosaic span {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 1px; }
  .workshop-project__image {
    display: block;
    margin: 0;
    position: relative;
    font-size: 0px; }
    .workshop-project__image:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: black;
      top: 0px;
      left: 0px;
      opacity: 0;
      transition: all 300ms ease; }
    .workshop-project__image .workshop-project__image__play,
    .workshop-project__image .related-article__image__play {
      fill: white;
      position: absolute;
      height: 70px;
      width: 70px;
      display: block;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      opacity: 0.9;
      transition: all 300ms ease; }
      @media screen and (min-width: 992px) and (max-width: 1300px) {
        .workshop-project__image .workshop-project__image__play,
        .workshop-project__image .related-article__image__play {
          height: 40px;
          width: 40px;
          display: block;
          top: calc(50% - 20px);
          left: calc(50% - 20px); } }
    .workshop-project__image .workshop-project__image__play {
      opacity: 0;
      transition: all 300ms ease;
      transition-delay: 200ms; }
  .workshop-project__tag {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 135px;
    opacity: 0;
    transition: all 300ms ease;
    transition-delay: 200ms; }
    .workshop-project__tag--attribute {
      height: 21px;
      z-index: 1;
      width: auto; }
    .workshop-project__tag--big {
      top: 20px;
      width: 200px; }
      @media screen and (max-width: 767px) {
        .workshop-project__tag--big {
          width: 135px;
          top: 10px; } }
      .workshop-project__tag--big.workshop-project__tag--attribute {
        height: 32px;
        width: auto; }
    @media screen and (max-width: 767px) {
      .workshop-project__tag {
        display: none !important; } }
  .workshop-project__duration {
    position: absolute;
    bottom: 10px;
    display: block;
    right: 20px;
    z-index: 3;
    font-size: 14px;
    color: white;
    background: rgba(0, 0, 0, 0.25);
    padding: 2px 5px;
    border-radius: 6px; }
  .workshop-project__title {
    color: black;
    flex: 1 1 auto;
    font-size: 17px;
    font-weight: normal;
    line-height: 1.4em;
    margin: 0;
    padding: 25px 20px;
    text-align: center;
    text-transform: none; }
    @media screen and (max-width: 767px) {
      .workshop-project__title {
        padding: 15px 10px;
        font-size: 15px; } }
  .workshop-project__title-container {
    display: flex;
    align-items: center; }

.productsModal__content {
  max-width: 1400px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column; }
  @media all and (max-width: 1199px) {
    .productsModal__content {
      overflow-y: auto; } }

.productsModal__wysiwyg {
  overflow-y: auto;
  height: 100%;
  color: black; }
  .productsModal__wysiwyg img {
    margin-bottom: 15px; }
  .productsModal__wysiwyg h2 {
    font-size: 22px;
    text-transform: none;
    color: #8c8c8c;
    font-weight: normal; }
  .productsModal__wysiwyg h3 {
    font-size: 18px;
    text-transform: none;
    color: #a5a5a5;
    margin-bottom: 0.5em;
    font-weight: normal; }
  .productsModal__wysiwyg p {
    line-height: 1.5em;
    font-size: 15px; }

.productsModal__products {
  display: flex;
  flex-wrap: wrap; }

.productsModal__product {
  border: 1px solid #f5f5f5;
  flex: 1 0 auto;
  padding: 10px;
  margin: 0 0 10px;
  color: black;
  transition: all 250ms ease; }
  .productsModal__product:hover {
    border-color: #e0e0e0;
    box-shadow: 0 0 10px rgba(0, 0, 5, 0.03); }
    @media all and (min-width: 1200px) {
      .productsModal__product:hover .overlayIconButton span {
        width: 150px;
        opacity: 1; } }
  .productsModal__product__description {
    line-height: 1.5em;
    font-size: 15px;
    margin-bottom: 0; }
  .productsModal__product__price {
    font-size: 18px;
    margin-top: 5px; }
  .productsModal__product__image {
    position: relative;
    font-size: 0;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    height: 240px;
    background-position: 50% 50%; }
    .productsModal__product__image--from-sku {
      background-size: contain;
      background-repeat: no-repeat; }
  .productsModal__product__more {
    text-decoration: underline; }
  .productsModal__product .overlayIconButton {
    display: none; }
    @media all and (max-width: 1199px) {
      .productsModal__product .overlayIconButton span {
        display: block;
        width: auto;
        padding: 0 15px 0 5px; } }

.productsModal__row {
  height: 100%; }
  @media all and (min-width: 1200px) {
    .productsModal__row {
      display: flex; } }

.productsModal__col {
  padding: 10px; }
  .productsModal__col:first-of-type {
    width: 45%; }
  .productsModal__col:last-of-type {
    width: 55%;
    display: flex;
    flex: 1;
    min-height: 0px; }
  @media all and (max-width: 1199px) {
    .productsModal__col:first-of-type, .productsModal__col:last-of-type {
      padding: 0 0 10px;
      width: 100%; } }

.overlayIconButton {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 1px solid #efefef;
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  font-size: 0;
  transition: all 300ms ease; }
  .overlayIconButton svg {
    width: 16px;
    height: 16px;
    fill: #8e8e8e;
    margin: 6px; }
  .overlayIconButton span {
    display: block;
    width: 0px;
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    color: #666;
    font-size: 14px;
    transition: all 250ms ease; }
    @media all and (max-width: 1199px) {
      .overlayIconButton span {
        font-size: 12px;
        opacity: 1;
        width: auto;
        padding-right: 10px; } }
    @media all and (max-width: 767px) {
      .overlayIconButton span {
        display: none; } }
  .overlayIconButton:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); }

.product-tile__badge {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 4px 12px;
  border-radius: 500px;
  font-size: 14px; }

.product-tile .product-tile__eco {
  background: #8DC63F;
  color: #fff; }

.product-tile .product-tile__great-buy {
  background: #2437FE;
  color: #F2FF61; }

.product-tile .product-tile__buy-in-bulk {
  background: #00315D;
  color: #fff; }

.product-tile .product-tile__pro-pick {
  background: #00315D;
  color: #F2FF61; }

.product-tile .product-tile__clearance {
  background: #DD1728;
  color: #F2FF61; }

/* BEGIN box-shadow */
/* END box-shadow */
.container--font-em {
  font-size: 10px; }
  @media screen and (max-width: 991px) {
    .container--font-em {
      font-size: 7px; } }

.background-color--main, .banner5050-left--main .banner5050-left, .banner5050-right--main .banner5050-right {
  background-color: #00315d;
  color: #ffffff; }

.background-color--secondary, .banner5050-left--secondary .banner5050-left, .banner5050-right--secondary .banner5050-right {
  background-color: #0073b9;
  color: #ffffff; }

.background-color--gray, .banner5050-left--gray .banner5050-left, .banner5050-right--gray .banner5050-right {
  background-color: #f6f6f6; }

.rebateTag-wrapper {
  position: absolute; }

.rebateTag {
  background-color: #e82535;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-flow: column;
  font-family: "Roboto", sans-serif;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 15px 20px 15px 15px;
  position: relative;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .rebateTag {
      padding: 10px 15px 10px 10px; } }
  .rebateTag--XONY .rebateTag__text--before {
    align-self: center;
    text-align: center; }
  .rebateTag--XONY .rebateTag__number {
    margin-top: 5px; }
  .rebateTag--XONY .rebateTag__number-text {
    font-size: 50px;
    display: flex;
    align-items: center; }
    .rebateTag--XONY .rebateTag__number-text__slash {
      position: relative;
      top: -3px; }
  .rebateTag--XONY .rebateTag__text--boxed {
    align-self: center;
    background: white;
    color: #e82535;
    font-size: 20px;
    line-height: 1;
    margin-left: 10px;
    padding: 6px 10px;
    text-transform: uppercase; }
  .rebateTag__number {
    display: flex;
    position: relative; }
  .rebateTag__number-sign {
    align-self: flex-start;
    flex: 0 0 auto;
    font-size: 24px;
    line-height: 1; }
  .rebateTag__number-symbol {
    position: absolute;
    top: 0;
    right: -10px; }
  .rebateTag__number-text {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    white-space: nowrap;
    line-height: 1;
    flex: 0 0 auto;
    font-size: 58px;
    line-height: 0.8; }
    .rebateTag__number-text--XFORY {
      align-items: center;
      display: flex;
      line-height: 1; }
      .rebateTag__number-text--XFORY span {
        display: inline-block;
        line-height: 1;
        margin: auto 5px; }
  .rebateTag__number-text--decimal {
    font-size: 25px;
    margin-top: 2px; }
  .rebateTag__number-regularPrice {
    align-self: flex-end;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin: auto 5px 0;
    text-decoration: line-through; }
  .rebateTag__after-number {
    display: flex;
    flex-flow: column;
    justify-content: space-between; }
  .rebateTag__text {
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    line-height: 1.2;
    font-weight: bold;
    align-self: flex-start; }
    .rebateTag__text--after {
      margin-left: 2px;
      margin-bottom: auto;
      margin-top: 2px; }
  .rebateTag__description {
    background: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    color: black;
    font-size: 0.8em;
    font-weight: bold;
    left: 0;
    padding: 0.8em;
    position: absolute;
    top: 100%;
    text-transform: none;
    width: 100%; }
    @media screen and (max-width: 450px) {
      .rebateTag__description {
        font-size: 0.69em; } }

.rebateTagNew {
  align-items: center;
  background: #e82535;
  bottom: 10px;
  color: white;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  left: 0;
  line-height: 1;
  min-height: 46px;
  padding: 8px 10px 8px 10px;
  position: absolute;
  max-width: calc(100% - 10px);
  text-transform: uppercase; }
  @media screen and (max-width: 475px) {
    .rebateTagNew {
      font-size: 11px; } }
  @media screen and (max-width: 374px) {
    .rebateTagNew {
      font-size: 10px; } }
  .rebateTagNew__text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 374px) {
      .rebateTagNew__text {
        font-size: 12px; } }
    .rebateTagNew__text b {
      font-family: "Roboto", sans-serif;
      font-size: 30px;
      margin: auto 5px; }
      .rebateTagNew__text b sup {
        font-size: 18px; }
    .rebateTagNew__text sup {
      text-transform: none; }
  .rebateTagNew__number {
    align-items: center;
    display: flex;
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    margin: auto 5px; }
  .rebateTagNew__number-regularPrice {
    align-self: flex-end;
    display: flex;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin: auto 5px 0;
    text-decoration: line-through; }
    .rebateTagNew__number-regularPrice .rebateTagNew__number-text--decimal {
      font-size: 10px; }
  .rebateTagNew__number-text--XFORY {
    align-items: center;
    display: flex;
    line-height: 1; }
    .rebateTagNew__number-text--XFORY span {
      display: inline-block;
      line-height: 1;
      margin: auto 5px; }
  .rebateTagNew__number-sign {
    align-self: flex-start;
    font-size: 18px; }
  .rebateTagNew__number-text--decimal {
    align-self: flex-start;
    font-size: 18px; }

.category_suggestion {
  margin: 3em 0;
  padding: 1em 0;
  font-size: 10px;
  background-color: #f6f6f6; }
  .category_suggestion--underlined {
    background-color: transparent;
    text-align: left; }
    .category_suggestion--underlined h2 {
      text-transform: none;
      font-family: "Roboto", sans-serif; }
    .category_suggestion--underlined .category_suggestion_header {
      margin-bottom: 30px; }
    .category_suggestion--underlined .category_suggestion_links {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      margin: 0 -10px; }
      .category_suggestion--underlined .category_suggestion_links > div {
        display: flex;
        flex: 0 0 auto;
        padding: 10px;
        width: 33.33333%; }
        @media screen and (max-width: 991px) {
          .category_suggestion--underlined .category_suggestion_links > div {
            width: 50%; } }
        @media screen and (max-width: 767px) {
          .category_suggestion--underlined .category_suggestion_links > div {
            width: 100%; } }
        .category_suggestion--underlined .category_suggestion_links > div .button {
          background: none;
          border: none;
          border-bottom: 2px solid #ccc;
          color: #00315d;
          display: block;
          flex: 1 0 auto;
          font-size: 18px;
          font-weight: bold;
          padding: 0 0 25px 0;
          transition: border-color 300ms ease;
          text-align: left;
          text-transform: none;
          width: 100%; }
          .category_suggestion--underlined .category_suggestion_links > div .button:hover {
            border-bottom: 2px solid #00315d; }

.category_suggestion h2 {
  font-size: 2.4em;
  margin-bottom: 0; }

.category_suggestion p {
  font-size: 2.4em;
  font-weight: normal;
  margin-top: 0; }

.category_suggestion:not(.category_suggestion--underlined) .category_suggestion_links {
  display: flex;
  flex-flow: row wrap;
  flex: 4;
  justify-content: space-between;
  align-content: space-between; }
  .category_suggestion:not(.category_suggestion--underlined) .category_suggestion_links > div {
    flex: 1 0 auto;
    width: 25%;
    padding: 0.5em; }
    @media screen and (max-width: 991px) {
      .category_suggestion:not(.category_suggestion--underlined) .category_suggestion_links > div {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .category_suggestion:not(.category_suggestion--underlined) .category_suggestion_links > div {
        width: 100%; } }
  .category_suggestion:not(.category_suggestion--underlined) .category_suggestion_links .button {
    color: #000;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    width: 100%; }

.button {
  font-size: 14px; }
  .button.button-marginTop {
    margin-top: 20px; }
  .button--float-right {
    align-self: flex-end;
    justify-self: flex-end;
    float: right; }
  .button--flex-align-right {
    margin-left: auto; }
  .button--flex-align-center {
    margin-left: auto;
    margin-right: auto; }
  .button--lightblue {
    background-color: #2437fe;
    color: #fff; }
    .button--lightblue:hover {
      background-color: #fff;
      color: #2437fe; }
  .button.btn-link {
    background: none;
    color: #0073b9;
    text-decoration: underline;
    text-transform: none; }
    .button.btn-link:hover {
      font-weight: bolder; }
  .button.button--link-text {
    background: none;
    font-weight: bold;
    text-decoration: none;
    text-transform: none; }
    .button.button--link-text:hover {
      font-weight: bolder;
      text-decoration: underline;
      background: none; }
  .button.button--link-black {
    background: none;
    color: #000000;
    font-weight: bold;
    text-decoration: none;
    text-transform: none; }
    .button.button--link-black:hover {
      color: #000000;
      font-weight: bolder;
      text-decoration: underline;
      background: none; }
  .button.button--install-primary {
    background: #0f2e5a;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase; }
    .button.button--install-primary:hover {
      background: #f5be32;
      color: #0f2e5a; }
  .button.button--install-secondary {
    background: #f5be32;
    color: #0f2e5a;
    text-transform: uppercase; }
    .button.button--install-secondary:hover {
      background: #0f2e5a;
      color: #ffffff; }

.btn-link--install {
  background: none;
  color: yellow !important;
  text-decoration: underline;
  text-transform: none; }
  .btn-link--install:hover {
    font-weight: bolder; }

.common-header--m2 {
  margin-bottom: 2em; }

.common-card {
  align-content: center;
  align-items: center;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: space-between;
  margin: 10px;
  min-width: 1px;
  text-decoration: none;
  transition: all 200ms ease; }
  .common-card[href]:hover {
    border: 1px solid #ddd !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .common-card--no-border, .common-card--no-border .common-card {
    border: none;
    box-shadow: none; }
    .common-card--no-border .common-card:hover, .common-card--no-border .common-card .common-card:hover {
      border: none !important;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2); }
  .common-card--round-corner {
    border-radius: 10px; }
  @media screen and (max-width: 767px) {
    .common-card--hide-image-mobile .common-card__icon {
      display: none; }
    .common-card--hide-description-mobile .common-card__description {
      display: none; } }
  .common-card--no-margin-text-card {
    margin: 0 0 20px; }
    .common-card--no-margin-text-card .common-card__description {
      align-self: flex-start;
      font-size: 18px;
      line-height: 1.2;
      text-align: left; }
  .common-card__wrapper {
    text-decoration: none;
    display: flex;
    width: auto;
    height: auto; }
  .common-card__title {
    align-self: flex-start;
    font-family: DIN Next LT Pro Medium, sans-serif;
    text-transform: uppercase; }
    .common-card__title h3,
    .common-card__title h2 {
      padding-left: 5px;
      padding-top: 5px;
      margin: 0.2em;
      font-size: 3.5em; }
    .common-card__title span {
      color: #00315d; }
  .common-card__image {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    position: relative;
    text-align: center;
    width: 100%;
    border: none; }
    body.notie .common-card__image {
      flex: 0 0 auto; }
    body:not(.notie) .common-card__image {
      min-height: 1px; }
    .common-card__image img {
      margin: 0;
      width: 100%; }
  .common-card__body {
    border-top: 1px solid #ddd;
    display: flex;
    flex: 1 0 auto;
    padding: 15px;
    justify-content: center;
    width: 100%; }
  .common-card__description {
    align-self: center;
    text-align: center;
    color: black; }
    .common-card__description span {
      font-weight: bolder;
      font-size: 1.55em; }
    .common-card__description p {
      font-size: 2.6em; }
  .common-card__cta {
    padding-bottom: 1.5em; }
    .common-card__cta svg {
      display: none; }
    .common-card__cta .button {
      font-size: 2.6em;
      padding: 5px 15px;
      margin-left: auto;
      margin-right: auto; }
    .common-card__cta--text-rebate {
      justify-content: space-between; }
      .common-card__cta--text-rebate .common-card__cta__text-rebate {
        color: inherit;
        font-size: 2em; }
  .common-card__icon {
    width: 100%; }
  .common-card__rebate {
    background: #fff10a;
    color: #000000;
    font-family: DIN Next LT Pro Bold, sans-serif;
    font-size: 3.6em;
    font-weight: bolder;
    left: 0px;
    min-height: 1.2em;
    min-width: 7em;
    padding: 2px 0;
    position: absolute;
    top: 10%; }

.common-card--vippro {
  background-color: #424449; }
  .common-card--vippro .common-card__title h3,
  .common-card--vippro .common-card__title h2 {
    color: #ffffff; }
  .common-card--vippro .common-card__title span {
    color: #fff10a; }
  .common-card--vippro .common-card__description {
    color: #ffffff; }
  .common-card--vippro .common-card__cta .button {
    background: #fff10a;
    color: #000000;
    font-size: 1.5em;
    padding: 15px 20px 10px; }

.common-card--install__blue-model {
  background: #617694;
  margin-left: 0;
  margin-right: 0;
  padding: 30px 10px;
  width: 100%; }
  .common-card--install__blue-model .common-card__body {
    display: block;
    border-top: none; }
    @media screen and (max-width: 767px) {
      .common-card--install__blue-model .common-card__body {
        padding: 0; } }
  .common-card--install__blue-model .common-card__description--title {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin: 1em 0;
    text-align: center; }
  .common-card--install__blue-model .common-card__description {
    color: #ffffff;
    font-size: 1.5em;
    line-height: 1.6em; }
  .common-card--install__blue-model .common-card__icon svg {
    width: 65px;
    height: 65px;
    margin: 0 auto;
    display: block; }
  .common-card--install__blue-model .common-card__cta {
    padding: 0; }
    .common-card--install__blue-model .common-card__cta .button {
      background: transparent;
      color: #ffffff;
      text-transform: inherit;
      text-decoration: underline;
      font-size: 13px;
      padding: 0;
      font-weight: normal; }

.common-card--pill-title .common-card__title h3,
.common-card--pill-title .common-card__title h2 {
  background: #fff10a;
  color: black;
  border-top-right-radius: 31px;
  border-bottom-right-radius: 31px;
  display: inline-block;
  height: 62px;
  line-height: 1;
  padding: 15px;
  padding-right: 35px;
  position: relative; }
  .common-card--pill-title .common-card__title h3 span,
  .common-card--pill-title .common-card__title h2 span {
    color: black;
    font-size: 24px; }

.common-card--border-top-cta .common-card__cta {
  border-top: #ddd 1px solid; }

.common-card--arrow-cta .common-card__body {
  padding: 10px;
  justify-content: flex-start; }
  .common-card--arrow-cta .common-card__body h4 {
    margin: 0;
    font-size: 2.4em;
    font-weight: normal; }

.common-card--arrow-cta .common-card__description--title {
  align-self: flex-start;
  justify-self: flex-start;
  color: #000000; }

.common-card--arrow-cta .common-card__cta {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-items: flex-start;
  align-items: center;
  padding: 1.25em 1em;
  flex: 1 0 auto; }
  .common-card--arrow-cta .common-card__cta .common-card__cta__icon-container {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 1.5em; }
  .common-card--arrow-cta .common-card__cta .common-card__cta__button-container {
    margin-right: 20px; }
  .common-card--arrow-cta .common-card__cta svg {
    display: inline-block;
    height: 1.5em;
    margin-left: 1em;
    width: 1em;
    flex: 0 0 auto;
    fill: #000000; }

.common-card--arrow-cta .button {
  background: none;
  background-color: unset;
  color: #000000;
  display: inline;
  font-size: 1.6em;
  font-weight: normal;
  margin: 0;
  max-width: 90%;
  padding: 0;
  text-transform: unset;
  flex: 0 0 auto;
  vertical-align: middle; }

.common-card--arrow-cta-main-color .common-card__cta svg {
  fill: #00315d; }
  .common-card--arrow-cta-main-color .common-card__cta svg:hover {
    background-color: transparent; }

.common-card--arrow-cta-main-color .button,
.common-card--arrow-cta-main-color .common-card__cta__text-rebate {
  color: #00315d; }

.common-card--arrow-cta-main-color .common-card:hover .common-card__cta {
  background-color: transparent; }
  .common-card--arrow-cta-main-color .common-card:hover .common-card__cta svg {
    fill: #000000; }
  .common-card--arrow-cta-main-color .common-card:hover .common-card__cta .button,
  .common-card--arrow-cta-main-color .common-card:hover .common-card__cta .common-card__cta__text-rebate {
    color: #000000; }

.common-card--title-text-button .common-card__body {
  border-top: none;
  flex-flow: column; }

.common-card--title-text-button .common-card__description--title {
  color: black;
  font-size: 18px;
  margin-top: 0; }

.common-card--title-text-button .common-card__description {
  align-self: flex-start;
  font-size: 14px;
  flex: 1 0 auto;
  text-align: left; }

.common-card--title-text-button .common-card__cta .button {
  font-size: 14px;
  padding: 12px 20px; }

.common-card--small-title .common-card__body {
  flex-flow: column;
  text-align: left;
  align-items: flex-start; }
  .common-card--small-title .common-card__body .common-card__description {
    align-self: flex-start;
    font-size: 14px;
    text-align: left; }
  .common-card--small-title .common-card__body .common-card__description--title {
    margin: 0.25em 0 0 0;
    font-size: 16px;
    color: #000000; }

.common-card--no-grow {
  margin: auto; }

.common-cards {
  font-size: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-content: space-between;
  margin: 20px -10px;
  width: calc(100% + 20px); }
  @media screen and (max-width: 767px) {
    .common-cards {
      font-size: 7px; } }
  @media all and (max-width: 500px) {
    .common-cards {
      flex-flow: column; } }
  @media all and (max-width: 500px) {
    .common-cards div.common-card, .common-cards a.common-card {
      width: calc(100% - 20px); } }
  @media all and (max-width: 991px) {
    .common-cards--multiline {
      flex-flow: row wrap; } }
  .common-cards--no-margin {
    margin: 0 -10px; }
  @media screen and (min-width: 501px) {
    .common-cards--card-max-width350 .common-card {
      max-width: 350px; } }
  .common-cards--card-max-width150 .common-card {
    max-width: 150px; }
  @media screen and (min-width: 501px) {
    .common-cards--card-max-width50pc .common-card {
      max-width: 50%; } }
  .common-cards--justify-content-center {
    justify-content: center; }
  .common-cards--1 {
    justify-content: center; }
  .common-cards--2 .common-card {
    width: calc(50% - 20px); }
  .common-cards--3 div.common-card, .common-cards--3 a.common-card, .common-cards--6 div.common-card, .common-cards--6 a.common-card {
    width: calc(33.33% - 20px); }
    @media all and (max-width: 991px) {
      .common-cards--3 div.common-card, .common-cards--3 a.common-card, .common-cards--6 div.common-card, .common-cards--6 a.common-card {
        width: calc(33.3% - 20px); } }
  @media all and (max-width: 500px) {
    .common-cards--3, .common-cards--6 {
      flex-flow: row wrap; } }
  .common-cards--4 {
    flex-flow: row wrap; }
    .common-cards--4 .common-card {
      width: calc(25% - 20px); }
      @media all and (max-width: 991px) {
        .common-cards--4 .common-card {
          width: calc(50% - 20px); } }
    @media all and (max-width: 500px) {
      .common-cards--4 {
        flex-flow: row wrap; } }
  .common-cards--5, .common-cards--10 {
    flex-flow: row wrap; }
    .common-cards--5 .common-card, .common-cards--10 .common-card {
      width: calc(20% - 20px); }
      @media all and (max-width: 991px) {
        .common-cards--5 .common-card, .common-cards--10 .common-card {
          width: calc(50% - 20px); } }
    @media all and (max-width: 500px) {
      .common-cards--5, .common-cards--10 {
        flex-flow: row wrap; } }
  .common-cards--8, .common-cards--16 {
    flex-flow: row wrap; }
    .common-cards--8 .common-card, .common-cards--16 .common-card {
      width: calc(12.5% - 20px); }
      @media all and (max-width: 991px) {
        .common-cards--8 .common-card, .common-cards--16 .common-card {
          width: calc(25% - 20px); } }
    @media all and (max-width: 500px) {
      .common-cards--8, .common-cards--16 {
        flex-flow: row wrap; } }
  .common-cards--text-center {
    text-align: center; }

.common-categoryselector {
  display: flex;
  align-content: center;
  align-items: center;
  background: #f2f2f2;
  flex-direction: row;
  flex-flow: row;
  justify-content: space-between;
  padding: 20px 10px;
  min-width: 1px;
  text-decoration: none;
  flex-wrap: wrap;
  /** Added custom class **/ }
  @media screen and (max-width: 991px) {
    .common-categoryselector {
      padding: 10px 5px; } }
  .common-categoryselector > * {
    flex: 1 1 0;
    align-self: stretch; }
    @media screen and (max-width: 991px) {
      .common-categoryselector > * {
        flex: 1 1 auto;
        margin: 5px !important;
        width: calc(50% - 10px);
        align-items: center !important; } }
  .common-categoryselector h2 {
    text-align: center;
    font-size: 2.4em;
    line-height: 26px;
    text-transform: initial; }
    @media screen and (max-width: 991px) {
      .common-categoryselector h2 {
        font-size: 28px;
        line-height: 30px; } }
  @media screen and (min-width: 992px) {
    .common-categoryselector.common-categoryselector--4 .common-header__title {
      min-width: 300px; } }
  .common-categoryselector .common-header__title {
    align-self: center; }
    @media screen and (min-width: 992px) {
      .common-categoryselector .common-header__title {
        min-width: 200px; } }
    @media screen and (max-width: 991px) {
      .common-categoryselector .common-header__title {
        flex: 100%; } }
  .common-categoryselector a.common-card {
    border: 4px solid #ffffff;
    display: flex;
    align-items: start;
    padding: 6px;
    margin: 0 10px;
    justify-content: flex-start; }
    @media screen and (max-width: 991px) {
      .common-categoryselector a.common-card {
        border: none;
        flex-flow: row;
        justify-content: left; } }
    .common-categoryselector a.common-card .common-card__text {
      margin: 0px; }
      .common-categoryselector a.common-card .common-card__text span {
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        font-weight: bold; }
        @media screen and (max-width: 991px) {
          .common-categoryselector a.common-card .common-card__text span {
            font-size: 13px;
            line-height: 18px; } }
    .common-categoryselector a.common-card:hover {
      border: 4px solid #0073b9 !important;
      box-shadow: none; }
      @media screen and (max-width: 991px) {
        .common-categoryselector a.common-card:hover {
          border: none !important; } }
    .common-categoryselector a.common-card .common-card__image {
      max-width: 150px;
      margin: 0 auto 10px auto; }
      @media screen and (max-width: 991px) {
        .common-categoryselector a.common-card .common-card__image {
          max-width: 60px;
          margin: 0 5px 0 0; } }
      @media screen and (max-width: 350px) {
        .common-categoryselector a.common-card .common-card__image {
          max-width: 40px;
          margin: 0; } }
  .common-categoryselector--margin-bottom {
    margin-bottom: 40px; }

.special-offer__title {
  background-color: #00315d; }
  .special-offer__title h2 {
    border-bottom: #ffdd00 solid 1px; }
  .special-offer__title__image .special-offer-tag {
    fill: #0073b9; }

.special-offer__rebate {
  background-color: #ffdd00;
  color: #00315d; }
  .special-offer__rebate p {
    color: #ffffff; }
  .special-offer__rebate:after {
    right: calc(-1.5em);
    top: calc(50% - 1.5em);
    border-left: 1.5em solid #ffdd00;
    border-bottom: 1.5em solid transparent;
    border-top: 1.5em solid transparent; }

.special-offer__stores {
  border: #ffdd00 solid 1px; }
  .special-offer__stores .location-pin {
    fill: #ffdd00; }

.special-offer__section {
  margin: 1em 0;
  display: flex;
  flex-flow: row wrap;
  font-size: 10px; }
  @media screen and (max-width: 991px) {
    .special-offer__section {
      font-size: 7px; } }

.special-offer__title {
  text-align: center;
  padding: 2em;
  z-index: 1;
  background-color: #00315d;
  flex: 1 0 100%; }
  .special-offer__title h2 {
    display: inline-block;
    font-size: 2em;
    color: #ffffff !important;
    padding-bottom: 0.5em;
    border-bottom: #0073b9 solid 1px; }
  .special-offer__title__image {
    width: 100%;
    margin-bottom: -7em;
    padding: 0 20em;
    z-index: 2; }
    @media screen and (max-width: 991px) {
      .special-offer__title__image {
        padding: 0 10em; } }
    @media screen and (max-width: 575.98px) {
      .special-offer__title__image {
        padding: 0 5em;
        margin-bottom: -4em; } }

.special-offer__rebate {
  background-color: #0073b9;
  color: #00315d;
  display: flex;
  flex-flow: column;
  flex: 1 0 50%;
  width: 50%;
  padding: 4em;
  position: relative; }
  @media screen and (max-width: 991px) {
    .special-offer__rebate {
      padding: 4em 2em 2em 2em;
      flex: 1 0 100%;
      width: 100%; } }
  .special-offer__rebate__tag {
    align-content: center;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 963.312 310.12' style='fill:%23ffffff'><path d='M506.174,1278.73H576.09v16.41H506.174v-16.41Zm893.436,0h69.9v16.41h-69.9v-16.41ZM552.5,1131.89l59.567,36.46-8.627,13.98-59.567-36.46Zm870.678,310.15-59.57-36.46,8.63-13.98,59.57,36.46Zm-876.908-9.93-8.035-14.32,61.008-33.99,8.036,14.32Zm891.178-275.95-61.04,33.96-8.03-14.34,61.03-33.96Z' transform='translate(-506.188 -1131.91)'/></svg>");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-flow: row;
    flex: 1 0 1em;
    font-size: 20em;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    height: 1em;
    line-height: 1;
    padding-top: 0.1em;
    justify-content: center; }
    @media screen and (max-width: 1199.98px) {
      .special-offer__rebate__tag {
        font-size: 17em; } }
    @media screen and (max-width: 991.98px) {
      .special-offer__rebate__tag {
        font-size: 15em; } }
    @media screen and (max-width: 767.98px) {
      .special-offer__rebate__tag {
        font-size: 12em; } }
    @media screen and (max-width: 575.98px) {
      .special-offer__rebate__tag {
        font-size: 7em; } }
  .special-offer__rebate__value {
    font-size: 1em;
    flex: 0 0 1em;
    letter-spacing: -0.1em;
    padding-right: 0.1em; }
  .special-offer__rebate__nextoval {
    flex-flow: column;
    display: flex;
    flex: 0 0 auto; }
  .special-offer__rebate__nextoval--en {
    justify-content: center; }
    .special-offer__rebate__nextoval--en .special-offer__rebate__off {
      flex: 0 0 auto; }
  .special-offer__rebate__percent {
    font-size: 0.5em;
    align-self: flex-start;
    flex: 0 0 50%; }
  .special-offer__rebate__off {
    align-self: center;
    font-size: 0.2em;
    flex: 0 0 50%;
    padding-bottom: 0.5em; }
  .special-offer__rebate__text-after {
    font-size: 2em;
    flex: 1 0 0px;
    align-self: center;
    text-align: center; }
  .special-offer__rebate p {
    color: #ffffff;
    font-size: 2em;
    text-align: center; }
  .special-offer__rebate:after {
    content: "";
    position: absolute;
    right: calc(-1.5em);
    top: calc(50% - 1.5em);
    width: 0;
    height: 0;
    border-left: 1.5em solid #0073b9;
    border-bottom: 1.5em solid transparent;
    border-top: 1.5em solid transparent;
    clear: both; }
    @media screen and (max-width: 991px) {
      .special-offer__rebate:after {
        right: calc(50% - 1.5em);
        top: calc(100%);
        border-left: 1.5em solid transparent;
        border-bottom: none;
        border-top: 1.5em solid #0073b9;
        border-right: 1.5em solid transparent; } }

.special-offer__stores {
  border: #0073b9 solid 1px;
  flex: 1 0 50%;
  display: flex;
  padding: 2em;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .special-offer__stores .location-pin {
    height: 6em;
    fill: #0073b9; }
  .special-offer__stores h3 {
    padding-top: 1em;
    font-size: 1.6em; }
  .special-offer__stores h2 {
    font-size: 3.4em; }
  .special-offer__stores a {
    font-weight: bold;
    font-size: 1.6em; }
  .special-offer__stores svg {
    max-width: 100%; }

.container .section-header {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: start;
  margin-top: 2em;
  margin-bottom: 2em; }
  .container .section-header h1 {
    font-size: 3.3em;
    font-weight: bold;
    font-family: "Roboto", sans-serif; }
  .container .section-header h2 {
    font-size: 2.2em; }
  .container .section-header h3 {
    font-size: 1.6em; }
  .container .section-header h1,
  .container .section-header h2,
  .container .section-header h3,
  .container .section-header h4,
  .container .section-header h5,
  .container .section-header h6 {
    display: inline-block;
    background: #ffffff;
    z-index: 1;
    margin: 0;
    color: #000000;
    text-transform: none; }
  .container .section-header p {
    font-size: 1.8em;
    margin: 10px 0; }
  .container .section-header__container {
    display: flex;
    justify-content: start;
    position: relative;
    width: 100%;
    flex-flow: column; }
  .container .section-header__icon {
    padding-bottom: 1em; }

.container .section-header--center {
  align-items: center;
  display: flex; }
  .container .section-header--center .section-header__container {
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center; }
    .container .section-header--center .section-header__container h1,
    .container .section-header--center .section-header__container h2,
    .container .section-header--center .section-header__container h3,
    .container .section-header--center .section-header__container h4,
    .container .section-header--center .section-header__container h5,
    .container .section-header--center .section-header__container h6 {
      display: flex;
      margin: auto;
      max-width: 100%; }
    .container .section-header--center .section-header__container:after {
      top: 50%; }

.container .section-header--stroke .section-header__container:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 1px;
  width: 100%;
  border-top: #dddddd solid 2px; }

.container .section-header--main-color {
  fill: #00315d;
  border-color: #00315d; }

.container .section-header--short-content .section-header__container {
  max-width: 1000px; }

.container .section-header--margin2em {
  margin-top: 2em; }

.container .section-header--black {
  color: #000000;
  fill: #000000;
  border-color: #000000; }
  .container .section-header--black h1,
  .container .section-header--black h2,
  .container .section-header--black h3,
  .container .section-header--black h4,
  .container .section-header--black h5,
  .container .section-header--black h6 {
    color: #000000; }

.container .section-header--big-title {
  margin: 5em 0; }
  .container .section-header--big-title h1 {
    font-size: 4.5em; }
    @media screen and (max-width: 767px) {
      .container .section-header--big-title h1 {
        font-size: 4em; } }
  .container .section-header--big-title h2,
  .container .section-header--big-title h3,
  .container .section-header--big-title h4,
  .container .section-header--big-title h5,
  .container .section-header--big-title h6 {
    font-size: 4.5em; }
  .container .section-header--big-title p {
    color: black;
    font-size: 1.8em;
    font-weight: bold;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }

.container .section-header--icon-left {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-content: center; }
  .container .section-header--icon-left h1,
  .container .section-header--icon-left h2,
  .container .section-header--icon-left h3,
  .container .section-header--icon-left h4,
  .container .section-header--icon-left h5,
  .container .section-header--icon-left h6 {
    padding-left: 0; }
  .container .section-header--icon-left p {
    margin-left: 0; }
  .container .section-header--icon-left .section-header__icon {
    flex: 0 0 auto;
    width: 20%; }
  .container .section-header--icon-left picture {
    flex: 0 0 auto;
    width: 20%;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: 150px; }
    .container .section-header--icon-left picture .section-header__icon {
      width: 100px; }
  .container .section-header--icon-left .section-header__container {
    flex: 0 1 auto;
    width: auto;
    flex-flow: column;
    padding-left: 1em; }
    .container .section-header--icon-left .section-header__container:after {
      content: none; }

.section-menu {
  background: white;
  border-bottom: 1px solid black; }
  @media print {
    .section-menu {
      display: none; } }
  .section-menu .container {
    display: flex; }
    @media screen and (max-width: 767px) {
      .section-menu .container {
        padding: 0; } }
  .section-menu.section-menu--scrollable .section-menu__button {
    display: block; }
    @media screen and (max-width: 767px) {
      .section-menu.section-menu--scrollable .section-menu__button {
        display: none !important; } }
  .section-menu.section-menu--scrollable .section-menu__scroller-container .section-menu__scroller {
    padding: 5px;
    margin: 0 5px; }
  .section-menu.section-menu--fixed {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 50; }
  .section-menu__scroller {
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /*hide scroll start*/
    overflow-y: hidden;
    padding-bottom: 30px;
    margin-bottom: -30px;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    /*hide scroll end*/ }
    .section-menu__scroller::-webkit-scrollbar {
      width: 0px;
      height: 0px; }
  .section-menu__scroller-container {
    position: relative;
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    min-height: 46px;
    width: 100%;
    height: 46px;
    overflow: hidden; }
    .section-menu--scrollable .section-menu__scroller-container:after {
      content: "";
      height: 100%;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      width: 25px; }
  .section-menu__item {
    background: white;
    border: 0;
    color: black;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
    outline: none;
    padding: 8px 15px;
    position: relative;
    text-transform: uppercase;
    transition: background 300ms ease; }
    .section-menu__item--external {
      line-height: 2;
      text-decoration: none; }
    .section-menu__item:hover {
      background-color: #0073b9;
      color: white; }
    body.isTouchDevice .section-menu__item:hover {
      background: white;
      color: black; }
    .section-menu__item:after {
      background-color: #0073b9;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 3px;
      transition: width 300ms ease; }
      .scrollingUp .section-menu__item:after {
        left: 0;
        right: auto; }
    .section-menu__item.active:after {
      width: 100%;
      left: 0;
      right: auto; }
      .scrollingUp .section-menu__item.active:after {
        left: auto;
        right: 0; }
    @media screen and (max-width: 767px) {
      .section-menu__item {
        font-size: 11px;
        padding: 6px 12px; } }
  .section-menu__button {
    border: 0;
    background: white;
    display: none;
    flex: 0 0 28px;
    fill: #b2b2b2;
    width: 28px;
    height: 40px; }
    .section-menu__button svg {
      height: 10px;
      width: 15px; }
    .section-menu__button:hover {
      fill: black; }
    .section-menu__button--prev {
      border-right: 1px solid #e8e8e8; }
      .section-menu__button--prev svg {
        transform: rotate(90deg); }
    .section-menu__button--next {
      border-left: 1px solid #e8e8e8; }
      .section-menu__button--next svg {
        transform: rotate(-90deg); }
  .section-menu--spring-hub {
    border: none;
    margin: 30px 0; }
    .section-menu--spring-hub .container {
      background-color: #93e7af;
      align-items: center; }
    .section-menu--spring-hub .section-menu__button {
      display: none;
      background-color: #93e7af;
      border: #00315d solid 1px;
      fill: #00315d; }
      .section-menu--spring-hub .section-menu__button:hover {
        background-color: #00315d;
        fill: #707070; }
    .section-menu--spring-hub .section-menu-mobile-button__text {
      color: #00315d; }
    .section-menu--spring-hub .section-menu-mobile-button__burger span {
      background-color: #00315d; }
    .section-menu--spring-hub.section-menu--fixed {
      position: relative;
      width: 100%;
      z-index: 50; }
    .section-menu--spring-hub .section-menu__scroller-container {
      height: auto;
      margin: 0;
      background-color: #93e7af; }
      .section-menu--spring-hub .section-menu__scroller-container .section-menu__scroller {
        display: flex;
        align-content: center;
        justify-content: space-around;
        padding: 5px 30px;
        margin: 0 10px; }
        .section-menu--spring-hub .section-menu__scroller-container .section-menu__scroller :last-child {
          margin-right: 30px; }
    .section-menu--spring-hub.section-menu--scrollable .section-menu__scroller {
      justify-content: unset !important; }
    .section-menu--spring-hub .section-menu__item {
      background-color: #93e7af;
      color: #00315d;
      flex: 1 0 auto;
      text-align: center;
      padding: 15px;
      margin: 0 10px;
      text-decoration: none;
      display: flex;
      align-items: center;
      height: auto;
      justify-content: center; }
      @media screen and (min-width: 992px) {
        .section-menu--spring-hub .section-menu__item {
          max-width: 18%; } }
      @media screen and (max-width: 991px) {
        .section-menu--spring-hub .section-menu__item {
          border-bottom: 1px solid white; }
          .section-menu--spring-hub .section-menu__item:last-of-type {
            border-bottom: none; } }
      .section-menu--spring-hub .section-menu__item:hover, .section-menu--spring-hub .section-menu__item:focus, .section-menu--spring-hub .section-menu__item.active {
        background-color: #ffffff;
        color: #00315d;
        border: none; }
        .section-menu--spring-hub .section-menu__item:hover:after, .section-menu--spring-hub .section-menu__item:focus:after, .section-menu--spring-hub .section-menu__item.active:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 20px 0 20px;
          border-color: #93e7af transparent transparent transparent;
          bottom: -20px;
          transform: translateX(-50%);
          left: 50% !important;
          right: auto !important;
          background: transparent; }

@media screen and (max-width: 1199px) {
  .section-menu--mobile-burger .container {
    flex-flow: column; } }

@media screen and (min-width: 1200px) {
  .section-menu--mobile-burger .section-menu__scroller-container {
    display: block !important; } }

@media screen and (max-width: 1199px) {
  .section-menu--mobile-burger .section-menu__scroller-container {
    display: none;
    min-height: 0; }
    .section-menu--mobile-burger .section-menu__scroller-container .section-menu__scroller :last-child {
      margin: 0 10px; } }

@media screen and (max-width: 1199px) {
  .section-menu--mobile-burger .section-menu__scroller {
    flex-flow: column; } }

.section-menu-mobile-button {
  background-color: #93e7af;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 15px;
  width: 100%; }
  @media screen and (min-width: 1200px) {
    .section-menu-mobile-button {
      display: none; } }
  .section-menu-mobile-button__text {
    color: black;
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
    text-transform: uppercase; }
  .section-menu-mobile-button__burger {
    display: inline-block;
    height: 22px;
    font-size: 0;
    line-height: 0;
    margin-right: 8px;
    vertical-align: middle;
    width: 25px; }
    .section-menu-mobile-button__burger span {
      background-color: #000;
      display: block;
      height: 4px;
      margin-bottom: 5px;
      width: 100%;
      transition: color 250ms, background-color 250ms; }
      .section-menu-mobile-button__burger span:last-child {
        margin: 0; }

.section--gray {
  background-color: #f6f6f6; }
  .section--gray .section-header .section-header__container h1,
  .section--gray .section-header .section-header__container h2,
  .section--gray .section-header .section-header__container h3 {
    background-color: transparent; }

.section--main {
  background-color: #00315d; }
  .section--main .section-header .section-header__container h1,
  .section--main .section-header .section-header__container h2,
  .section--main .section-header .section-header__container h3 {
    background-color: transparent; }

.section--secondary {
  background-color: #0073b9; }
  .section--secondary .section-header .section-header__container h1,
  .section--secondary .section-header .section-header__container h2,
  .section--secondary .section-header .section-header__container h3 {
    background-color: transparent; }

.section--install-blue-opacity-90 {
  background-color: rgba(97, 118, 148, 0.9); }

.section--full-width-float {
  z-index: 1; }
  .section--full-width-float.section--margin-30top10bottom {
    margin: 5em 1em 0em; }

.section--full-width-float-background {
  position: absolute;
  width: 100vw;
  min-height: calc(100% + 30px);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: -1;
  max-width: 100vw; }

.section--margin-40top10bottom {
  margin: 4em 1em 0em; }

.common-section {
  position: relative; }

.banner {
  display: block;
  line-height: 1.4;
  position: relative;
  text-decoration: none; }
  .banner__top-text {
    background: #00315d;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 20px 15px;
    text-align: center; }
  .banner > .button {
    position: absolute;
    bottom: 15px;
    right: 15px; }
    .banner > .button--centered {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap; }
  .banner--tile img {
    display: block;
    width: 100%; }
  @media screen and (max-width: 767px) {
    .banner--tile .button {
      display: none; }
      .banner--tile .button.button-mobile {
        display: block !important; } }
  @media screen and (min-width: 768px) {
    .banner--button-right {
      display: flex; }
      .banner--button-right .buttons-container {
        flex: 0 0 auto;
        flex-flow: column;
        margin: 0 0 0 4px;
        padding: 0;
        width: 33.33333%; }
        .banner--button-right .buttons-container .button {
          width: 100%;
          margin: 0 0 4px 0; }
          .banner--button-right .buttons-container .button:last-of-type {
            margin: 0; } }
  .banner .button {
    background: #00315d;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    padding: 11px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 300ms ease; }
    @media screen and (max-width: 767px) {
      .banner .button {
        font-size: 13px;
        padding: 10px 15px; } }
    .banner .button:hover {
      color: #00315d;
      background: #0073b9; }
    .banner .button--white {
      background: white;
      color: #00315d; }
      .banner .button--white:hover {
        opacity: 0.9;
        background: white; }
    .banner .button--lightblue {
      background-color: #2437fe;
      color: #fff; }
      .banner .button--lightblue:hover {
        background-color: #fff;
        color: #2437fe; }
    .banner .button--white-border-grey {
      border: 4px solid #e7eded;
      background: white;
      color: #00315d; }
      .banner .button--white-border-grey:hover {
        background: #0073b9;
        color: #00315d; }
    .banner .button--white-border-black {
      border: 4px solid black;
      background: white;
      color: black; }
      .banner .button--white-border-black:hover {
        background: black;
        border: 4px solid #e82535;
        color: white; }
    .banner .button--tile6 {
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
      bottom: auto !important;
      text-align: center;
      width: 100%;
      text-transform: none;
      color: #000 !important;
      font-size: 12px;
      font-weight: normal;
      background: transparent !important;
      display: block !important; }

.banner-info {
  left: 0;
  max-width: 200px;
  position: absolute;
  top: 0;
  z-index: 1; }
  .banner-info__text {
    background: white;
    color: black;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    padding: 15px;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .banner-info__text {
        font-size: 13px;
        padding: 10px; } }

.banner-container {
  margin-bottom: 30px;
  position: relative; }
  .banner-container .button.hidden {
    display: none; }
  .banner-container .button--coupon {
    bottom: 60px;
    border: 2px dotted #ffffff;
    background: transparent; }
    @media screen and (min-width: 768px) {
      .banner-container .button--coupon {
        position: absolute;
        width: 28%;
        right: 15px;
        bottom: 60px;
        color: #ffffff; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .banner-container .button--coupon {
        bottom: 42px;
        color: #ffffff;
        width: 28%;
        font-size: 12px;
        padding: 7px 12px; } }
  .banner-container .button--coupon:hover {
    background: #ffffff;
    color: #00315d; }
  .banner-container .button--shop-all {
    background: #ffffff;
    color: #00315d; }
    @media screen and (max-width: 767px) {
      .banner-container .button--shop-all {
        background: #fff;
        color: #00315d;
        margin-top: 10px; } }
    @media screen and (min-width: 768px) {
      .banner-container .button--shop-all {
        position: absolute;
        width: 28%;
        bottom: 15px;
        right: 15px;
        background: #ffffff;
        color: #00315d; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .banner-container .button--shop-all {
        bottom: 10px;
        font-size: 12px;
        padding: 7px 12px;
        background: #ffffff;
        color: #00315d;
        width: 28%; } }
  .banner-container .button--shop-all:hover {
    background: #00315d;
    color: #ffffff; }

.banner-legal {
  color: black;
  display: block;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0;
  margin-left: auto;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none; }
  .banner-legal-text-rebate {
    position: absolute;
    bottom: 0px;
    left: 5px;
    margin-right: 140px; }
    @media screen and (max-width: 767px) {
      .banner-legal-text-rebate {
        bottom: 0px; } }

.banner-legal-container {
  display: flex; }

.banner-legal-text {
  font-size: 10px;
  padding: 5px 0; }

.banner--tile6:hover {
  box-shadow: 0 0 10px #000000;
  transition: all 250ms ease; }

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
  padding-top: 2px; }
  @media screen and (max-width: 767px) {
    .buttons-container {
      margin: 0;
      padding: 0; } }
  .buttons-container .button {
    flex: 1 0 auto;
    width: calc(25% - 4px);
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .buttons-container .button {
        width: 100%;
        margin: 2px 0 0; } }

.button-container--centered {
  text-align: center; }

.product-tile-rebate-container {
  padding-bottom: 30px;
  height: 100%;
  position: relative; }
  @media screen and (max-width: 767px) {
    .product-tile-rebate-container {
      padding-bottom: 20px; } }
  .product-tile-rebate-container .product-tile-rebate {
    margin-bottom: 0;
    height: 100%; }
  .product-tile-rebate-container .banner-legal-container {
    position: absolute;
    bottom: 6px;
    width: 100%;
    left: 0; }
  .product-tile-rebate-container picture {
    display: flex; }

.product-tile-rebate {
  background: white;
  border: 1px solid #dbdbdb;
  display: flex;
  line-height: 1.4;
  margin-bottom: 30px;
  text-decoration: none;
  position: relative; }
  .product-tile-rebate--small {
    margin-bottom: 15px; }
    .product-tile-rebate--small .product-tile-rebate__info {
      display: flex;
      width: 65%; }
    .product-tile-rebate--small .product-tile-rebate__info__text {
      align-items: center;
      display: flex; }
    .product-tile-rebate--small .product-tile-rebate__image-container {
      width: auto;
      margin-left: auto;
      margin-bottom: 0;
      flex: auto; }
    .product-tile-rebate--small .button {
      padding: 10px 15px; }
    .product-tile-rebate--small .banner-legal-text-rebate {
      left: 160px; }
  @media screen and (max-width: 767px) {
    .product-tile-rebate {
      margin-bottom: 10px; } }
  .product-tile-rebate .button {
    bottom: 15px;
    position: absolute;
    right: 15px; }
    @media screen and (max-width: 767px) {
      .product-tile-rebate .button {
        bottom: 10px;
        right: 10px; } }
  .product-tile-rebate--oe {
    border: none; }
    .product-tile-rebate--oe .product-tile-rebate-new__info__text:after {
      content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill: %231b636f;' data-name='icon-arrow-right' viewBox='0 0 257.33 171.57'%3E%3Cpath d='M254.48,121.56h.58q18.21,18.66,36.44,37.31c.66.68,1.44,1.25,2.16,1.87v.58c-.78.72-1.59,1.4-2.33,2.16q-62,63.48-123.92,127a17.43,17.43,0,0,0-1.79,2.65l-1.24,0a18,18,0,0,0-1.8-2.64Q100.75,227.06,38.88,163.7c-.81-.83-1.69-1.58-2.54-2.37q0-.29,0-.58c.72-.62,1.5-1.19,2.16-1.87q18.23-18.65,36.44-37.32h.58a20.2,20.2,0,0,0,1.75,2.19q42.84,40.79,85.71,81.54c.62.59,1.28,1.14,2,1.8.53-.45,1-.8,1.39-1.19q43.39-41.27,86.76-82.56A12.82,12.82,0,0,0,254.48,121.56Z' transform='translate(-36.33 -121.56)'/%3E%3C/svg%3E"); }
    .product-tile-rebate--oe .product-tile-rebate-new__info {
      background: #9dcacf;
      border-top: none;
      color: #00315d; }
    .product-tile-rebate--oe .rebateTagNew {
      background: #00315d;
      color: #fff10a; }
    .product-tile-rebate--oe .product-tile-rebate-new__image-container {
      background: #0073b9; }

.product-tile-rebate__info {
  flex: 0 0 auto;
  width: 162px; }
  .product-tile-rebate__info__text {
    color: black;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.2;
    padding: 15px;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .product-tile-rebate__info__text {
        padding: 10px; } }

.product-tile-rebate__image-container {
  flex: 1 1 auto;
  margin-bottom: 30px; }
  .product-tile-rebate__image-container img {
    display: block;
    width: 100%; }

.product-tile-rebate--b:after {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s ease;
  top: 0;
  width: 100%; }

.product-tile-rebate--b:hover:after {
  opacity: 1; }

.product-tile-rebate--b .button {
  display: none; }

.product-tile-rebate--b .product-tile-rebate__image-container {
  margin-bottom: 0px; }

.product-tile-rebate--b .product-tile-rebate__info__text:after {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill: %23000000;' data-name='icon-arrow-right' viewBox='0 0 257.33 171.57'%3E%3Cpath d='M254.48,121.56h.58q18.21,18.66,36.44,37.31c.66.68,1.44,1.25,2.16,1.87v.58c-.78.72-1.59,1.4-2.33,2.16q-62,63.48-123.92,127a17.43,17.43,0,0,0-1.79,2.65l-1.24,0a18,18,0,0,0-1.8-2.64Q100.75,227.06,38.88,163.7c-.81-.83-1.69-1.58-2.54-2.37q0-.29,0-.58c.72-.62,1.5-1.19,2.16-1.87q18.23-18.65,36.44-37.32h.58a20.2,20.2,0,0,0,1.75,2.19q42.84,40.79,85.71,81.54c.62.59,1.28,1.14,2,1.8.53-.45,1-.8,1.39-1.19q43.39-41.27,86.76-82.56A12.82,12.82,0,0,0,254.48,121.56Z' transform='translate(-36.33 -121.56)'/%3E%3C/svg%3E");
  display: inline-block;
  font-size: 0;
  height: 5px;
  width: 9px;
  transform: rotate(-90deg);
  margin-left: 2px;
  position: relative;
  top: -1px; }

.product-tile-rebate-new {
  background: white;
  border: 1px solid #dfdfdf;
  color: black;
  display: flex;
  flex-flow: column;
  position: relative;
  text-decoration: none; }
  .product-tile-rebate-new:after {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s ease;
    top: 0;
    width: 100%; }
  .product-tile-rebate-new:hover:not(div):after {
    opacity: 1; }
  .product-tile-rebate-new__image-container {
    min-height: 1px;
    position: relative;
    flex: 1 0 auto;
    display: flex; }
  .product-tile-rebate-new__info {
    align-items: center;
    border-top: 1px solid #dfdfdf;
    display: flex;
    min-height: 60px; }
    .product-tile-rebate-new__info__text {
      flex: 1 1 auto;
      font-size: 14px;
      line-height: 1.2;
      padding-left: 10px; }
      @media screen and (max-width: 767px) {
        .product-tile-rebate-new__info__text {
          padding-right: 10px; }
          .product-tile-rebate-new__info__text:after {
            content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill: %23000000;' data-name='icon-arrow-right' viewBox='0 0 257.33 171.57'%3E%3Cpath d='M254.48,121.56h.58q18.21,18.66,36.44,37.31c.66.68,1.44,1.25,2.16,1.87v.58c-.78.72-1.59,1.4-2.33,2.16q-62,63.48-123.92,127a17.43,17.43,0,0,0-1.79,2.65l-1.24,0a18,18,0,0,0-1.8-2.64Q100.75,227.06,38.88,163.7c-.81-.83-1.69-1.58-2.54-2.37q0-.29,0-.58c.72-.62,1.5-1.19,2.16-1.87q18.23-18.65,36.44-37.32h.58a20.2,20.2,0,0,0,1.75,2.19q42.84,40.79,85.71,81.54c.62.59,1.28,1.14,2,1.8.53-.45,1-.8,1.39-1.19q43.39-41.27,86.76-82.56A12.82,12.82,0,0,0,254.48,121.56Z' transform='translate(-36.33 -121.56)'/%3E%3C/svg%3E");
            display: inline-block;
            font-size: 0;
            height: 6px;
            width: 10px;
            transform: rotate(-90deg);
            margin-left: 4px;
            position: relative;
            top: -1px; } }
    .product-tile-rebate-new__info .button {
      flex: 0 0 auto;
      margin: 10px; }
      @media screen and (max-width: 767px) {
        .product-tile-rebate-new__info .button {
          display: none; } }
  .product-tile-rebate-new__top-text {
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    background: #044954;
    color: #fff;
    font-size: 22px;
    padding: 20px 15px;
    text-align: center; }
    .product-tile-rebate-new__top-text span {
      color: #0073b9; }
      @media (min-width: 768px) and (max-width: 1199px) {
        .product-tile-rebate-new__top-text span {
          display: block; } }

@media screen and (max-width: 475px) {
  .col-xs-6 .product-tile-rebate-new__image-container {
    padding-bottom: 35px; } }

picture {
  width: 100%;
  display: flex;
  align-items: flex-start; }
  picture img {
    width: 100%; }

.picturelang--object-fit-cover img {
  height: 100%;
  object-fit: cover; }

.header-top-link {
  background: #00315d;
  max-height: 80px;
  overflow: hidden; }
  .header-top-link .slick-track {
    display: block !important; }
  .header-top-link .slick-slide {
    outline: none;
    margin: 0; }
  .header-top-link--bg-blue {
    background: #00315d; }
  .header-top-link--bg-red {
    background: #dd0000; }

.HeaderTopLink__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px; }

.HeaderTopLink__icon {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  width: 30px;
  flex: 0 0 auto; }

.HeaderTopLink__text,
.HeaderTopLink a {
  flex: 0 1 auto;
  color: #ffffff;
  font-size: 15px; }

.HeaderTopLink img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px; }

.HeaderTopLink span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1; }

.HeaderTopLink .container {
  padding: 8px; }

.js-header-top-link.header-top-link.slick-initialized {
  background: #00315d; }

.header-top-link--topHeader.header-top-link {
  background-color: #ededed !important; }

.header-top-link--topHeader .HeaderTopLink,
.header-top-link--topHeader .HeaderTopLink a,
.header-top-link--topHeader .HeaderTopLink__text,
.header-top-link--topHeader .header__top__link,
.header-top-link--topHeader .header__top__link:hover {
  color: #00315c !important; }

@media screen and (max-width: 767px) {
  .header-top-link--bigText .HeaderTopLink__content {
    height: 36px; }
  .header-top-link--bigText .HeaderTopLink__text,
  .header-top-link--bigText .HeaderTopLink__text a {
    font-size: 12px; } }

.banner5050 {
  display: flex;
  flex-flow: row;
  margin: 1em 0;
  position: relative;
  font-size: 10px; }
  @media screen and (max-width: 991px) {
    .banner5050 {
      font-size: 7px; } }
  @media screen and (max-width: 767px) {
    .banner5050 {
      flex-flow: column; } }
  .banner5050--content-padding-bottom {
    padding-bottom: 50px; }
  .banner5050--content-light h3 {
    margin-top: 20px; }
    .banner5050--content-light h3 span {
      color: #98edb1;
      margin-top: 10px; }
  .banner5050--content-light p {
    color: #fff; }
  .banner5050--content-light .button {
    position: absolute;
    right: 20px;
    bottom: 20px; }
  .banner5050--content-light picture,
  .banner5050--content-light img {
    height: 100%; }
  @media screen and (max-width: 767px) {
    .banner5050--reverse-mobile .banner5050-right {
      order: -1; } }
  .banner5050--margin50 {
    margin: 5em 0; }

.banner5050-left {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 50%;
  flex: 1 1 auto; }
  @media screen and (max-width: 767px) {
    .banner5050-left {
      width: 100%;
      padding: 1em 0px; } }
  .banner5050-left > *:not(picture) {
    margin-left: 10px; }
  .banner5050-left p {
    font-size: 1.8em; }
  .banner5050-left h1 {
    margin-top: 1em;
    font-size: 3.6em; }
  .banner5050-left h2 {
    margin-top: 1em;
    font-size: 2.2em; }
  .banner5050-left .button {
    font-size: 1.4em; }
  .banner5050-left--content-center .banner5050-left {
    align-items: center;
    justify-content: center; }
  .banner5050-left--content-padding .banner5050-left {
    padding: 0 5em; }
    @media screen and (max-width: 767px) {
      .banner5050-left--content-padding .banner5050-left {
        padding: 1em 0px; } }

.banner5050-right {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 50%;
  flex: 1 1 auto; }
  @media screen and (max-width: 767px) {
    .banner5050-right {
      width: 100%;
      padding: 1em 0px; } }
  .banner5050-right > *:not(picture) {
    margin-left: 10px; }
  .banner5050-right p {
    font-size: 1.8em; }
  .banner5050-right h1 {
    margin-top: 1em;
    font-size: 3.6em; }
  .banner5050-right h2 {
    margin-top: 1em;
    font-size: 2.2em; }
  .banner5050-right .button {
    font-size: 1.4em; }
  .banner5050-right--content-center .banner5050-right {
    align-items: center;
    justify-content: center; }
  .banner5050-right--content-padding .banner5050-right {
    padding: 0 5em; }
    @media screen and (max-width: 767px) {
      .banner5050-right--content-padding .banner5050-right {
        padding: 1em 0px; } }

.common-mosaic {
  clear: both;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: space-between; }
  @media (min-width: 992px) {
    .common-mosaic .order-md-1 {
      order: 1; }
    .common-mosaic .order-md-2 {
      order: 2; }
    .common-mosaic .order-md-3 {
      order: 3; }
    .common-mosaic .order-md-4 {
      order: 4; }
    .common-mosaic .order-md-5 {
      order: 5; }
    .common-mosaic .order-md-6 {
      order: 6; }
    .common-mosaic .order-md-7 {
      order: 7; }
    .common-mosaic .order-md-8 {
      order: 8; }
    .common-mosaic .order-md-9 {
      order: 9; }
    .common-mosaic .order-md-10 {
      order: 10; }
    .common-mosaic .order-md-11 {
      order: 11; }
    .common-mosaic .order-md-12 {
      order: 12; }
    .common-mosaic .order-md-13 {
      order: 13; }
    .common-mosaic .order-md-14 {
      order: 14; }
    .common-mosaic .order-md-15 {
      order: 15; }
    .common-mosaic .order-md-16 {
      order: 16; }
    .common-mosaic .order-md-17 {
      order: 17; }
    .common-mosaic .order-md-18 {
      order: 18; }
    .common-mosaic .order-md-19 {
      order: 19; }
    .common-mosaic .order-md-20 {
      order: 20; }
    .common-mosaic .order-md-21 {
      order: 21; }
    .common-mosaic .order-md-22 {
      order: 22; }
    .common-mosaic .order-md-23 {
      order: 23; }
    .common-mosaic .order-md-24 {
      order: 24; } }
  @media (max-width: 767px) {
    .common-mosaic .order-xs-1 {
      order: 1; }
    .common-mosaic .order-xs-2 {
      order: 2; }
    .common-mosaic .order-xs-3 {
      order: 3; }
    .common-mosaic .order-xs-4 {
      order: 4; }
    .common-mosaic .order-xs-5 {
      order: 5; }
    .common-mosaic .order-xs-6 {
      order: 6; }
    .common-mosaic .order-xs-7 {
      order: 7; }
    .common-mosaic .order-xs-8 {
      order: 8; }
    .common-mosaic .order-xs-9 {
      order: 9; }
    .common-mosaic .order-xs-10 {
      order: 10; }
    .common-mosaic .order-xs-11 {
      order: 11; }
    .common-mosaic .order-xs-12 {
      order: 12; }
    .common-mosaic .order-xs-13 {
      order: 13; }
    .common-mosaic .order-xs-14 {
      order: 14; }
    .common-mosaic .order-xs-15 {
      order: 15; }
    .common-mosaic .order-xs-16 {
      order: 16; }
    .common-mosaic .order-xs-17 {
      order: 17; }
    .common-mosaic .order-xs-18 {
      order: 18; }
    .common-mosaic .order-xs-19 {
      order: 19; }
    .common-mosaic .order-xs-20 {
      order: 20; }
    .common-mosaic .order-xs-21 {
      order: 21; }
    .common-mosaic .order-xs-22 {
      order: 22; }
    .common-mosaic .order-xs-23 {
      order: 23; }
    .common-mosaic .order-xs-24 {
      order: 24; } }
  .common-mosaic--column {
    flex-flow: column;
    flex: 1 0; }
    .common-mosaic--column .col--grow-align-items-center {
      flex: 1 0 auto !important;
      align-items: center; }
  .common-mosaic--justify-content-center {
    justify-content: center; }
  .common-mosaic--margin50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .common-mosaic.common-mosaic--marginLeftRight0.row {
    margin-left: 0;
    margin-right: 0; }
  .common-mosaic--padding-top-50-bottom-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .common-mosaic.common-mosaic--padding0.row {
    margin-left: 0;
    margin-right: 0; }
    .common-mosaic.common-mosaic--padding0.row > [class*="col-"] {
      padding-left: 0;
      padding-right: 0; }
  .common-mosaic--padding30mobile15.row {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    @media screen and (max-width: 767px) {
      .common-mosaic--padding30mobile15.row {
        margin-left: -15px !important;
        margin-right: -15px !important; } }
    .common-mosaic--padding30mobile15.row > [class*="col-"] {
      padding-left: 30px;
      padding-right: 30px; }
      @media screen and (max-width: 767px) {
        .common-mosaic--padding30mobile15.row > [class*="col-"] {
          padding-left: 15px;
          padding-right: 15px; } }
  .common-mosaic .col--block {
    display: block !important; }
  .common-mosaic .col--flex-column {
    flex-flow: column; }
  .common-mosaic.row {
    margin-left: -10px;
    margin-right: -10px; }
  .common-mosaic > [class*="col-"] {
    display: flex;
    padding: 0 10px; }
    .common-mosaic > [class*="col-"] .mosaic-tile picture {
      flex: 1 0 auto;
      min-height: 1px; }

.standard-content-bg, .standard-content--bg-topo, .standard-content--bg-leaves, .standard-content--bg-light-leaves {
  color: white;
  padding: 3em; }
  .standard-content-bg .standard-content__title, .standard-content--bg-topo .standard-content__title, .standard-content--bg-leaves .standard-content__title, .standard-content--bg-light-leaves .standard-content__title {
    color: white;
    font-weight: bold;
    margin: 0; }
    .standard-content-bg .standard-content__title > span, .standard-content--bg-topo .standard-content__title > span, .standard-content--bg-leaves .standard-content__title > span, .standard-content--bg-light-leaves .standard-content__title > span {
      font-size: 5em !important; }
      @media screen and (max-width: 991px) {
        .standard-content-bg .standard-content__title > span, .standard-content--bg-topo .standard-content__title > span, .standard-content--bg-leaves .standard-content__title > span, .standard-content--bg-light-leaves .standard-content__title > span {
          font-size: 3em !important; } }
    @media screen and (max-width: 767px) {
      .standard-content-bg .standard-content__title, .standard-content--bg-topo .standard-content__title, .standard-content--bg-leaves .standard-content__title, .standard-content--bg-light-leaves .standard-content__title {
        align-self: center;
        text-align: center; }
        .standard-content-bg .standard-content__title > span, .standard-content--bg-topo .standard-content__title > span, .standard-content--bg-leaves .standard-content__title > span, .standard-content--bg-light-leaves .standard-content__title > span {
          font-size: 4em !important; } }

.standard-content {
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 10px;
  margin-bottom: 20px;
  width: 100%; }
  .standard-content .standard-content__title {
    font-size: inherit;
    line-height: 1.2;
    text-transform: none; }
  .standard-content h2.standard-content__title {
    margin-bottom: 20px; }
    .standard-content h2.standard-content__title > span {
      font-size: 3.2em; }
  .standard-content h3.standard-content__title {
    color: black;
    margin-bottom: 20px; }
    .standard-content h3.standard-content__title > span {
      font-size: 2.4em; }
  .standard-content .standard-content__text {
    font-size: inherit;
    line-height: 1.2;
    margin-bottom: 20px;
    max-width: 1000px; }
    .standard-content .standard-content__text > span {
      font-size: 1.8em; }
  .standard-content blockquote {
    font-size: 1.6em; }
  .standard-content .standard-content__image {
    margin-bottom: 20px; }
  .standard-content li {
    font-size: 1.6em;
    margin: 0.5em; }
  .standard-content--title-stroke .standard-content__title {
    position: relative;
    padding-bottom: 10px; }
    .standard-content--title-stroke .standard-content__title:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0px;
      height: 1px;
      width: 100%;
      border-top: #dddddd solid 2px; }
  .standard-content--title-small h2.standard-content__title > span {
    font-size: 2.4em; }
  .standard-content--title-small h3.standard-content__title > span {
    font-size: 2em !important; }
  .standard-content--big-title .standard-content__title > span {
    font-size: 4.5em !important; }
    @media screen and (max-width: 991px) {
      .standard-content--big-title .standard-content__title > span {
        font-size: 3.5em !important; } }
    @media screen and (max-width: 767px) {
      .standard-content--big-title .standard-content__title > span {
        font-size: 2.5em !important; } }
  .standard-content--no-margin {
    margin: 0; }
  .standard-content--bg-topo {
    background-image: url("//www.renodepot.com/documents/RD/specialpages/spring-hub//yard-care-bg-video.jpg"); }
    @media screen and (max-width: 767px) {
      .standard-content--bg-topo {
        background-image: url("//www.renodepot.com/documents/RD/specialpages/spring-hub//yard-care-bg-video-mobile.jpg"); } }
    .standard-content--bg-topo .standard-content__title > span span {
      color: #98edb1; }
  .standard-content--bg-leaves {
    background-image: url("//www.renodepot.com/documents/RD/specialpages/spring-hub//RD-bg-video-desktop.jpg"); }
    @media screen and (max-width: 767px) {
      .standard-content--bg-leaves {
        background-image: url("//www.renodepot.com/documents/RD/specialpages/spring-hub//RD-bg-video-mobile.jpg"); } }
  .standard-content--bg-light-leaves {
    background-image: url("//www.renodepot.com/documents/RD/specialpages/spring-hub//Lowes-bg-video-desktop.jpg");
    color: black; }
    @media screen and (max-width: 767px) {
      .standard-content--bg-light-leaves {
        background-image: url("//www.renodepot.com/documents/RD/specialpages/spring-hub//Lowes-bg-video-mobile.jpg"); } }
  .standard-content--spring-hub--font-highlight span {
    color: #ffffff;
    text-transform: none; }
  .standard-content--spring-hub--font-highlight span > span {
    font-size: 1em;
    color: #98edb1; }
  .standard-content--text-centered {
    background: #00305e;
    color: #98edb1;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px; }
    .standard-content--text-centered .button {
      margin-top: 20px; }
    @media screen and (max-width: 767px) {
      .standard-content--text-centered {
        padding: 30px; } }
    .standard-content--text-centered .standard-content__text {
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%; }
      .standard-content--text-centered .standard-content__text span {
        font-size: 2.2em; }
        .standard-content--text-centered .standard-content__text span > span {
          font-size: 1em; }
    .standard-content--text-centered .standard-content__title {
      color: #98edb1;
      font-size: 2.4em; }
  .standard-content--background-main-color {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    margin-bottom: 20px;
    background: #00315d;
    color: #ffffff; }
    .standard-content--background-main-color .button {
      background: #ffffff;
      color: #00315d; }
      .standard-content--background-main-color .button:hover {
        background: #00315d;
        color: #ffffff; }
  .standard-content--image-first {
    align-items: flex-start;
    display: flex;
    flex-flow: column; }
    .standard-content--image-first .standard-content__title,
    .standard-content--image-first .standard-content__text {
      width: 100%; }
    .standard-content--image-first .standard-content__image {
      order: -1;
      width: 100%;
      min-height: 1px; }
    .standard-content--image-first .button {
      margin-top: auto; }
  .standard-content--svg-first {
    align-items: flex-start;
    display: flex;
    flex-flow: column; }
    .standard-content--svg-first .standard-content__title,
    .standard-content--svg-first .standard-content__text {
      width: 100%; }
    .standard-content--svg-first .standard-content__svg {
      order: -1;
      width: 100%;
      min-height: 1px; }
    .standard-content--svg-first .button {
      margin-top: auto; }
  .standard-content--justify-center {
    justify-content: center; }
  .standard-content--step {
    margin-bottom: 8em; }
    .standard-content--step .standard-content__step-number {
      color: #00315d;
      font-size: 45px;
      margin-right: 5px; }
    .standard-content--step .standard-content__text {
      flex: 1 0 auto; }
  .standard-content--text-normal .standard-content__text {
    font-weight: normal; }
  .standard-content--flex-column-text-grow {
    display: flex;
    flex-flow: column; }
    .standard-content--flex-column-text-grow .standard-content__text {
      flex: 1 0 auto; }
  .standard-content--center-max-width-800 {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px; }
  .standard-content--text-align-center {
    text-align: center; }
    .standard-content--text-align-center .standard-content__text {
      margin-left: auto;
      margin-right: auto; }
  @media screen and (max-width: 767px) {
    .standard-content--text-align-center-xs {
      text-align: center; }
      .standard-content--text-align-center-xs .standard-content__text {
        margin-left: auto;
        margin-right: auto; }
      .standard-content--text-align-center-xs ul {
        text-align: left; } }
  .standard-content--align-self-center {
    align-self: center; }
  .standard-content--align-items-center {
    align-items: center;
    text-align: center; }
    .standard-content--align-items-center .standard-content__content-wrapper {
      align-items: center; }
  .standard-content--padding-left {
    padding-left: 50px; }
  .standard-content--padding-right {
    padding-right: 50px; }
  @media screen and (min-width: 768px) {
    .standard-content--padding-left-min-sm {
      padding-left: 30px; } }
  .standard-content-text--white {
    color: white; }
    .standard-content-text--white h1,
    .standard-content-text--white h2,
    .standard-content-text--white h3,
    .standard-content-text--white h4,
    .standard-content-text--white span {
      color: white; }

/** Generic Slider **/
.slick-slider-generic {
  margin: 0 -10px; }
  .slick-slider-generic .slick-slide {
    margin: 0 10px;
    position: relative; }
    .slick-slider-generic .slick-slide picture,
    .slick-slider-generic .slick-slide img {
      height: 100%;
      width: auto; }
  .slick-slider-generic .slick-arrow.slick-disabled {
    opacity: 0 !important; }
  .slick-slider-generic:hover .slick-arrow {
    opacity: 1; }
  .slick-slider-generic .slick-arrow {
    position: absolute;
    top: 70px;
    height: 40px;
    width: 35px;
    z-index: 3;
    opacity: 0;
    outline: 0;
    font-size: 0;
    background-position: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-size: 20px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .slick-slider-generic .slick-arrow.slick-next {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    right: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iI0ZGRkZGRiIvPgogIDwvZz4KPC9zdmc+Cg==); }
  .slick-slider-generic:hover .slick-arrow.slick-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-slider-generic .slick-arrow.slick-prev {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    left: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im04OC42LDEyMS4zYzAuOCwwLjggMS44LDEuMiAyLjksMS4yczIuMS0wLjQgMi45LTEuMmMxLjYtMS42IDEuNi00LjIgMC01LjhsLTUxLTUxIDUxLTUxYzEuNi0xLjYgMS42LTQuMiAwLTUuOHMtNC4yLTEuNi01LjgsMGwtNTQsNTMuOWMtMS42LDEuNi0xLjYsNC4yIDAsNS44bDU0LDUzLjl6IiBmaWxsPSIjRkZGRkZGIi8+CiAgPC9nPgo8L3N2Zz4K); }

/** Category Slider **/
.category-slider {
  margin: 0 -10px 0 -10px; }
  .category-slider .slick-track a:hover .common-card__image {
    border: 2px solid #0073b9; }
  .category-slider .slick-track > a.slick-slide {
    margin: 0 10px !important; }
    @media screen and (max-width: 767px) {
      .category-slider .slick-track > a.slick-slide {
        margin: 0 5px !important; } }
  @media screen and (max-width: 767px) {
    .category-slider .slick-track > div.slick-slide {
      margin: 0 6px !important; } }
  .category-slider .slick-slide {
    position: relative; }
    .category-slider .slick-slide picture,
    .category-slider .slick-slide img {
      height: 100%;
      width: auto; }
  .category-slider .slick-arrow.slick-disabled {
    opacity: 0 !important; }
  .category-slider:hover .slick-arrow {
    opacity: 1; }
  .category-slider .slick-arrow {
    position: absolute;
    top: 70px;
    height: 40px;
    width: 35px;
    z-index: 3;
    opacity: 0;
    outline: 0;
    font-size: 0;
    background-position: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-size: 20px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    @media screen and (max-width: 991px) {
      .category-slider .slick-arrow {
        opacity: 100 !important; } }
  .category-slider .slick-arrow.slick-next {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    right: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iI0ZGRkZGRiIvPgogIDwvZz4KPC9zdmc+Cg==); }
    @media screen and (max-width: 991px) {
      .category-slider .slick-arrow.slick-next {
        right: -10px !important; } }
  .category-slider:hover .slick-arrow.slick-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .category-slider .slick-arrow.slick-prev {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    left: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im04OC42LDEyMS4zYzAuOCwwLjggMS44LDEuMiAyLjksMS4yczIuMS0wLjQgMi45LTEuMmMxLjYtMS42IDEuNi00LjIgMC01LjhsLTUxLTUxIDUxLTUxYzEuNi0xLjYgMS42LTQuMiAwLTUuOHMtNC4yLTEuNi01LjgsMGwtNTQsNTMuOWMtMS42LDEuNi0xLjYsNC4yIDAsNS44bDU0LDUzLjl6IiBmaWxsPSIjRkZGRkZGIi8+CiAgPC9nPgo8L3N2Zz4K); }
    @media screen and (max-width: 991px) {
      .category-slider .slick-arrow.slick-prev {
        left: -10px !important; } }
  .category-slider .slick-list {
    padding: 0 !important;
    margin: 0 0px !important; }
    @media screen and (max-width: 767px) {
      .category-slider .slick-list {
        padding: 0 20% 0 0 !important; } }
  .category-slider .common-card {
    display: none;
    margin: 10px 5px; }
    .category-slider .common-card[href]:hover {
      border: none !important;
      box-shadow: none; }
    .category-slider .common-card:hover {
      border: none !important;
      box-shadow: none; }
    .category-slider .common-card .common-card__image {
      border: 2px solid #f2f2f2; }
    .category-slider .common-card .common-card__text span {
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .category-slider .common-card .common-card__text span {
          font-size: 13px;
          line-height: 18px; } }

@media screen and (max-width: 991px) {
  .container-category-slider {
    padding: 0 5px !important; } }

.container-category-slider .slick-arrow {
  background-color: #00315d !important; }
  .container-category-slider .slick-arrow:hover {
    background-color: rgba(0, 0, 0, 0.6); }

.normalized-fonts h1 {
  font-family: "Roboto", sans-serif;
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.3; }
  @media (min-width: 1200px) {
    .normalized-fonts h1 {
      font-size: 2.5rem; } }

.normalized-fonts h2 {
  font-family: "Roboto", sans-serif;
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 700;
  line-height: 1.071; }
  @media (min-width: 1200px) {
    .normalized-fonts h2 {
      font-size: 1.75rem; } }

.normalized-fonts h3 {
  font-family: "Roboto", sans-serif;
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 400;
  line-height: 1.364; }
  @media (min-width: 1200px) {
    .normalized-fonts h3 {
      font-size: 1.375rem; } }

.normalized-fonts h4 {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25; }

.normalized-fonts p,
.normalized-fonts span {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.357; }

.normalized-fonts b {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.357; }

.normalized-fonts small {
  font-family: "Roboto", sans-serif;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.182; }

.normalized-fonts .n-headline h1,
.normalized-fonts .n-headline h2,
.normalized-fonts .n-headline h3,
.normalized-fonts .n-headline h4,
.normalized-fonts .n-headline p,
.normalized-fonts .n-headline div,
.normalized-fonts .n-headline a,
.normalized-fonts .n-headline button,
.normalized-fonts .n-headline span {
  font-family: "Roboto", sans-serif;
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.3; }
  @media (min-width: 1200px) {
    .normalized-fonts .n-headline h1,
    .normalized-fonts .n-headline h2,
    .normalized-fonts .n-headline h3,
    .normalized-fonts .n-headline h4,
    .normalized-fonts .n-headline p,
    .normalized-fonts .n-headline div,
    .normalized-fonts .n-headline a,
    .normalized-fonts .n-headline button,
    .normalized-fonts .n-headline span {
      font-size: 2.5rem; } }

.normalized-fonts .n-title h1,
.normalized-fonts .n-title h2,
.normalized-fonts .n-title h3,
.normalized-fonts .n-title h4,
.normalized-fonts .n-title p,
.normalized-fonts .n-title div,
.normalized-fonts .n-title a,
.normalized-fonts .n-title button,
.normalized-fonts .n-title span {
  font-family: "Roboto", sans-serif;
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 700;
  line-height: 1.071; }
  @media (min-width: 1200px) {
    .normalized-fonts .n-title h1,
    .normalized-fonts .n-title h2,
    .normalized-fonts .n-title h3,
    .normalized-fonts .n-title h4,
    .normalized-fonts .n-title p,
    .normalized-fonts .n-title div,
    .normalized-fonts .n-title a,
    .normalized-fonts .n-title button,
    .normalized-fonts .n-title span {
      font-size: 1.75rem; } }

.normalized-fonts .n-section-title h1,
.normalized-fonts .n-section-title h2,
.normalized-fonts .n-section-title h3,
.normalized-fonts .n-section-title h4,
.normalized-fonts .n-section-title p,
.normalized-fonts .n-section-title div,
.normalized-fonts .n-section-title a,
.normalized-fonts .n-section-title button,
.normalized-fonts .n-section-title span {
  font-family: "Roboto", sans-serif;
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 400;
  line-height: 1.364; }
  @media (min-width: 1200px) {
    .normalized-fonts .n-section-title h1,
    .normalized-fonts .n-section-title h2,
    .normalized-fonts .n-section-title h3,
    .normalized-fonts .n-section-title h4,
    .normalized-fonts .n-section-title p,
    .normalized-fonts .n-section-title div,
    .normalized-fonts .n-section-title a,
    .normalized-fonts .n-section-title button,
    .normalized-fonts .n-section-title span {
      font-size: 1.375rem; } }

.normalized-fonts .n-subtitle h1,
.normalized-fonts .n-subtitle h2,
.normalized-fonts .n-subtitle h3,
.normalized-fonts .n-subtitle h4,
.normalized-fonts .n-subtitle p,
.normalized-fonts .n-subtitle div,
.normalized-fonts .n-subtitle a,
.normalized-fonts .n-subtitle button,
.normalized-fonts .n-subtitle span {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25; }

.normalized-fonts .n-body-strong h1,
.normalized-fonts .n-body-strong h2,
.normalized-fonts .n-body-strong h3,
.normalized-fonts .n-body-strong h4,
.normalized-fonts .n-body-strong p,
.normalized-fonts .n-body-strong div,
.normalized-fonts .n-body-strong a,
.normalized-fonts .n-body-strong button,
.normalized-fonts .n-body-strong span {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.357; }

.normalized-fonts .n-body h1,
.normalized-fonts .n-body h2,
.normalized-fonts .n-body h3,
.normalized-fonts .n-body h4,
.normalized-fonts .n-body p,
.normalized-fonts .n-body div,
.normalized-fonts .n-body a,
.normalized-fonts .n-body button,
.normalized-fonts .n-body span {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.357; }

.normalized-fonts .n-legal h1,
.normalized-fonts .n-legal h2,
.normalized-fonts .n-legal h3,
.normalized-fonts .n-legal h4,
.normalized-fonts .n-legal p,
.normalized-fonts .n-legal div,
.normalized-fonts .n-legal a,
.normalized-fonts .n-legal button,
.normalized-fonts .n-legal span {
  font-family: "Roboto", sans-serif;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.182; }

.special-offer__rebate__tag {
  padding-top: 0; }

.special-offer__title {
  background-color: #00315d; }
  .special-offer__title h2 {
    border-bottom: #0073b9 solid 1px; }
  .special-offer__title__image .special-offer-tag {
    fill: #ffdd00; }

.special-offer__rebate {
  background-color: #0073b9;
  color: #ffffff; }
  .special-offer__rebate p {
    color: #ffffff; }
  .special-offer__rebate:after {
    right: calc(-1.5em);
    top: calc(50% - 1.5em);
    border-left: 1.5em solid #0073b9;
    border-bottom: 1.5em solid transparent;
    border-top: 1.5em solid transparent; }

.special-offer__stores {
  border: #0073b9 solid 1px; }
  .special-offer__stores .location-pin {
    fill: #0073b9; }

.page-department h1.page__title {
  display: block;
  font-size: 2.2em; }

.espot-DepartmentBottomContent .dept-useful-info__title {
  margin-top: 40px;
  margin-bottom: 0;
  padding-top: 40px;
  border-top: 1px solid #ddd; }

.espot-DepartmentBottomContent .useful-info {
  padding-top: 0;
  border-top: 0; }

@media screen and (max-width: 991px) {
  .useful-info__item {
    padding-left: 70px !important; } }

.Suggestions .cio-item-SearchSuggestions {
  padding: 0 !important; }

.Suggestions .cio-suggestion-text {
  padding: 8px 0; }

.cio-results {
  display: none; }

.search-bar-active .cio-results {
  display: block; }

@media (max-width: 991px) {
  .sidebar__filters {
    z-index: 1000 !important; } }

#storeDetails.currentStore {
  width: auto;
  height: auto;
  font-style: inherit;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  font-family: Roboto, sans-serif; }

@media (max-width: 767px) {
  .cio-search-bar {
    padding: 5px 10px; } }

@media (max-width: 767px) {
  .cio-search-bar > div {
    max-width: none; } }

.cio-search-bar > div.search-bar-active {
  position: relative;
  z-index: 100;
  border-radius: 8px !important; }
  @media (max-width: 767px) {
    .cio-search-bar > div.search-bar-active {
      padding: 10px; } }

/*#productAddedToCartModal.modal-container {
  z-index: 1052;
}*/
.filter-container {
  position: sticky; }

@media (max-width: 767px) {
  .cio-search-bar > div.search-bar-active {
    padding: 16px !important;
    border-radius: 0 !important;
    background: White;
    bottom: 0; }
  .cio-search-bar > div.search-bar-active .cio-results {
    margin-left: -16px;
    border-radius: 0;
    margin-right: 0;
    box-shadow: none;
    max-height: 80%; } }
