/** Generic Slider **/
.slick-slider-generic {
  margin: 0 -10px;
  .slick-slide {
    margin: 0 10px;

    position: relative;
    picture,
    img {
      height: 100%;
      width: auto;
    }
  }
  .slick-arrow.slick-disabled {
    opacity: 0 !important;
  }
  &:hover .slick-arrow {
    opacity: 1;
  }
  .slick-arrow {
    position: absolute;
    top: 70px;
    height: 40px;
    width: 35px;
    z-index: 3;
    opacity: 0;
    outline: 0;
    font-size: 0;
    background-position: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-size: 20px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .slick-arrow.slick-next {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    right: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iI0ZGRkZGRiIvPgogIDwvZz4KPC9zdmc+Cg==);
  }
  &:hover .slick-arrow.slick-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-arrow.slick-prev {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    left: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im04OC42LDEyMS4zYzAuOCwwLjggMS44LDEuMiAyLjksMS4yczIuMS0wLjQgMi45LTEuMmMxLjYtMS42IDEuNi00LjIgMC01LjhsLTUxLTUxIDUxLTUxYzEuNi0xLjYgMS42LTQuMiAwLTUuOHMtNC4yLTEuNi01LjgsMGwtNTQsNTMuOWMtMS42LDEuNi0xLjYsNC4yIDAsNS44bDU0LDUzLjl6IiBmaWxsPSIjRkZGRkZGIi8+CiAgPC9nPgo8L3N2Zz4K);
  }
}

/** Category Slider **/
.category-slider {
  margin: 0 -10px 0 -10px;

  .slick-track a:hover .common-card__image {
    border: 2px solid $secondary-color;
  }

  //RONA
  .slick-track > a.slick-slide {
    margin: 0 10px !important;
    @media screen and (max-width: 767px) {
      margin: 0 5px !important;
    }
  }
  //RD
  .slick-track > div.slick-slide {
    @media screen and (max-width: 767px) {
      margin: 0 6px !important;
    }
  }

  .slick-slide {
    position: relative;
    picture,
    img {
      height: 100%;
      width: auto;
    }
  }
  .slick-arrow.slick-disabled {
    opacity: 0 !important;
  }
  &:hover .slick-arrow {
    opacity: 1;
  }
  .slick-arrow {
    position: absolute;
    top: 70px;
    height: 40px;
    width: 35px;
    z-index: 3;
    opacity: 0;
    outline: 0;
    font-size: 0;
    background-position: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-size: 20px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    @media screen and (max-width: 991px) {
      opacity: 100 !important;
    }
  }
  .slick-arrow.slick-next {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    right: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iI0ZGRkZGRiIvPgogIDwvZz4KPC9zdmc+Cg==);
    @media screen and (max-width: 991px) {
      right: -10px !important;
    }
  }
  &:hover .slick-arrow.slick-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-arrow.slick-prev {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    left: -20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im04OC42LDEyMS4zYzAuOCwwLjggMS44LDEuMiAyLjksMS4yczIuMS0wLjQgMi45LTEuMmMxLjYtMS42IDEuNi00LjIgMC01LjhsLTUxLTUxIDUxLTUxYzEuNi0xLjYgMS42LTQuMiAwLTUuOHMtNC4yLTEuNi01LjgsMGwtNTQsNTMuOWMtMS42LDEuNi0xLjYsNC4yIDAsNS44bDU0LDUzLjl6IiBmaWxsPSIjRkZGRkZGIi8+CiAgPC9nPgo8L3N2Zz4K);
    @media screen and (max-width: 991px) {
      left: -10px !important;
    }
  }

  //Card CSS
  .slick-list {
    padding: 0 !important;
    margin: 0 0px !important;

    @media screen and (max-width: 767px) {
      padding: 0 20% 0 0 !important;
    }
  }
  .common-card {
    display:none; //onPageLoad remove the fullscreen image flicker
    margin: 10px 5px;
    &[href]:hover {
      border: none !important;
      box-shadow: none;
    }
    &:hover {
      border: none !important;
      box-shadow: none;
    }
    .common-card__image {
      border: 2px solid #f2f2f2;
    }
    .common-card__text span {
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}
.container-category-slider {
  @media screen and (max-width: 991px) {
    padding: 0 5px !important;
  }
  .slick-arrow {
    background-color: $main-color !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}
