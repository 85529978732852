.collection_slider {
	padding: 10px 0 0;

	.slick-list {
		padding: 0 !important;
	}

	&:after,
	&:before {
		position: absolute;
		height: 100%;
		top: 0;
		width: 220px;
		z-index: 110;
		max-width: 20%;
		@media screen and (max-width: 990px) {
			content: "";
		}
	}

	&:before {
		left: 0;
		@media screen and (max-width: 990px) {
			background: linear-gradient(90deg, rgba(255, 255, 255, 1) 33%, rgba(255, 255, 255, 0.85) 66%, rgba(255, 255, 255, 0.6) 80%, transparent);
		}
	}

	&:after {
		right: 0;
		@media screen and (max-width: 990px) {
			background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 33%, rgba(255, 255, 255, 0.85) 66%, rgba(255, 255, 255, 0.6) 80%, transparent);
		}
	}

	img {
		width: 100%;
	}

	.slick-next,
	.slick-prev {
		display: none;
		background: url("//www.rona.ca/documents/rona/specialpages/2016/smart-home/assets/images/slider-arrow.png") center center/15px auto no-repeat;
		position: absolute;
		top: 50%;
		height: 42px;
		margin-top: 0;
		width: 29px;
		z-index: 120;
		transform: translateY(-50%);
		@media screen and (max-width: 767px) {
			height: 30px;
			width: 20px;
		}
	}

	.slick-prev {
		transform: rotate(180deg) translateY(50%);
		left: 25px;
	}

	.slick-next {
		right: 25px;
	}

	.swiper-slide {
		padding: 0 5px;

		a {
			display: block;
			position: relative;

			&:hover {
				h2:after {
					width: 100%;
				}
			}
		}

		h2 {
			bottom: 0;
			color: white;
			font-family: $condensed;
			font-size: 28px;
			font-weight: bold;
			left: 0;
			position: absolute;
			text-transform: uppercase;
			width: 100%;
			padding: 6px 12px;
			margin: 0;
			@media screen and (max-width: 767px) {
				font-size: 16px;
			}

			span {
				position: relative;
				z-index: 1;
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.7);
				height: 100%;
				width: 0;
				transition: width 300ms ease;
			}
		}
	}
}

.button-link {
	background: #fff;
	color: black;
	border: 1px solid #9d9f9c;
	padding: 10px 18px;
	display: inline-block;
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 1em;
	margin-top: 10px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	-webkit-transition: all 250ms ease;
	-moz-transition: all 250ms ease;
	-ms-transition: all 250ms ease;
	-o-transition: all 250ms ease;
	transition: all 250ms ease;

	&:hover {
		background: black;
		color: white;
	}

	&.brown {
		float: right;
		background: #605954;
		border: 2px solid #605954;
		color: white;
		font-family: "Roboto Condensed", sans-serif;
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0;

		&:hover {
			background: white;
			color: #605954;
		}
	}

	&.red {
		background: #e9251f;
		border: 1px solid #e9251f;
		color: white;
		margin-top: 15px;

		&:hover {
			background: white;
			color: black;
		}
	}
}

.bloctitle {
	position: absolute;
	bottom: -125px;
	left: 0;
	right: 0;
	// margin: 0 auto;
	text-align: center;

	h1 {
		color: #fff;
		font-size: 28px;
		text-transform: uppercase;
		font-family: "Roboto Condensed", sans-serif;
		line-height: 1;
		margin: 0 auto;
		padding: 20px 0;
		background: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/tag_center.png") center center repeat-x;
		position: relative;
		height: 70px;
		display: inline-block;

		&::after,
		&::before {
			content: "";
			background: url("https://www.rona.ca/documents/ronaResponsive/Dept/01-103/tag_end.png") center center repeat-x;
			height: 70px;
			width: 39px;
			position: absolute;
			top: 0;
			left: -39px;
			display: block;
		}

		&::after {
			transform: rotate(180deg);
			left: auto;
			right: -39px;
			top: -1px;
		}
	}

	p {
		font-size: 15px;
		line-height: 1.2;
		font-family: "Roboto Condensed", sans-serif;
		color: #827035;
		margin: 0;
	}
}
