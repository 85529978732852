.common-cards {
  font-size: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-content: space-between;
  margin: 20px -10px;
  width: calc(100% + 20px);
  @media screen and (max-width: 767px) {
    font-size: 7px;
  }

  @media all and (max-width: 500px) {
    flex-flow: column;
  }

  div.common-card,a.common-card {
    @media all and (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }
  &--multiline {
    @media all and (max-width: 991px) {
      flex-flow: row wrap;
    }
  }
  &--no-margin {
    margin: 0 -10px;
  }
  &--card-max-width350 {
    .common-card {
      @media screen and (min-width: 501px) {
        max-width: 350px;
      }
    }
  }
  &--card-max-width150 {
    .common-card {
        max-width: 150px;
    }
  }
  &--card-max-width50pc {
    .common-card {
      @media screen and (min-width: 501px) {
        max-width: 50%;
      }
    }
  }
  &--justify-content-center {
    justify-content: center;
  }
  &--1 {
    justify-content: center;
  }

  &--2 {
    .common-card {
      width: calc(50% - 20px);
    }
  }
  &--3, &--6 {
    div.common-card,a.common-card {
      width: calc(33.33% - 20px);
      @media all and (max-width: 991px) {
        width: calc(33.3% - 20px);
      }
    }
    @media all and (max-width: 500px) {
      flex-flow: row wrap;
    }
  }
  &--4 {
    flex-flow: row wrap;
    .common-card {
      width: calc(25% - 20px);
      @media all and (max-width: 991px) {
        width: calc(50% - 20px);
      }

    }
    @media all and (max-width: 500px) {
      flex-flow: row wrap;
    }
  }
  &--5,
  &--10 {
    flex-flow: row wrap;
    .common-card {
      width: calc(20% - 20px);
      @media all and (max-width: 991px) {
        width: calc(50% - 20px);
      }
    }
    @media all and (max-width: 500px) {
      flex-flow: row wrap;
    }
  }
  &--8,
  &--16 {
    flex-flow: row wrap;
    .common-card {
      width: calc(12.5% - 20px);
      @media all and (max-width: 991px) {
        width: calc(25% - 20px);
      }
    }
    @media all and (max-width: 500px) {
      flex-flow: row wrap;
    }
  }

  &--text-center {
    text-align: center;
  }
}
