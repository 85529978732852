.overlayIconButton {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 1px solid #efefef;
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  font-size: 0;
  transition: all 300ms ease;
  svg {
    width: 16px;
    height: 16px;
    fill: #8e8e8e;
    margin: 6px;
  }
  span {
    display: block;
    width: 0px;
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    color: #666;
    font-size: 14px;
    transition: all 250ms ease;
    @media all and (max-width: 1199px) {
      font-size: 12px;
      opacity: 1;
      width: auto;
      padding-right: 10px;
    }
    @media all and (max-width: 767px) {
      display: none;
    }
  }
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
}
