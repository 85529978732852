.category_suggestion {
  margin: 3em 0;
  padding: 1em 0;
  font-size: 10px;
  background-color: #f6f6f6;
  &--spring-hub {
  }

  &--underlined {
    background-color: transparent;
    text-align: left;
    h2 {
      text-transform: none;
      font-family: $font-normal;
    }
    .category_suggestion_header {
      margin-bottom: 30px;
    }
    .category_suggestion_links {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      margin: 0 -10px;
      > div {
        display: flex;
        flex: 0 0 auto;
        padding: 10px;
        width: 33.33333%;
        @media screen and (max-width: 991px) {
          width: 50%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .button {
          background: none;
          border: none;
          border-bottom: 2px solid #ccc;
          color: $main-color;
          display: block;
          flex: 1 0 auto;
          font-size: 18px;
          font-weight: bold;
          padding: 0 0 25px 0;
          transition: border-color 300ms ease;
          text-align: left;
          text-transform: none;
          width: 100%;
          &:hover {
            border-bottom: 2px solid $main-color;
          }
        }
      }
    }
  }
}

.category_suggestion {
  h2 {
    font-size: 2.4em;
    margin-bottom: 0;
  }

  p {
    font-size: 2.4em;
    font-weight: normal;
    margin-top: 0;
  }
  &:not(.category_suggestion--underlined) {
    .category_suggestion_links {
      display: flex;
      flex-flow: row wrap;
      flex: 4;
      justify-content: space-between;
      align-content: space-between;

      > div {
        flex: 1 0 auto;
        width: 25%;
        padding: 0.5em;

        @media screen and (max-width: 991px) {
          width: 50%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .button {
        color: #000;
        border: 1px solid #e0e0e0;
        background-color: #fff;
        width: 100%;
      }
    }
  }
}
