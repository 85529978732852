@mixin font($weight) {
  @if $weight == bold {
    @if $font-normal == ("Roboto", sans-serif) {
      // if font is from rona explicitly set font-weight: bold;
      font-family: $font-normal;
      font-weight: bold;
    } @else {
      font-family: $font-bold;
    }
  } @else if $weight == medium {
    @if $font-normal == ("Roboto", sans-serif) {
      // if font is from rona explicitly set font-weight: bold;
      font-family: $font-normal;
      font-weight: 500;
    } @else {
      font-family: $font-medium;
    }
  } @else if $weight == normal {
    font-family: $font-normal;
  } @else {
    @error "Unknown weight #{$weight}.";
  }
}

@mixin font-size-container {
  font-size: 10px;
  @media screen and (max-width: 991px) {
    font-size: 7px;
  }
}
