#homePage .layoutBuilder {
	margin-left: -10px !important;
	margin-right: -10px !important;
}

#homePage [class^="c_"] {
	// width: auto!important;
	float: left !important;
}

#homePage .layoutBuilder [class=c_1073874885], // Plain text
#homePage .layoutBuilder [class=c_1073874884], // something
#homePage .layoutBuilder [class=c_1073874840], // LT1A
#homePage .layoutBuilder [class=c_1073874879], // LT1B
#homePage .layoutBuilder [class=c_1073874846], // LT1C
#homePage .layoutBuilder [class=c_1073874856], // H0
#homePage .layoutBuilder [class=c_1073897835], // HT1A
#homePage .layoutBuilder [class=c_1073897836], // HT1B
#homePage .layoutBuilder [class=c_1073897837], // HT1C
#homePage .layoutBuilder [class=c_1073874864], // Carrousel
#homePage .layoutBuilder [class=c_1073874870], // H1
#homePage .layoutBuilder [class=c_1073874841], // H2
#homePage .layoutBuilder [class=c_1073874858], // R1
#homePage .layoutBuilder [class=c_1073864847],
#homePage .layoutBuilder [class=c_1073874875], // R1A
#homePage .layoutBuilder [class=c_1073874836], // R1B
#homePage .layoutBuilder [class=c_1073874868], // RT1A
#homePage .layoutBuilder [class=c_1073874851], // RT1B
#homePage .layoutBuilder [class=c_1073874872], // RT1C
#homePage .layoutBuilder [class=c_1073874852], // R2
#homePage .layoutBuilder [class=c_1073874869], // R2A
#homePage .layoutBuilder [class=c_1073874880], // R2B
#homePage .layoutBuilder [class=c_1073874855], // RT2A
#homePage .layoutBuilder [class=c_1073874861], // RT2B
#homePage .layoutBuilder [class=c_1073874828], // RT2C
#homePage .layoutBuilder [class=c_1073874876], // R3
#homePage .layoutBuilder [class=c_1073874845], // R3A
#homePage .layoutBuilder [class=c_1073874853], // R3B
#homePage .layoutBuilder [class=c_1073874865], // RT3A
#homePage .layoutBuilder [class=c_1073874831], // RT3B
#homePage .layoutBuilder [class=c_1073874830], // RT3C
#homePage .layoutBuilder [class=c_1073874832], // R4
#homePage .layoutBuilder [class=c_1073874849], // R4A
#homePage .layoutBuilder [class=c_1073874842], // R4B
#homePage .layoutBuilder [class=c_1073874866], // RT4A
#homePage .layoutBuilder [class=c_1073874834], // RT4B
#homePage .layoutBuilder [class=c_1073874835], // RT4C
#homePage .layoutBuilder [class=c_1073874881], // R5
#homePage .layoutBuilder [class=c_1073874860], // R5A
#homePage .layoutBuilder [class=c_1073874882], // R5B
#homePage .layoutBuilder [class=c_1073874873], // RT5A
#homePage .layoutBuilder [class=c_1073874847], // RT5B
#homePage .layoutBuilder [class=c_1073874862], // RT5C
#homePage .layoutBuilder [class=c_1073874859], // R6
#homePage .layoutBuilder [class=c_1073874871], // R6A
#homePage .layoutBuilder [class=c_1073874867], // R6B
#homePage .layoutBuilder [class=c_1073874874], // RT6A
#homePage .layoutBuilder [class=c_1073874829], // RT6B
#homePage .layoutBuilder [class=c_1073874854], // RT6C
#homePage .layoutBuilder [class=c_1073874844], // R7
#homePage .layoutBuilder [class=c_1073874839], // R7A
#homePage .layoutBuilder [class=c_1073874857], // R7B
#homePage .layoutBuilder [class=c_1073874838], // R8
#homePage .layoutBuilder [class=c_1073874877], // R8A
#homePage .layoutBuilder [class=c_1073874883], // R8B
#homePage .layoutBuilder [class=c_1073874878], // legal
#homePage .layoutBuilder [class=c_1073864831],
#homePage .layoutBuilder [class=c_1073864836],
#homePage .layoutBuilder [class=c_1073864841],
#homePage .layoutBuilder [class=c_1073864843],
#homePage .layoutBuilder [class=c_1073864844],
#homePage .layoutBuilder [class=c_1073864858],
 #homePage .layoutBuilder [class=c_1073893335], // RT7A
  #homePage .layoutBuilder [class=c_1073893333], // RT7B
  #homePage .layoutBuilder [class=c_1073893334],  // RT7C
    #homePage .layoutBuilder [class=c_1073893336],
    #homePage .layoutBuilder [class=c_1073893338],
    #homePage .layoutBuilder [class=c_1073893337] {
	position: relative;
	float: left !important;
	width: 100% !important;
	min-height: 0 !important;
	padding-left: 10px;
	padding-right: 10px;
	position: relative;

	// sets a sensible height to the promos as they are loading
	img[data-original][src*="data:image/"] {
		height: 305px;
		// min-height: calc(100% * 300 / 1280);
		// position: relative;
		// display: block;
		// background: #ddd;
	}
}

@media (min-width: 768px) {
	#homePage .layoutBuilder [class="c_1073864831"],
	#homePage .layoutBuilder [class="c_1073864836"],
	#homePage .layoutBuilder [class="c_1073864841"],
	#homePage .layoutBuilder [class="c_1073864844"] {
		float: left !important;
		width: 25% !important;
	}
}
@media (min-width: 768px) {
	#homePage .layoutBuilder [class=c_1073865826],
  #homePage .layoutBuilder [class=c_1073865827],
  #homePage .layoutBuilder [class=c_1073865825],
  #homePage .layoutBuilder [class=c_1073874868], // RT1A
  #homePage .layoutBuilder [class=c_1073874851], // RT1B
  #homePage .layoutBuilder [class=c_1073874872], // RT1C
  #homePage .layoutBuilder [class=c_1073874855], // RT2A
  #homePage .layoutBuilder [class=c_1073874861], // RT2B
  #homePage .layoutBuilder [class=c_1073874828], // RT2C
  #homePage .layoutBuilder [class=c_1073874865], // RT3A
  #homePage .layoutBuilder [class=c_1073874831], // RT3B
  #homePage .layoutBuilder [class=c_1073874830], // RT3C
  #homePage .layoutBuilder [class=c_1073874866], // RT4A
  #homePage .layoutBuilder [class=c_1073874834], // RT4B
  #homePage .layoutBuilder [class=c_1073874835], // RT4C
  #homePage .layoutBuilder [class=c_1073874873], // RT5A
  #homePage .layoutBuilder [class=c_1073874847], // RT5B
  #homePage .layoutBuilder [class=c_1073874862], // RT5C
  #homePage .layoutBuilder [class=c_1073874874], // RT6A
  #homePage .layoutBuilder [class=c_1073874829], // RT6B
  #homePage .layoutBuilder [class=c_1073874854],// RT6C
  #homePage .layoutBuilder [class=c_1073893335], // RT7A
  #homePage .layoutBuilder [class=c_1073893333], // RT7B
  #homePage .layoutBuilder [class=c_1073893334],  // RT7C
    #homePage .layoutBuilder [class=c_1073893336],
    #homePage .layoutBuilder [class=c_1073893338],
    #homePage .layoutBuilder [class=c_1073874840], // LT1A
#homePage .layoutBuilder [class=c_1073874879], // LT1B
#homePage .layoutBuilder [class=c_1073874846], // LT1C
#homePage .layoutBuilder [class=c_1073897835], // HT1A
#homePage .layoutBuilder [class=c_1073897836], // HT1B
#homePage .layoutBuilder [class=c_1073897837], // HT1C
    #homePage .layoutBuilder [class=c_1073893337] {
		float: left !important;
		width: 33.333333333% !important;

		// sets a sensible height to the promos as they are loading
		img[data-original][src*="data:image/"] {
			// min-height: calc(100% * 616 / 738);
			height: 305px;
		}
	}
}

@media (min-width: 768px) {
	#homePage .layoutBuilder [class=c_1073874875], // R1A
  #homePage .layoutBuilder [class=c_1073874836], // R1B
  #homePage .layoutBuilder [class=c_1073874869], // R2A
  #homePage .layoutBuilder [class=c_1073874880], // R2B
  #homePage .layoutBuilder [class=c_1073874845], // R3A
  #homePage .layoutBuilder [class=c_1073874853], // R3B
  #homePage .layoutBuilder [class=c_1073874849], // R4A
  #homePage .layoutBuilder [class=c_1073874842], // R4B
  #homePage .layoutBuilder [class=c_1073874860], // R5A
  #homePage .layoutBuilder [class=c_1073874882], // R5B
  #homePage .layoutBuilder [class=c_1073874871], // R6A
  #homePage .layoutBuilder [class=c_1073874867], // R6B
  #homePage .layoutBuilder [class=c_1073874839], // R7A
  #homePage .layoutBuilder [class=c_1073874857], // R7B
  #homePage .layoutBuilder [class=c_1073874877], // R8A
  #homePage .layoutBuilder [class=c_1073874883] {
		// R8B
		float: left !important;
		width: 50% !important;

		// sets a sensible height to the promos as they are loading
		img[data-original][src*="data:image/"] {
			// min-height: calc(100% * 357 / 748);
			height: 305px;
		}
	}
}
