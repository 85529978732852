.normalized-fonts {
  $font-sizes: "headline", "title", "section-title", "subtitle", "body-strong", "body", "legal";

  h1 {
    font-family: map-get(map-get($font-map, "headline"), "font");
    @include font-size(map-get(map-get($font-map, "headline"), "font-size"));
    font-weight: map-get(map-get($font-map, "headline"), "weight");
    line-height: map-get(map-get($font-map, "headline"), "line-height");
  }
  h2 {
    font-family: map-get(map-get($font-map, "title"), "font");
    @include font-size(map-get(map-get($font-map, "title"), "font-size"));
    font-weight: map-get(map-get($font-map, "title"), "weight");
    line-height: map-get(map-get($font-map, "title"), "line-height");
  }
  h3 {
    font-family: map-get(map-get($font-map, "section-title"), "font");
    @include font-size(map-get(map-get($font-map, "section-title"), "font-size"));
    font-weight: map-get(map-get($font-map, "section-title"), "weight");
    line-height: map-get(map-get($font-map, "section-title"), "line-height");
  }
  h4 {
    font-family: map-get(map-get($font-map, "subtitle"), "font");
    @include font-size(map-get(map-get($font-map, "subtitle"), "font-size"));
    font-weight: map-get(map-get($font-map, "subtitle"), "weight");
    line-height: map-get(map-get($font-map, "subtitle"), "line-height");
  }
  p,
  span {
    font-family: map-get(map-get($font-map, "body"), "font");
    @include font-size(map-get(map-get($font-map, "body"), "font-size"));
    font-weight: map-get(map-get($font-map, "body"), "weight");
    line-height: map-get(map-get($font-map, "body"), "line-height");
  }
  b {
    font-family: map-get(map-get($font-map, "body-strong"), "font");
    @include font-size(map-get(map-get($font-map, "body-strong"), "font-size"));
    font-weight: map-get(map-get($font-map, "body-strong"), "weight");
    line-height: map-get(map-get($font-map, "body-strong"), "line-height");
  }
  small {
    font-family: map-get(map-get($font-map, "legal"), "font");
    @include font-size(map-get(map-get($font-map, "legal"), "font-size"));
    font-weight: map-get(map-get($font-map, "legal"), "weight");
    line-height: map-get(map-get($font-map, "legal"), "line-height");
  }

  @each $font-size in $font-sizes {
    .n-#{$font-size} {
      h1,
      h2,
      h3,
      h4,
      p,
      div,
      a,
      button,
      span {
        @if not map-get($font-map, #{$font-size}) {
          @error "Font `#{$font-size}` not found in map $font-map";
        }
        font-family: map-get(map-get($font-map, #{$font-size}), "font");
        @include font-size(map-get(map-get($font-map, #{$font-size}), "font-size"));
        font-weight: map-get(map-get($font-map, #{$font-size}), "weight");
        line-height: map-get(map-get($font-map, #{$font-size}), "line-height");
      }
    }
  }
}
