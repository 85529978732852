.espot-DepartmentTopContent {
	display: block; // temp
}

.dept-inspiration {
	font-size: 10px;
	margin-bottom: 15px;
	margin-top: 15px;
	position: relative;
	@media screen and (max-width: 1199px) {
		font-size: 0.9vw;
	}
	@media screen and (max-width: 767px) {
		font-size: 1.2vw;
	}
	&__title {
		color: white;
		font-size: 4em;
		font-weight: bold;
		line-height: 1.1;
		margin: 0;
		text-align: center;
		text-transform: uppercase;
		small {
			display: block;
			font-size: 0.7em;
		}
		@media screen and (max-width: 767px) {
			white-space: nowrap;
		}
	}
	&__title-box {
		background: rgba(0, 0, 0, 0.4);
		left: 50%;
		padding: 2.5em 3em;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.dept-page-link {
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0);
	color: black;
	display: flex;
	flex: 1 0 auto;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	margin: 15px 0;
	padding: 25px;
	text-align: center;
	transition: all 0.3s ease;
	@media screen and (max-width: 991px) {
		margin-top: 0;
	}
	&:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}
	&__text {
		font-size: 16px;
		margin: 15px 0;
	}
}

.hub {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	border: 4px solid $white;
	background: rgba(0, 0, 0, 0.5);
	padding: 10px 20px;
	color: $white;
	display: flex;
	align-items: center;

	@media screen and (max-width: $screen-sm-min) {
		padding: 2vw 5vw;
	}

	&__title {
		font-family: $condensed;
		font-weight: bold;
		font-size: 75px;
		text-transform: uppercase;
		padding-right: 20px;

		@media screen and (max-width: $screen-sm-min) {
			font-size: 10vw;
			padding-right: 5vw;
		}
	}

	&__date {
		font-family: $condensed;
		font-size: 30px;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: $screen-sm-min) {
			font-size: 5vw;
		}

		span:first-child {
			border-bottom: 2px solid $white;
		}
	}
}

.dept-inspiration__experience {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 10px 0 30px 0;
}
.dept-inspiration__experience__title {
	text-align: center;
	font-size: 22px;
	color: #00315d;
}
.dept-inspiration__experience__icon {
	background: #00315d;
	height: 80px;
	width: 80px;
	display: flex;
	justify-content: center;
	border-radius: 80px;
	margin: 0 auto;
}
.dept-inspiration__experience__icon--return,
.dept-inspiration__experience__icon--price {
	background: transparent;
}
.dept-inspiration__experience__subtitle {
	color: #00315d;
	font-size: 13px;
	padding: 0px 20px;
}
.dept-inspiration__experience__subtitle span {
	display: block;
	margin: 5px 0px;
}
.dept-inspiration__experience__subtitle strong {
	font-size: 30px;
	margin-bottom: 5px;
	display: block;
}
.dept-inspiration__experience__box {
	flex: 1;
	text-align: center;
}
.dept-inspiration__experience__icon svg {
	fill: white;
	width: 43px;
}
.dept-inspiration__experience__icon--price svg,
.dept-inspiration__experience__icon--return svg {
	height: 80px;
	width: 80px;
	fill: #00315d;
}
@media screen and (max-width: 767px) {
	.dept-inspiration__experience__title {
		font-size: 16px;
	}
	.dept-inspiration__experience__subtitle {
		font-size: 10px;
		padding: 0px 10px;
	}
	.dept-inspiration__experience__box {
		flex: 1 0 33.33%;
		width: 33.33%;
		margin-bottom: 5px;
	}
	.dept-inspiration__experience__icon svg {
		width: 33px;
	}
	.dept-inspiration__experience__icon,
	.dept-inspiration__experience__icon--price svg,
	.dept-inspiration__experience__icon--return svg {
		width: 60px;
		height: 60px;
	}
	.dept-inspiration__experience {
		padding-bottom: 15px;
	}
}
@media screen and (max-width: 400px) {
	.dept-inspiration__experience__title {
		font-size: 12px;
		padding-bottom: 5px;
	}
	.dept-inspiration__experience__subtitle strong {
		font-size: 24px;
	}
}
