@import "_special-offer_mixin";

@include special-offer($main-color, $accent-color, $secondary-color, $main-color, #ffffff, 1.5em);
.special-offer {
  &__section {
    margin: 1em 0;
    display: flex;
    flex-flow: row wrap;
    font-size: 10px;
    @media screen and (max-width: 991px) {
      font-size: 7px;
    }
  }

  &__title {
    text-align: center;
    padding: 2em;
    z-index: 1;
    h2 {
      display: inline-block;
      font-size: 2em;
      color: #ffffff !important;
      padding-bottom: 0.5em;
      border-bottom: $main-color-light solid 1px;
    }
    background-color: $main-color;
    flex: 1 0 100%;
    &__image {
      width: 100%;
      margin-bottom: -7em;
      padding: 0 20em;
      z-index: 2;

      @media screen and (max-width: 991px) {
        padding: 0 10em;
      }
      @media screen and (max-width: 575.98px) {
        padding: 0 5em;
        margin-bottom: -4em;
      }
    }
  }

  &__rebate {
    background-color: $main-color-light;
    color: $main-color;
    display: flex;
    flex-flow: column;
    flex: 1 0 50%;
    width: 50%;
    padding: 4em;
    @media screen and (max-width: 991px) {
      padding: 4em 2em 2em 2em;
      flex: 1 0 100%;
      width: 100%;
    }
    position: relative;
    &__tag {
      align-content: center;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 963.312 310.12' style='fill:%23ffffff'><path d='M506.174,1278.73H576.09v16.41H506.174v-16.41Zm893.436,0h69.9v16.41h-69.9v-16.41ZM552.5,1131.89l59.567,36.46-8.627,13.98-59.567-36.46Zm870.678,310.15-59.57-36.46,8.63-13.98,59.57,36.46Zm-876.908-9.93-8.035-14.32,61.008-33.99,8.036,14.32Zm891.178-275.95-61.04,33.96-8.03-14.34,61.03-33.96Z' transform='translate(-506.188 -1131.91)'/></svg>");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-flow: row;
      flex: 1 0 1em;
      font-size: 20em;
      font-weight: 500;
      font-family: $font-normal;
      height: 1em;
      line-height: 1;
      padding-top: 0.1em;
      justify-content: center;

      @media screen and (max-width: 1199.98px) {
        font-size: 17em;
      }
      @media screen and (max-width: 991.98px) {
        font-size: 15em;
      }
      @media screen and (max-width: 767.98px) {
        font-size: 12em;
      }
      @media screen and (max-width: 575.98px) {
        font-size: 7em;
      }
    }

    &__value {
      font-size: 1em;
      flex: 0 0 1em;
      letter-spacing: -0.1em;
      padding-right: 0.1em;
    }

    &__nextoval {
      flex-flow: column;
      display: flex;
      flex: 0 0 auto;
    }
    &__nextoval--en {
      justify-content: center;
      .special-offer__rebate__off {
        flex: 0 0 auto;
      }
    }

    &__percent {
      font-size: 0.5em;
      align-self: flex-start;
      flex: 0 0 50%;
    }

    &__off {
      align-self: center;
      font-size: 0.2em;
      flex: 0 0 50%;
      padding-bottom: 0.5em;
    }

    &__text-after {
      font-size: 2em;
      flex: 1 0 0px;
      align-self: center;
      text-align: center;
    }

    p {
      color: #ffffff;
      font-size: 2em;
      text-align: center;
    }
    $arrow-size: 1.5em;
    &:after {
      content: "";
      position: absolute;
      right: calc(-#{$arrow-size});
      top: calc(50% - #{$arrow-size});
      width: 0;
      height: 0;
      border-left: $arrow-size solid $main-color-light;
      border-bottom: $arrow-size solid transparent;
      border-top: $arrow-size solid transparent;
      clear: both;
      @media screen and (max-width: 991px) {
        right: calc(50% - #{$arrow-size});
        top: calc(100%);
        border-left: $arrow-size solid transparent;
        border-bottom: none;
        border-top: $arrow-size solid $main-color-light;
        border-right: $arrow-size solid transparent;
      }
    }
  }

  &__stores {
    border: $main-color-light solid 1px;
    flex: 1 0 50%;
    display: flex;
    padding: 2em;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    .location-pin {
      height: 6em;
      fill: $main-color-light;
    }
    h3 {
      padding-top: 1em;
      font-size: 1.6em;
    }

    h2 {
      font-size: 3.4em;
    }

    a {
      font-weight: bold;
      font-size: 1.6em;
    }

    svg {
      max-width: 100%;
    }
  }
}
