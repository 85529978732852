.header-top-link {
  background: $main-color;
  max-height: 80px;
  overflow: hidden;
  .slick-track {
    display: block !important;
  }
  .slick-slide {
    outline: none;
    margin: 0;
  }
  &--bg-blue {
    background: $main-color;
  }
  &--bg-red {
    background: #dd0000;
  }
}

.HeaderTopLink {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
  }
  &__icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    width: 30px;
    flex: 0 0 auto;
  }
  &__text,
  a {
    flex: 0 1 auto;
    color: #ffffff;
    font-size: 15px;
  }
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }
  .container {
    padding: 8px;
  }
}
.js-header-top-link.header-top-link.slick-initialized {
  background: $main-color;
}

.header-top-link--topHeader {
  &.header-top-link {
    background-color: #ededed !important;
  }
  .HeaderTopLink,
  .HeaderTopLink a,
  .HeaderTopLink__text,
  .header__top__link,
  .header__top__link:hover {
    color: #00315c !important;
  }
}

.header-top-link--bigText {
  @media screen and (max-width: 767px) {
    .HeaderTopLink__content {
      height: 36px;
    }
    .HeaderTopLink__text,
    .HeaderTopLink__text a {
      font-size: 12px;
    }
  }
}
