.button {
  font-size: 14px;

  &.button-marginTop {
    margin-top: 20px;
  }

  &--float-right {
    align-self: flex-end;
    justify-self: flex-end;
    float: right;
  }

  &--flex-align-right {
    margin-left: auto;
  }

  &--flex-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  &--lightblue {
    background-color: $lightblue;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $lightblue;
    }
  }

  &.btn-link {
    background: none;
    color: $secondary-color;
    text-decoration: underline;
    text-transform: none;

    &:hover {
      font-weight: bolder;
    }
  }

  &.button--link-text {
    background: none;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;

    &:hover {
      font-weight: bolder;
      text-decoration: underline;
      background: none;
    }
  }

  &.button--link-black {
    background: none;
    color: #000000;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;

    &:hover {
      color: #000000;
      font-weight: bolder;
      text-decoration: underline;
      background: none;
    }
  }

  &.button--install-primary {
    background: $install-color-main;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background: $install-color-secondary;
      color: $install-color-main;
    }
  }

  &.button--install-secondary {
    background: $install-color-secondary;
    color: $install-color-main;
    text-transform: uppercase;

    &:hover {
      background: $install-color-main;
      color: #ffffff;
    }
  }
}

.btn-link--install {
  background: none;
  color: yellow !important;
  text-decoration: underline;
  text-transform: none;

  &:hover {
    font-weight: bolder;
  }
}
